import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import $ from "jquery";
import "./App.scss";
import "./Global";

import "antd/dist/antd.css";
import "./resources/semantic/theme/semantic-ui.css";
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import "./App.scss";
import "./Vendor";
// import "./resources/Titillium_Web/TitilliumWeb-Black.ttf";
// import "./resources/fontelloori/css/myicon.css";
// import "./resources/fontello/css/seacargo-go.css";

// import "./resources/Titillium_Web/TitilliumWeb-Bold.ttf";

import { store, persistor } from "./state/store/";
import LoginComponent from "./components/Seacargo/Login/";
import RecoverPasswors from "./components/Seacargo/RecoverPassword/";
import HomeComponent from "./components/Seacargo/Home/";
import WrapperRoute from "./components/Middlewares/WrapperRoute";

import NotFound from "./components/template/pages/notFound";
import Politicas from "./pages/politica_privacidad";
import terminos_condiciones from "./pages/terminos_condiciones";
import traceabilityShipmentsComponent from "./components/Seacargo/Traceability/Shipments";
import ReportesAnaliticos from "./components/Seacargo/LogisticsTools";
import Rssfeed from "./components/Seacargo/RssFeed";
import Herramientas from "./components/Seacargo/LogisticsTools";
import Usuarios from "./components/Seacargo/Admin/Usuarios";
import shipmentsDetailComponent from "./components/Seacargo/Traceability/Shipments/ShipmentsDetail";
import ContainerDetailComponets from "./components/Seacargo/Traceability/Shipments/Container/";
import Reporting from "./components/Seacargo/Reporting&Analitics";
import MyReportsCreate from "./components/Seacargo/Reporting&Analitics/MyReports/Create";
import QuickSearch from "./components/Seacargo/QuickSearch";
import GoAnalytics from "./components/Seacargo/Reporting&Analitics/MyAnalitics/goanalytics";

import AltaUsuario from "./components/Seacargo/Admin/Usuarios/components/createUsers/index";
import Entidades from "./components/Seacargo/Admin/Entidades";
import EditarEntidad from "./components/Seacargo/Admin/Entidades/components/editarEntidad/index";

import loadSATO from "./components/Seacargo/DocumentManagement/loadSATO";

import history from "./history";

$.ajaxPrefilter((o) => (o.async = true));

/*=============================================
=            App Component With Router        =
=============================================*/

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history} basename={"/"}>
            <Switch>
              <Route exact path="/" component={LoginComponent}></Route>
              <Route
                exact
                path="/dashboard"
                render={() => (
                  <WrapperRoute
                    name={"dashboard"}
                    component={HomeComponent}
                  ></WrapperRoute>
                )}
              ></Route>
              <Route
                exact
                path="/trazabilidad"
                render={() => (
                  <WrapperRoute
                    name={"trazabilidad"}
                    component={traceabilityShipmentsComponent}
                  ></WrapperRoute>
                )}
              ></Route>
              <Route
                exact
                path="/reportes-analiticos"
                render={() => (
                  <WrapperRoute
                    name={"reportes-analiticos"}
                    component={ReportesAnaliticos}
                  ></WrapperRoute>
                )}
              ></Route>
              <Route
                exact
                path="/rssfeed"
                render={() => (
                  <WrapperRoute
                    name={"rssfeed"}
                    component={Rssfeed}
                  ></WrapperRoute>
                )}
              ></Route>
              <Route
                exact
                path="/herramientas"
                render={() => (
                  <WrapperRoute
                    name={"herramientas"}
                    component={Herramientas}
                  ></WrapperRoute>
                )}
              ></Route>
              <Route
                exact
                path="/usuarios"
                render={() => (
                  <WrapperRoute
                    name={"usuarios"}
                    component={Usuarios}
                  ></WrapperRoute>
                )}
              ></Route>
              <Route
                exact
                path="/terminos-y-condiciones"
                component={terminos_condiciones}
              />
              <Route exact path="/politicas_privacidad" component={Politicas} />
              <Route
                exact
                path="/reporting"
                render={() => (
                  <WrapperRoute
                    name={"reporting"}
                    component={Reporting}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/reportes-especiales/creacion"
                render={() => (
                  <WrapperRoute
                    name={"myreports_create"}
                    component={MyReportsCreate}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/scl-go-analytics"
                render={() => (
                  <WrapperRoute
                    name={"sclGoAnalytics"}
                    component={GoAnalytics}
                  ></WrapperRoute>
                )}
              ></Route>

              <Route
                exact
                path="/altausuario"
                render={() => (
                  <WrapperRoute
                    name={"alta_usuarios"}
                    component={AltaUsuario}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/entidades"
                render={() => (
                  <WrapperRoute
                    name={"entidades"}
                    component={Entidades}
                  ></WrapperRoute>
                )}
              />

              {/* Routes with params */}
              <Route
                exact
                path="/shipmentsDetal/:id"
                render={() => (
                  <WrapperRoute
                    name={"shipmentsDetal"}
                    component={shipmentsDetailComponent}
                    filterCustomer={1}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/altausuario/:id"
                render={() => (
                  <WrapperRoute
                    name={"alta_usuarios"}
                    component={AltaUsuario}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/ContainerDetailComponets/:id"
                render={() => (
                  <WrapperRoute
                    name={"ContainerDetailComponets"}
                    component={ContainerDetailComponets}
                    filterCustomer={2}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/editarentidad/:id"
                render={() => (
                  <WrapperRoute
                    name={"editar_entidad"}
                    component={EditarEntidad}
                  ></WrapperRoute>
                )}
              />
              <Route
                exact
                path="/search/:id*"
                render={() => (
                  <WrapperRoute
                    name={"quick_search"}
                    component={QuickSearch}
                  ></WrapperRoute>
                )}
              />

              <Route
                exact
                path="/recoverpassword/:token"
                component={RecoverPasswors}
              ></Route>

              <Route
                exact
                path="/load-sato"
                render={() => (
                  <WrapperRoute
                    name={"load_sato"}
                    component={loadSATO}
                  ></WrapperRoute>
                )}
              ></Route>

              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
