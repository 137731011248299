import React, { useState, useCallback, useEffect } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Typography } from 'antd';
import { withRouter } from 'react-router-dom';
// import Card from './CardWrapper';
import Feed from './../../../RssFeed/components/RSSCard';
import './styles.scss';
import { Services } from './../../Services';
import dummyInfo from './../../../../../resources/dummy.json'
import { Card, CardBody, Col, Row } from 'reactstrap';

const { Text, Link } = Typography;
const RoldanImage = "https://fsposeidon.blob.core.windows.net/roldan/R.jpg";
/**
 * RSS Feeds
 * @param {*} param0
 */
function RSS({ type, history }) {
  const [feeds, setFeeds] = useState([]);

  const GetFeeds = useCallback(async () => {
    try {
      const response = await axios.get(`${Services.RSS.path}api/v1/rss-feed/mex-int?format=json`);
      response.data.feeds.forEach(element => {
        element.grouped[0].Preview = element.grouped[0].Preview === RoldanImage ? "" : element.grouped[0].Preview;
      });
      setFeeds([...response.data.feeds]);
      //############ INVOCACION COMENTADA ##############
      // const response = await axios.post(`${Services.DASHBOARD.path}dashboard/info`);
      //setFeeds([...response.data.data.RSS.feeds]);

      //setFeeds([...dummyInfo.RSS.feeds]);
    } catch (error) {
      console.error('Error: ', error);
    }
  }, []);

  useEffect(() => {
    GetFeeds();
  }, [GetFeeds]);

  return (
    <>
      <div className='col-md-12 rss-container d-flex flex-row justify-content-between'>
        <div className='d-flex flex-row justify-content-start'>
          {/* <em className="fas fa-rss fa-1x mr-2" /> */}
          <label className="headerTable" style={{ fontSize: "20px" }}>Top 3 Noticias Logísticas</label>
        </div>
        <div onClick={() => history.push('/rssfeed')}>
          <Link style={{ fontWeight: "bold" }}>
            Ver más resultados
          </Link>
        </div>
      </div>

      {
        feeds.map((feed, key) => {
          return (

            <Col xs={12} md={12} lg={4} className="ml-auto mr-auto">
              <Card key={key} className='shadow-sm'>
                <CardBody>
                  <Feed feed={feed} type={type}></Feed>
                </CardBody>
              </Card>
            </Col>

          );
        })
      }

    </>
  );
}
RSS.propTypes = {
  type: propTypes.string
};
RSS.defaultProps = {
  type: 'rss'
};

export default withRouter(RSS);