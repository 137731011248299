export const Services = {
   "DASHBOARD": {
      "path": "https://seacargoapidashboard.azurewebsites.net/",
      "method": "",
      "descrp": "API DASHBOARD"
   },
   "RSS": {
      "path": "https://roldanapirss.azurewebsites.net/",
      "method": "",
      "descrp": "API TO HANDLE RSS"
   }
}