import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import axios from 'axios';

import Context from './../../store/CreateContext';
import Header from './../../components/Header';
import { Services } from './../../../../Services';

function ShareToComponent() {
  const [usersList, setUsersList] = React.useState([]);
  const context = React.useContext(Context);

  const GET_USERLIST = React.useCallback(async () => {
    try {
      const body = {
        user: localStorage.getItem('dataUser')
      };
      const respUsers = await axios.post(`${Services.REPORTS.path}reports/shared/users/list`, body);
      setUsersList(respUsers.data.users);
    } catch (error) {
      console.error('ShareToComponent', error);
    }
  }, []);

  const handleOnChange = (evt, data) => {
    context.set_share_to(data.value);
  };


  React.useEffect(() => {
    GET_USERLIST();
  }, [GET_USERLIST]);

  return (

    <div className='col-md-12'>
      <Header
        as='h6'
        icon='share alternate'
        title='Compartir A:'
      />
      <Form size='tiny'>
        <Form.Group>
          <Form.Field width={6}>
            <label className="colorFuente">Nombre del usuario</label>
            {/* <Dropdown fluid multiple placeholder='Nombre del usuario' options={usersList} search/> */}
            <Dropdown
              name='share-users'
              placeholder='Nombre del usuario'
              clearable
              fluid
              multiple
              selection
              search
              defaultValue={context.share_to}
              value={context.share_to}
              options={usersList}
              onChange={handleOnChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
};

export default ShareToComponent;