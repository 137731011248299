

import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
// import logoSCL from '../resources/Logorojouber.png'
import logoSCL from '../resources/Logo-privacidad.png'
import logoSCL2 from '../resources/Logo-login.png'
import './stylesPages.scss'


function Politicas() {

	return (
		<div>
			<Col xs={12} md={7} lg={7} className="ml-auto mr-auto">
				<CardBody>
					<div className='content'>
						<Row>
							<Col xs={4} md={3} lg={3} className='mr-auto' style={{ textAlign: "left" }} >
								<img src={logoSCL2} alt="logo" />
							</Col>
							{/* <Col xs={3} style={{ textAlign: "right" }} className='ml-auto'> */}
							{/* <img style={{ width: "55%" }} src={logoSCL} alt="logo" /> */}
							{/* <img src={logoSCL2} alt="logo" /> */}
							{/* </Col> */}
						</Row>
						<br />
						<br />
						<h4 className="header1 text-center">
							POLITICA DE PRIVACIDAD – SCL GO
						</h4>
						<br />
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El presente documento establece las condiciones mediante las cuales se regirá el uso de la aplicación móvil SCL Go (en adelante la aplicación), operada por Sea Cargo Logistics y proveída sin costo alguna a los clientes de Sea Cargo Logistics. Sea Cargo Logistics es una compañía constituida en México y con domicilio en la CDMX y con oficinas bajo el mismo nombre en Colombia, Perú, Costa Rica y Panamá. En lo sucesivo SCL.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							La aplicación funciona como un nuevo canal de información para los clientes sobre todas las operaciones que SCL realiza a nombre del CLIENTE que usa los servicios ofrecidos por la empresa SCL.
						</p>
						<br />
						<p className="valueTable">
							El usuario se compromete a leer los términos y condiciones aquí establecidas, previamente a la descarga de la aplicación, por tanto, en caso de realizar la instalación se entiende que cuenta con el conocimiento integral de este documento y la consecuente aceptación de la totalidad de sus estipulaciones.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El Usuario reconoce que el ingreso de su información personal, y los datos que contiene la aplicación a su disposición respecto a los productos activos con las Empresas aliadas de SCL en México, la realizan de manera voluntaria, quienes optan por acceder a esta aplicación en México o desde fuera del territorio nacional, lo hacen por iniciativa propia y son responsables del cumplimiento de las leyes locales, en la medida en que dichas leyes sean aplicables en su correspondiente país. Esta aplicación no está destinada a menores de edad y aunque no contiene ninguna información no relativa a sus operaciones, el acceso por parte de menores de edad está completamente prohibido. En caso de que se acceda por parte de menores de edad, deben contar con la supervisión de un adulto en todo momento desde la descarga y durante el uso de la aplicación, en el evento en que no se cumpla esta condición, le agradecemos no hacer uso de la aplicación.
						</p>
						<br />

						<h6 className="headerTable">
							Alcance y Uso
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El usuario de la aplicación entiende y acepta que no obstante es operada por SEA CARGO LOGISTICS, la información contenida en la misma será la referente a su vínculo comercial o contractual con cualquiera de las oficinas del grupo SCL, por tanto, las funcionalidades ofrecidas por la aplicación serán entregadas por cada oficina de acuerdo con su vinculación.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							En la aplicación se pondrá a disposición del CLIENTE información de las transacciones determinadas o habilitadas por SCL para cada servicio en particular. SCL podrá adicionar, modificar o eliminar las funcionalidades en cualquier momento, e inclusive eliminar la aplicación de forma unilateral, lo cual acepta el usuario mediante la instalación de la aplicación. En todo caso, al momento de realizar dichas modificaciones se notificarán al usuario a través de la misma aplicación de escritorio o móvil una vez inicie sesión.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Los tiempos de respuesta, tramites y demás solicitudes efectuadas por el usuario mediante la aplicación serán procesadas de conformidad con las especificaciones de cada servicio activo con SCL.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El usuario acepta y autoriza que los registros electrónicos de las actividades mencionadas, que realice en la aplicación constituyen plena prueba de aceptación de los mismos.
						</p>
						<br />
						<h6 className="headerTable">
							Requisitos para Uso
						</h6>
						<br />

						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El usuario deberá contar con una computadora, dispositivo móvil inteligente (Smartphone) o Tableta con sistema operativo Android o IOS, cualquiera de estos con acceso a internet, ambos seguros y confiables. SCL, no será responsable por la seguridad de los equipos propiedad de los usuarios utilizados para el acceso al canal, ni por la disponibilidad del servicio en los dispositivos en los cuales se descargue la aplicación.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							En la forma permitida por la ley, los materiales de la aplicación se suministran sin garantía de ningún género, expresa o implícita, incluyendo sin limitación las garantías de calidad satisfactoria, comerciabilidad, adecuación para un fin particular o no infracción, por tanto, SCL no garantiza el funcionamiento adecuado en los distintos sistemas operativos o dispositivos en los cuales se haga uso de la aplicación.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Para acceder al portal, EL CLIENTE contará con Usuario y Clave, que lo identifica en su relación con SCL, los cuales serán los mismos utilizados en el portal web. Adicional a lo anterior se requerirá a EL CLIENTE, registrar preguntas de seguridad, las cuales serán solicitadas al momento de intentar ingresar el portal, sólo cuando el cliente ingrese desde un equipo registrado no se solicitará responder las preguntas definidas con anterioridad.
						</p>
						<br />

						<h6 className="headerTable">
							Obligaciones de los Usuarios
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El Usuario se obliga a usar la aplicación y los contenidos encontrados en ella de una manera diligente, correcta, lícita y en especial, se compromete a NO realizar las conductas descritas a continuación:
						</p>
						<br />
						<ol style={{ textAlign: "justify", textJustify: "inter-word" }}>
							<li>
								Utilizar los contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público;
							</li>
							<br />
							<li>
								Reproducir, copiar, representar, utilizar, distribuir, transformar o modificar los contenidos de la aplicación, por cualquier procedimiento o sobre cualquier soporte, total o parcial, o permitir el acceso del público a través de cualquier modalidad de comunicación pública;
							</li>
							<br />
							<li>
								Utilizar los contenidos de cualquier manera que entrañen un riesgo de daño o inutilización de la aplicación o de los contenidos o de terceros;
							</li>
							<br />
							<li>
								Suprimir, eludir o manipular el derecho de autor y demás datos identificativos de los derechos de autor incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren tener los contenidos;
							</li>
							<br />
							<li>
								Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la aplicación para distribuir, transmitir, remitir, modificar, rehusar o reportar la publicidad o los contenidos de esta con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como comercializar o divulgar de cualquier modo dicha información; No permitir que terceros ajenos a usted usen la aplicación de escritorio o móvil con su clave;
							</li>
							<br />
							<li>
								Utilizar la aplicación y los contenidos con fines lícitos y/o ilícitos, contrarios a lo establecido en estos Términos y Condiciones, o al uso mismo de la aplicación, que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la aplicación y los contenidos o impedir la normal utilización o disfrute de esta y de los contenidos por parte de los usuarios.
							</li>
						</ol>
						<br />
						<h6 className="headerTable">
							Propiedad Intelectual
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y de diseño, así como todos los contenidos, textos y bases de datos puestos a su disposición en esta aplicación están protegidos por derechos de autor y/o propiedad industrial cuyo titular es SEA CARGO LOGISTICS, en algunos casos, de terceros que han autorizado su uso o explotación. Igualmente, el uso en la aplicación de algunos materiales de propiedad de terceros se encuentra expresamente autorizado por la ley o por dichos terceros. Todos los contenidos en la aplicación están protegidos por las normas sobre derecho de autor y por todas las normas nacionales e internacionales que le sean aplicables.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Exceptuando lo expresamente estipulado en estos Términos y Condiciones, queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los contenidos de esta aplicación, de manera o por medio alguno, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo y por escrito de SCL o del titular de los respectivos derechos.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							En ningún caso estos Términos y Condiciones confieren derechos, licencias ni autorizaciones para realizar los actos anteriormente prohibidos. Cualquier uso no autorizado de los contenidos constituirá una violación del presente documento y a las normas vigentes sobre derechos de autor, a las normas vigentes nacionales e internacionales sobre Propiedad Industrial, y a cualquier otra que sea aplicable.
						</p>
						<br />
						<h6 className="headerTable">
							Licencia para Copiar para Uso Personal
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Usted podrá leer, visualizar, imprimir y descargar el material de sus operaciones.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Ninguna parte de la aplicación podrá ser reproducida o transmitida o almacenada en otro sitio web o en otra forma de sistema de recuperación electrónico.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Ya sea que se reconozca específicamente o no, las marcas comerciales, las marcas de servicio y los logos visualizados en esta aplicación pertenecen al grupo SCL, sus socios promocionales u otros terceros.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL no interfiere, no toma decisiones, ni garantiza las relaciones que los usuarios lleguen a sostener o las vinculaciones con terceros que pauten y/o promocionen sus productos y servicios. Estas marcas de terceros se utilizan solamente para identificar los productos y servicios de sus respectivos propietarios y el patrocinio o el aval por parte de SCL no se deben inferir con el uso de estas marcas comerciales.
						</p>
						<br />
						<h6 className="headerTable">
							Integración con Otras Aplicaciones
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Los links de Facebook®, Instagram®, twitter® en esta aplicación pueden mostrar contenido que no están bajo el control de SCL.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Aunque esta aplicación de SCL trata de suministrar links solamente a sitios y aplicaciones de terceros que cumplan con las leyes y regulaciones aplicables y las normas de SCL, el Usuario debe entender que SCL no tiene control sobre la naturaleza y el contenido de esos sitios y no está recomendando estos sitios, la información que contienen ni los productos o servicios de terceros.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL no acepta responsabilidad por el contenido del sitio de un tercero con el cual existe un link de hipertexto y no ofrece garantía (explícita o implícita) en cuanto al contenido de la información en esos sitios, ya que no recomienda estos sitios.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Usted debe verificar las secciones de términos y condiciones, política legal y de privacidad de algunos otros sitios de SCL o de un tercero con los cuales se enlaza.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL no asume ninguna responsabilidad por pérdida directa, indirecta o consecuencial por el uso de un sitio de un tercero.
						</p>
						<br />

						<h6 className="headerTable">
							Uso de Información y Privacidad
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Con la descarga de la APP usted acepta y autoriza que SCL, utilice sus datos en calidad de responsable del tratamiento para fines derivados de la ejecución de la APP. Sea Cargo Logistics informa que podrá ejercer sus derechos a conocer, actualizar, rectificar y suprimir su información personal; así como el derecho a revocar el consentimiento otorgado para el tratamiento de datos personales previstos en la ley XXXX de (XXXX año) , observando nuestra política de tratamiento de información disponible en nuestra pagina web; o a través de mailto: privacidad@seacargo.com o del teléfono +52 55 5231 7141 siendo voluntario responder preguntas sobre información sensible o de menores de edad.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Sea Cargo Logistics. podrá dar a conocer, transferir y/o trasmitir sus datos personales dentro y fuera del país a cualquier empresa miembro de SCL, así como a terceros a consecuencia de un contrato, ley o vínculo lícito que así lo requiera, para todo lo anterior otorgo mi autorización expresa e inequívoca.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							De conformidad a lo anterior autoriza el tratamiento de su información en los términos señalados, y transfiere a SCL de manera total, y sin limitación mis derechos de imagen y patrimoniales de autor, de manera voluntaria, previa, explicita, informada e inequívoca.
						</p>
						<br />
						<h6 className="headerTable">
							Responsabilidad de SCL
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL procurará garantizar disponibilidad, continuidad o buen funcionamiento de la aplicación. SCL podrá bloquear, interrumpir o restringir el acceso a esta cuando lo considere necesario para el mejoramiento de la aplicación o dar de baja la misma.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Se recomienda al Usuario tomar medidas adecuadas y actuar diligentemente al momento de acceder a la aplicación, como por ejemplo, contar con programas de protección, antivirus, para manejo de malware, spyware y herramientas similares.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL no será responsable por: a) Fuerza mayor o caso fortuito; b) Por la pérdida, extravío o hurto de su dispositivo móvil que implique el acceso de terceros a la aplicación móvil; c) Por errores en la digitación o accesos por parte del cliente; d) Por los perjuicios, lucro cesante, daño emergente, morales, y en general sumas a cargo de SCL, por los retrasos, no procesamiento de información o suspensión del servicio del operador móvil o daños en los dispositivos móviles.
						</p>
						<br />

						<h6 className="headerTable">
							Denegación y Retirada del Acceso a la Aplicación
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							En el Evento en que un Usuario incumpla estos Términos y Condiciones, o cualesquiera otras disposiciones que resulten de aplicación, SCL podrá suspender su acceso a la aplicación.
						</p>
						<br />

						<h6 className="headerTable">
							Términos y Condiciones
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							El Usuario acepta expresamente los Términos y Condiciones, siendo condición esencial para la utilización de la aplicación. En el evento en que se encuentre en desacuerdo con estos Términos y Condiciones, solicitamos abandonar la aplicación inmediatamente. SCL podrá modificar los presentes términos y condiciones, avisando a los usuarios de la aplicación mediante publicación en la página web www.seacargo.com o mediante la difusión de las modificación por algún medio electrónico, redes sociales, SMS y/o correo electrónico, lo cual se entenderá aceptado por el usuario si éste continua con el uso de la aplicación.
						</p>
						<br />


						<h6 className="headerTable">
							Jurisdicción
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Estos términos y condiciones y todo lo que tenga que ver con esta aplicación, se rigen por las leyes de México.
						</p>
						<br />

						<h6 className="headerTable">
							Uso de Información no Personal
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL también recolecta información no personal en forma agregada para seguimiento de datos como el número total de descargas de la aplicación. Utilizamos esta información, que permanece en forma agregada, para entender el comportamiento de la aplicación.
						</p>
						<br />

						<h6 className="headerTable">
							Uso de Direcciones IP
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Una dirección de Protocolo de Internet (IP) es un conjunto de números que se asigna automáticamente a su o dispositivo móvil cuando usted accede a su proveedor de servicios de internet, o a través de la red de área local (LAN) de su organización o la red de área amplia (WAN). Los servidores web automáticamente identifican su dispositivo móvil por la dirección IP asignada a él durante su sesión en línea.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL podrán recolectar direcciones IP para propósitos de administración de sistemas y para auditar el uso de nuestro sitio, todo lo anterior de acuerdo con la autorización de protección de datos que se suscribe para tal efecto. Normalmente no vinculamos la dirección IP de un usuario con la información personal de ese usuario, lo que significa que cada sesión de usuario se registra, pero el usuario sigue siendo anónimo para nosotros. Sin embargo, podemos usar las direcciones IP para identificar a los usuarios de nuestro sitio cuando sea necesario con el objeto de exigir el cumplimiento de los términos de uso del sitio, o para proteger nuestro servicio, sitio u otros usuarios.
						</p>
						<br />

						<h6 className="headerTable">
							Seguridad
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							SCL está comprometido en la protección de la seguridad de su información personal. SCL tiene implementados mecanismos de seguridad que aseguran la protección de la información personal, así como los accesos únicamente al personal y sistemas autorizados, también contra la pérdida, uso indebido y alteración de sus datos de usuario bajo nuestro control.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Excepto como se indica a continuación, sólo personal autorizado tiene acceso a la información que nos proporciona. Además, hemos impuesto reglas estrictas a los empleados de SCL con acceso a las bases de datos que almacenan información del usuario o a los servidores que hospedan nuestros servicios.
						</p>
						<br />
					</div>
				</CardBody>
			</Col>
		</div>
	);
}
export default Politicas;
