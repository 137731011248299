import React from "react";
import { Card, Input, Grid, Button, Popup } from "semantic-ui-react";
import { Col, Row, Label, CardBody } from "reactstrap";
import { Icon } from "semantic-ui-react";
import { IncotermsInfo } from "./data";
import { saveAs } from "file-saver";
import axios from 'axios';
import donwload from "../../../../../resources/donwload.png";
import info from "./info.png"
import "../../style.scss";

function Incoterms() {
  let [valueGrupoC, setValueGrupoC] = React.useState("cfr");
  let [valueGrupoD, setValueGrupoD] = React.useState("dpu");
  let [valueGrupoF, setValueGrupoF] = React.useState("fca");
  let [activeGC, setActiveGC] = React.useState("cfr");
  let [activeGD, setActiveGD] = React.useState("dpu");
  let [activeGF, setActiveGF] = React.useState("fca");
  let [loading, setloading] = React.useState(false);

  function handleClickGC(inpt) {
    setValueGrupoC(inpt);
    setActiveGC(inpt);
  }

  function handleClickGD(inpt) {
    setValueGrupoD(inpt);
    setActiveGD(inpt);
  }

  function handleClickGF(inpt) {
    setValueGrupoF(inpt);
    setActiveGF(inpt);
  }
  function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  function descarga(uri) {
    const linkSource = `https://magiclog.blob.core.windows.net/seacargopic/Incotermsscl2022pdf.pdf`;

    axios({
      method: 'get',
      url: encodeURI(linkSource),
      responseType: 'blob'
    }).then(function (response) {
      saveAs(
        response.data,
        'Incoterms'
      );
      setloading(false)
    }).catch(function (error) {
      console.log('download error');
    });
  };


  return (
    <>
      <div className="row">
        <div className="col-12">
          <img
            src={
              "https://magiclog.blob.core.windows.net/seacargopic/Incotermsscl2022.jpg"
            }
            alt=""
            width={"100%"}
            height={"auto"}
          />
        </div>
        <div style={{ textAlign: "end" }} className="col-12">
          {loading ? (

            <Icon
              loading={loading}
              style={{ marginTop: "9px" }}
              name="circle notch"
              size="large"
            />
          ) : (
            <img
              onClick={() => {
                setloading(true)
                sleep(1000).then(() => {
                  descarga()
                });
              }}
              style={{ margin: "-1px 24px 6px", marginTop: "-1px" }}
              alt=""
              src={donwload}
              width="35"
              height="35"
            ></img>
          )}
        </div>
      </div>

      <br />

      {/* <div className="row"> */}
      <CardBody>
        <Col>

          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="row">
                    <Label>Grupo C - Entrega Indirecta</Label>
                    <Popup
                      content={IncotermsInfo.grupoC.grupoData}
                      style={{ textAlign: "justify", color: "#020e3a" }}
                      position="right center"
                      wide="very"
                      trigger={
                        <img
                          style={{
                            marginLeft: "5px",
                            width: "15px",
                            height: "15px",
                            marginTop: "2px"
                          }}

                          alt=""
                          src={info}

                        ></img>
                      }
                    />
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-2" style={{ padding: "0" }}>
                      <Button
                        active={activeGC === "cfr"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cfr");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CFR
                      </Button>
                      <Button
                        active={activeGC === "cif"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cif");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CIF
                      </Button>
                      <Button
                        active={activeGC === "cpt"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cpt");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CPT
                      </Button>
                      <Button
                        active={activeGC === "cip"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGC("cip");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        CIP
                      </Button>
                    </div>
                    <div className="col-10" style={{ paddingLeft: "0" }}>
                      <Card
                        style={{ color: "#020e3a" }}
                        Card
                        fluid
                        header={IncotermsInfo.grupoC[valueGrupoC].title}
                        description={IncotermsInfo.grupoC[valueGrupoC].data}
                        className="CARD UI REACT STRAP BORDER"
                      ></Card>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="row">
                    <Label>Grupo D - Entrega Directa</Label>
                    <Popup
                      content={IncotermsInfo.grupoD.grupoData}
                      style={{ textAlign: "justify", color: "#020e3a" }}
                      position="top center"
                      wide="very"
                      trigger={
                        <img
                          style={{
                            marginLeft: "5px",
                            width: "15px",
                            height: "15px",
                            marginTop: "2px"
                          }}

                          alt=""
                          src={info}

                        ></img>
                      }
                    />
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-2" style={{ padding: "0" }}>
                      <Button
                        active={activeGD === "dpu"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGD("dpu");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        DPU
                      </Button>
                      <Button
                        active={activeGD === "dap"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGD("dap");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        DAP
                      </Button>
                      <Button
                        active={activeGD === "ddp"}
                        className="BUTTON UI REACT STRAP BORDER"
                        onClick={() => {
                          handleClickGD("ddp");
                        }}
                        basic
                        fluid
                        compact
                        size="tiny"
                      >
                        DDP
                      </Button>
                    </div>
                    <div className="col-10" style={{ paddingLeft: "0" }}>
                      <Card
                        Card
                        fluid
                        header={IncotermsInfo.grupoD[valueGrupoD].title}
                        description={IncotermsInfo.grupoD[valueGrupoD].data}
                        className="CARD UI REACT STRAP BORDER"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="row">
                <Label>Grupo E - Entrega Directa a la Salida</Label>
                <Popup
                  content={IncotermsInfo.grupoE.grupoData}
                  style={{ textAlign: "justify", color: "#020e3a" }}
                  position="top center"
                  wide="very"
                  trigger={
                    <img
                      style={{
                        marginLeft: "5px",
                        width: "15px",
                        height: "15px",
                        marginTop: "2px"
                      }}

                      alt=""
                      src={info}

                    ></img>
                  }
                />
              </div>
              <br />
              <div className="row">
                <div className="col-2" style={{ padding: "0" }}>
                  <Button
                    className="BUTTON UI REACT STRAP BORDER EG"
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    EXW
                  </Button>
                </div>
                <div className="col-10" style={{ paddingLeft: "0" }}>
                  <Card
                    Card
                    fluid
                    header={IncotermsInfo.grupoE.exw.title}
                    description={IncotermsInfo.grupoE.exw.data}
                    className="CARD UI REACT STRAP BORDER"
                  />
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="row">
                <Label>Grupo F - Entrega Indirecta</Label>
                <Popup
                  content={IncotermsInfo.grupoF.grupoData}
                  style={{ textAlign: "justify", color: "#020e3a" }}
                  position="top center"
                  wide="very"
                  trigger={
                    <img
                      style={{
                        marginLeft: "5px",
                        width: "15px",
                        height: "15px",
                        marginTop: "2px"
                      }}

                      alt=""
                      src={info}

                    ></img>
                  }
                />
              </div>
              <br />
              <div className="row">
                <div className="col-2" style={{ padding: "0" }}>
                  <Button
                    active={activeGF === "fca"}
                    className="BUTTON UI REACT STRAP BORDER"
                    onClick={() => {
                      handleClickGF("fca");
                    }}
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    FCA
                  </Button>
                  <Button
                    active={activeGF === "fas"}
                    className="BUTTON UI REACT STRAP BORDER"
                    onClick={() => {
                      handleClickGF("fas");
                    }}
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    FAS
                  </Button>
                  <Button
                    active={activeGF === "fob"}
                    className="BUTTON UI REACT STRAP BORDER"
                    onClick={() => {
                      handleClickGF("fob");
                    }}
                    basic
                    fluid
                    compact
                    size="tiny"
                  >
                    FOB
                  </Button>
                </div>
                <div className="col-10" style={{ paddingLeft: "0" }}>
                  <Card
                    // style={{ height: "220px" }}
                    Card
                    fluid
                    header={IncotermsInfo.grupoF[valueGrupoF].title}
                    description={IncotermsInfo.grupoF[valueGrupoF].data}
                    className="CARD UI REACT STRAP BORDER"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </CardBody>
      {/* </div> */}
    </>
  );
}

export default Incoterms;
