import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Progress } from "reactstrap";
import { Popup } from "semantic-ui-react";
const limit = 12;
export const CardShipment = (props) => {
  let { element } = props;

  return (
    <>
      <Card className="_clickCard myCard" id={Math.random()}>
        <CardBody>
          <div className="row" style={{ paddingRight: "15px" }}>
            <div className="container-fluid">
              <div className="row">
                {/* Icono & DO ID & Estatus */}
                <div className="col-md-2">
                  <div className="text-center">
                    {/* ==========================ICONO===================================== */}

                    <div className="mb-mail-meta">
                      <div className="mb-mail-from row">
                        <div
                          className="col-12"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <em
                            style={{
                              color: "#020E3A",
                              fontSize: "20px",
                              marginRight: "35px",
                              float: "left",
                              marginLeft: "5%",
                              marginBottom: "1em",
                            }}
                            className={
                              element.TransportMode === "Air"
                                ? "icon-final-04"
                                : element.TransportMode === "Ocean"
                                ? "icon-final-03"
                                : "icon-final-05"
                            }
                          ></em>
                        </div>
                      </div>
                      <div className="mb-mail-subject row">
                        <div className="col-5">
                          <span
                            style={{
                              fontSize: "12px",
                              float: "left",
                              marginLeft: "20%",
                            }}
                          >
                            {element.Status}
                          </span>
                        </div>
                        <div className="col-7">
                          <span style={{ fontSize: "12px" }}>
                            <Link
                              className="linkCard"
                              to={"/shipmentsDetal/" + element.ShipmentId}
                            >
                              {element.HBL_HAWB === null
                                ? element.ShipmentNumber
                                : element.HBL_HAWB}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ==========================cliente =========================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Embarcador
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">
                          {element.ShipperName ? (
                            element.ShipperName.length > limit ? (
                              <Popup
                                content={element.ShipperName}
                                key={element.ShipperName}
                                position=""
                                trigger={
                                  <span>
                                    {element.ShipperName.substring(
                                      0,
                                      limit - 3
                                    )}
                                    ...
                                  </span>
                                }
                              />
                            ) : (
                              element.ShipperName
                            )
                          ) : (
                            <a href style={{ color: "white" }}>
                              -
                            </a>
                          )}
                        </span>
                      </center>
                    </div>
                  </div>
                </div>

                {/* MODO transporte & doc transporte */}

                {/* ==========================Consignatario===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          <span>Consignatario </span>
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <span className="subTitleCard">
                        {element.ConsigneeName ? (
                          element.ConsigneeName.length > limit ? (
                            <Popup
                              content={element.ConsigneeName}
                              key={element.ConsigneeName}
                              position=""
                              trigger={
                                <span>
                                  {element.ConsigneeName.substring(
                                    0,
                                    limit - 3
                                  )}
                                  ...
                                </span>
                              }
                            />
                          ) : (
                            element.ConsigneeName
                          )
                        ) : (
                          <a href style={{ color: "white" }}>
                            -
                          </a>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {/* ==========================Ref. Cliente===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Ref. Cliente
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">
                          {element.CustomertRef ? (
                            element.CustomertRef.length > limit ? (
                              <Popup
                                content={element.CustomertRef}
                                key={element.CustomertRef}
                                position=""
                                trigger={
                                  <span>
                                    {element.CustomertRef.substring(
                                      0,
                                      limit - 3
                                    )}
                                    ...
                                  </span>
                                }
                              />
                            ) : (
                              element.CustomertRef
                            )
                          ) : (
                            <a href style={{ color: "white" }}>
                              -
                            </a>
                          )}
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* ==========================Viaje/ No Vuelo===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Viaje/ No Vuelo
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">
                          {element.VesselVoyageFlight ? (
                            element.VesselVoyageFlight.length > limit ? (
                              <Popup
                                content={element.VesselVoyageFlight}
                                key={element.VesselVoyageFlight}
                                position=""
                                trigger={
                                  <span>
                                    {element.VesselVoyageFlight.substring(
                                      0,
                                      limit - 3
                                    )}
                                    ...
                                  </span>
                                }
                              />
                            ) : (
                              element.VesselVoyageFlight
                            )
                          ) : (
                            <a href style={{ color: "white" }}>
                              -
                            </a>
                          )}
                        </span>
                      </center>
                    </div>
                  </div>
                </div>

                {/* Aduana & País origen*/}

                {/* ==========================No Contenedor===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          No Contenedor
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">
                          {element.Containers ? (
                            element.Containers.length > limit ? (
                              <Popup
                                content={element.Containers}
                                key={element.Containers}
                                position=""
                                trigger={
                                  <span>
                                    {element.Containers.substring(0, limit - 3)}
                                    ...
                                  </span>
                                }
                              />
                            ) : (
                              element.Containers
                            )
                          ) : (
                            <a href style={{ color: "white" }}>
                              -
                            </a>
                          )}
                        </span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* ==========================Origen===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Origen
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">{element.POL}</span>
                      </center>
                    </div>
                  </div>
                </div>

                {/* ==========================Destino===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Destino
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">{element.POD}</span>
                      </center>
                    </div>
                  </div>
                </div>

                {/* ========================== Instruccion===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          ETD
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">{element.ETD}</span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* ========================== ATA ===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          ETA
                        </span>
                      </center>
                    </div>
                    <div className="mb-mail-from">
                      <center>
                        <span className="subTitleCard">{element.ETA}</span>
                      </center>
                    </div>
                  </div>
                </div>
                {/* ========================== LEVANTE ===================================== */}
                <div className="col-md-1">
                  <div className="mb-mail-meta">
                    <div
                      className="mb-mail-subject"
                      style={{ marginBottom: "2em" }}
                    >
                      <center>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          Progreso
                        </span>
                      </center>
                    </div>
                    <div
                      style={{ marginBottom: "2em" }}
                      className="mb-mail-subject"
                    >
                      <center>
                        <Progress
                          color={
                            element.Progress < 50
                              ? element.Progress
                                ? "pink"
                                : "danger"
                              : "pink"
                          }
                          value={element.Progress}
                        />
                      </center>
                    </div>
                  </div>
                </div>
                {/* END OF MAIN DIV */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
