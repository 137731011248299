import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import Select from "react-virtualized-select";
import donwload from "../../../../../resources/donwload.png";
import Tooltip from '@material-ui/core/Tooltip';
import PDF from "./pdf.js";
import { Col, Row } from "reactstrap";

// import Select from "react-select";

/*=============================================
=            reportes             =
=============================================*/
class Contenedores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      catalog: [
        {
          key: "20FlatRack",
          value: "20FlatRack",
          label: "20' Flat Rack",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/20FlatRackv.png",
        },
        {
          key: "20FlatRackC",
          value: "20FlatRackC",
          label: "20' Flat Rack C",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/20FlatRackC.png",
        },
        {
          key: "20FlatRackP",
          value: "20FlatRackP",
          label: "20' Flat Rack P",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/20FlatRackP.png",
        },
        {
          key: "20OpenTop",
          value: "20OpenTop",
          label: "20' Open Top",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/20OpenTop.png",
        },
        {
          key: "20Refeer",
          value: "20Refeer",
          label: "20' Refeer",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/20Refeer.png",
        },
        {
          key: "20Standard",
          value: "20Standard",
          label: "20' Standard",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/20Standard.png",
        },
        {
          key: "40FlatRack",
          value: "40FlatRack",
          label: "40' Flat Rack",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40FlatRack.png",
        },
        {
          key: "40FlatRackC",
          value: "40FlatRackC",
          label: "40' Flat Rack C",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40FlatRackC.png",
        },
        {
          key: "40FlatRackP",
          value: "40FlatRackP",
          label: "40' Flat Rack P",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40FlatRackP.png",
        },
        {
          key: "40HighCube",
          value: "40HighCube",
          label: "40' High Cube",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40HighCube.png",
        },
        {
          key: "40OpenTop",
          value: "40OpenTop",
          label: "40' Open Top",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40OpenTop.png",
        },
        {
          key: "40Reefer",
          value: "40Reefer",
          label: "40' Reefer",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40Reefer.png",
        },
        {
          key: "40Standard",
          value: "40Standard",
          label: "40' Standard",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/40Standard.png",
        },
        {
          key: "53HighCube",
          value: "53HighCube",
          label: "53' High Cube",
          uri: "https://fsposeidon.blob.core.windows.net/seacargopic/53HighCube.png",
        },
      ],
      value: "20FlatRack",
      img: "https://fsposeidon.blob.core.windows.net/seacargopic/20FlatRackv.png",
    };
  }

  componentDidMount = () => { };

  Onchange = (value) => {
    let newValue = value.key;
    let newImg = value.uri;
    this.setState({
      value: newValue,
      img: newImg,
    });
  };

  descarga = (uri) => {
    const linkSource = `data:application/pdf;base64,${PDF}`;
    const downloadLink = document.createElement("a");
    // const fileName = 'REPORT_COST_' + new Date() + '.xlsx';
    const fileName = "ContenedoresSCL.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    this.setState({
      loading: false,
    })
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  render() {
    let { catalog, value, img, loading } = this.state;
    return (
      <>
        <Col>
          {/* <div className="col-xs-12 col-md-12 col-lg-12 row"> */}
          {/* <div style={{ marginBottom: "5em", display: "flex", alignItems: "center"}} className='col-12 row'> */}
          <Row>
            <Col xs={12} md={3} lg={3}>
              {/* <div className="col-xs-12 col-md-3 col-lg-3 "> */}
              <label className="subtitlecontenedor">
                Seleccionar el tipo de contenedores
              </label>
              {/* </div> */}
            </Col>
            <Col xs={10} md={3} lg={3}>

              <Select
                placeholder=""
                value={value}
                options={catalog}
                clearable={false}
                onChange={(value) => this.Onchange(value)}
              />

            </Col>
            <Col xs={2} md={3} lg={3}>
              {loading ? (
                // <center>
                // {" "}
                <Icon
                  loading={loading}
                  // style={{ marginTop: "9px" }}
                  name="circle notch"
                  size="large"
                />
                // </center>
              ) : (
                // <center>

                <img
                  onClick={() => {
                    this.setState({
                      loading: true,
                    }, () => {
                      this.sleep(1000).then(() => {
                        this.descarga(donwload);
                      });
                    })
                  }}
                  style={{ margin: "6px", marginTop: "-1px" }}
                  alt=""
                  src={donwload}
                  width="35"
                  height="35"
                ></img>
                // <Tooltip open={true} interactive={true} title="Descargar todos los contenedores" placement="placement" >

                // </Tooltip>



                // </center>
              )}
              {/* </div> */}
              <br></br>
              <br></br>
            </Col>


            {/* </div> */}

            {/* <div className="col-xs-12 col-md-8 col-lg-8"> */}
            <Col xs={12} md={8} lg={8}>
              <img
                // style={{ margin: "6px", marginTop: "-1px" }}
                alt=""
                src={img}
                width="100%"
              ></img>
            </Col>
          </Row>
          {/* </div> */}

          {/* </div> */}
        </Col>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </>
    );
  }
}

export default Contenedores;
