import React, { Component } from 'react';
import { Table, InputGroup, InputGroupAddon, Input, Col, Row } from 'reactstrap';
import { Pagination, Grid, Search } from 'semantic-ui-react';
import Select from 'react-select';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import Checkbox from '@mui/material/Checkbox';
import './styles.scss';
import { saveAs } from 'file-saver';
import IMG from '../../../../resources/filter.png';
const Column = (props) => {
  return (
    <div
      style={
        props.isLarge
          ? { width: '350px', fontSize: '14px', textAlign: 'center' }
          : { fontSize: '14px', textAlign: 'center' }
      }>
      <p className={props.headerClass && props.headerClass != "" ? props.headerClass : ""}
        style={props.headerClass && props.headerClass != "" ?
          { color: props.colorHeader ? props.colorHeader : '' } : {}}>{props.title}</p>
    </div>
  );
};

class FormExtended extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styletd: false,
      grupo: '',
      valor: '',
      grupoOpcion: [],
      countryOptions: this.props.catalog ? this.props.catalog : [],
      updateActivePage: props.updateActivePage ? props.updateActivePage : (page) => { },
      all: this.props.data,
      all2: this.props.data2,
      customAdd: this.props.customAdd,
      data: [],
      columns: [],
      mainColumns: [],
      columnsLogic: {},
      MainTable: '',

      ___rows: [],
      ___columns: [],
      ___mainColumns: [],

      pages: 0,
      activePage: 1,
      tableData: [],

      haveCheck: props.options ? props.options.selectItems : false,
      search: '',
      sorts: {},
      selected: [],
      selectControls: {},

      xlsx: '',
    };
  }

  toggle = () => this.setState({ collapse: !this.state.collapse });

  handleChange = (name) => (event) => {
    let { selectControls, data } = this.state;
    let checked = event.target.checked;
    selectControls[name] = checked;
    this.setState(
      {
        selectControls,
      },
      () => {
        this.buildRows();
        let array = this.state.selected;
        let index = data.findIndex((x) => x.mainKey === name);
        if (checked) {
          array.push(data[index]);
        } else {
          if (array.length === 1) array = [];
          else array.splice(index, 1);
        }
        this.setState({ selected: array }, () => {
          this.props.onSelect(this.state.selected);
        });
      }
    );
  };

  componentDidMount = () => {
    let { options, columns } = this.props;
    if (options.selectGroup) {
      let newgrupoOpcion = [];
      let data = this.props.data;
      data.map((e) => {
        let findcantalog = false;
        if (e.GrupoId == null) {
          e.GrupoId = 0;
          e.Grupo = 'sin Grupo';
        }
        newgrupoOpcion.map((f) => {
          if (e.GrupoId === f.value) {
            findcantalog = true;
          }
          return f;
        });
        if (!findcantalog) {
          let obj = {
            value: e.GrupoId,
            label: e.Grupo,
          };
          newgrupoOpcion.push(obj);
        }
        return e;
      });
      this.setState({
        grupoOpcion: newgrupoOpcion,
      });
    }

    let xlsx =
      options.downloads && options.downloads.xlsx ? (
        <div>
          <em
            className="fa-2x fas fa-file-excel mr-2"
            style={{ color: '#66bb6a', cursor: 'pointer' }}
            onClick={this.XLSX}
          ></em>
        </div>
      ) : (
        ''
      );
    this.setState(
      {
        xlsx,
      },
      () => {
        if (options && options.selectItems) {
          columns.map((f, index) => {
            if (f.key === 'select') {
              columns.splice(index, 1);
            }
            return f;
          });
          let selectControls = {};
          let lengthData = this.props.data.length;
          for (var i = 0; i < lengthData; i++) {
            this.props.data[i]['mainKey'] = i;
            selectControls[i] = false;
          }
          this.setState({ selectControls, haveCheck: true }, () => {
            columns.unshift({
              label: '',
              key: 'select',
              custom: (value) => {
                return (
                  <Checkbox
                    checked={this.state.selectControls[value.mainKey]}
                    onChange={this.handleChange(value.mainKey)}
                    value={value.mainKey}
                    color="primary"
                  />
                );
              },
            });
            this.setPagination(columns, this.props);
          });
        } else this.setPagination(columns, this.props);
      }
    );
  };

  buildSorts = () => {
    let { columns } = this.state;
    let sorts = {};
    columns.forEach((e) => {
      sorts[e.key ? e.key : e.label] = {
        active: false,
        sortBy: 'ASC',
      };
    });
    this.setState({ sorts }, () => {
      this.buildMainColumns();
      this.buildColumns();
    });
  };

  partirArray = (page, all, pages) => {
    let { itemsByPage } = this.state;
    let array = [];
    if (page === 1)
      for (
        let i = 0;
        i < (all.length < itemsByPage ? all.length : itemsByPage);
        i++
      )
        array.push(all[i]);
    else if (page !== 1 && page !== pages)
      for (let i = (page - 1) * itemsByPage; i < page * itemsByPage; i++)
        array.push(all[i]);
    else
      for (let i = (page - 1) * itemsByPage; i < all.length; i++)
        array.push(all[i]);
    return array;
  };

  setPagination = (columns, props) => {
    let { activePage } = this.state;
    this.setState(
      {
        mainColumns: props.mainColumns ? props.mainColumns : [],
        columns,
        itemsByPage: props.itemsByPage ? props.itemsByPage : 10,
        data: props.data,
      },
      () => {
        this.buildSorts();
        if (props.options && props.options.pagination) {
          let pages =
            props.data.length > this.state.itemsByPage
              ? props.data.length / this.state.itemsByPage + 1
              : 1;
          let tableData = this.partirArray(activePage, props.data, pages);
          let width =
            this.state.columns.length > 2
              ? 100 / this.state.columns.length
              : 50;
          this.setState(
            {
              activePage,
              pages: parseInt(pages),
              tableData,
              width: parseInt(width),
            },
            () => this.buildRows()
          );
        } else this.setState({ tableData: props.data }, () => this.buildRows());
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.options) {
      if (nextProps.options.reset) {
        let keysSelectControls = Object.keys(this.state.selectControls);
        let newSelectControls = this.state.selectControls;
        keysSelectControls.map((e) => {
          newSelectControls[e] = false;
          return e;
        });
        nextProps.data.map((e) => {
          e.checked = false;
          return e;
        });
        this.setState({
          selected: [],
          selectControls: newSelectControls,
          styletd: nextProps.options.styleColumn ? true : false,
        });
      }
    }
    let { options, columns } = nextProps;
    let { selectControls } = this.state;
    let xlsx =
      options.downloads && options.downloads.xlsx ? (
        <div>
          <em
            className="fa-2x fas fa-file-excel mr-2"
            style={{ color: '#66bb6a', cursor: 'pointer' }}
            onClick={this.XLSX}
          ></em>
        </div>
      ) : (
        ''
      );

    this.setState(
      {
        xlsx,
        countryOptions: nextProps.options.catalog,
      },
      () => {
        if (options && options.selectItems && !this.state.haveCheck) {
          let lengthData = nextProps.data.length;
          for (var i = 0; i < lengthData; i++) {
            nextProps.data[i]['mainKey'] = i;
            if (selectControls[i] === true) selectControls[i] = true;
            else selectControls[i] = false;
          }
          this.setState({ selectControls, xlsx }, () => {
            let index = columns.findIndex((x) => x.key === 'select');
            // console.log("index",index);

            if (index === -1)
              columns.unshift({
                label: 'Select',
                key: 'select',
                custom: (value) => {
                  return (
                    <Checkbox
                      checked={this.state.selectControls[value.mainKey]}
                      onChange={this.handleChange(value.mainKey)}
                      value={value.mainKey}
                      color="primary"
                    />
                  );
                },
              });
            this.setPagination(columns, nextProps);
            
          this.setSearch(false);
          });
        } else {
          this.setPagination(columns, nextProps)
          
          this.setSearch(false);
        };
      }
    );
  };

  buildRows = () => {
    let { columns } = this.state;

    let ___rows = this.state.tableData.map((e, _index) => {
      let tds = [];
      if (e) {
        columns.forEach((column, index_) => {
          if (column.custom) {
            tds.push(
              <td
                key={index_}
                className={
                  this.state.styletd === true ? 'table-td2' : 'table-td'
                }
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  minWidth: '150px',
                  maxWidth: '100px',
                }}
              >
                {column.custom(e)}
              </td>
            );
          } else if (column.index) {
            tds.push(
              <td

                onClick={() => { if (this.props.rowClick) this.props.customClick(e) }}
                key={index_}
                className={
                  this.state.styletd === true ? 'table-td2' : 'table-td'
                }
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  minWidth: '150px',
                  maxWidth: '100px',
                }}
              >
                {_index + 1}
              </td>
            );
          } else {
            tds.push(
              <td
                onClick={() => { if (this.props.rowClick) this.props.customClick(e) }}
                key={index_}
                className={
                  (this.state.styletd ? 'valueTable table-td2' : 'valueTable table-td')
                }
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  // fontSize: '14px',
                  // cursor: 'pointer',
                  textAlign: 'center',
                  // minWidth: '150px',
                  // maxWidth: '100px',
                }}
              >
                {e[column.key] ? e[column.key] : ''}
              </td>
            );
          }
        });
      }

      return <tr style={this.props.rowClick ? { cursor: "pointer" } : {}} key={_index}>{tds}</tr>;
    });

    this.setState({ ___rows }, () => this.buildColumns());
  };

  _sortBy = (id) => (event) => {
    let { data, sorts } = this.state;
    if (sorts[id]) {
      let { sortBy } = sorts[id];
      let formatedData = this.makeSort(data, id, sortBy);
      sorts[id].sortBy = sorts[id].sortBy === 'ASC' ? 'DESC' : 'ASC';
      this.setState(
        {
          sorts,
          sortKey: id,
          sortBy: sorts[id].sortBy,
        },
        () => {
          let tableData = this.partirArray(
            this.state.activePage,
            formatedData,
            this.state.pages
          );
          this.setState({ tableData }, () => this.buildRows());
        }
      );
    }
  };

  makeSort = (items, key, by) => {
    switch (by) {
      case 'ASC':
        return items.sort(function (a, b) {
          if (a[key] > b[key]) {
            return 1;
          }
          if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      case 'DESC':
        return items.sort(function (a, b) {
          if (a[key] < b[key]) {
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          }
          return 0;
        });
      default:
        return items;
    }
  };

  buildMainColumns = () => {
    let ___mainColumns = [];
    this.state.mainColumns.forEach((e, index) => {
      ___mainColumns.push(
        <th
          className='tableTitleContenedor'
          key={index}
          id={index}
          style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}
          colspan={e.colspan}
        >
          {e.label}
        </th>
      );
    });
    this.setState({ ___mainColumns });
  };

  buildColumns = () => {
    let ___columns = [];
    let { sortKey, sortBy } = this.state;
    this.state.columns.forEach((e, index) => {
      let column = this.props.options.large ? (
        <Column headerClass={this.props.headerClass}
          title={e.label} isLarge={e.isLarge}
          headerAlign={e.headerAlign}
          colorHeader={this.props.colorHeader} />
      ) : (
        e.label
      );

      if (e.key === sortKey) {
        ___columns.push(
          sortBy === 'DESC' ? (
            <th
              className='tableTitleContenedor'
              key={index}
              id={e.idTh ? e.idTh : index}
            //  onClick={this._sortBy(e.key)}
            >
              <Fade left opposite>
                <em className="icon-arrow-down mr-2"></em>
              </Fade>
              {column}
            </th>
          ) : (
            <th
              className='tableTitleContenedor'
              key={index}
              id={e.idTh ? e.idTh : index}
            // onClick={this._sortBy(e.key)}
            >
              <Fade left opposite>
                <em className="icon-arrow-up mr-2"></em>
              </Fade>
              {column}
            </th>
          )
        );
      } else {
        // if (e.key === 'addAll'){
        //   ___columns.push(
        //     <th key={index} id={e.idTh ? e.idTh : index}  > <span style={{ color: '#8c8c8c', fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} >Agregar Todos <em class="fa-2x mr-2 fas fa-plus-circle"  onClick={ () => console.log("esto es para el all data")} style={{marginTop: "33px", color: "rgb(0, 121, 184)", marginTop : '5px'}} ></em></span></th>
        //   );
        // }
        // else {
        ___columns.push(
          <th
            className='tableTitleContenedor'
            key={index}
            id={e.idTh ? e.idTh : index}
          //  onClick={this._sortBy(e.key)}
          >
            {' '}
            <span
              className={this.props.headerClass && this.props.headerClass !== '' ? this.props.headerClass : ''}
              style={this.props.headerClass && this.props.headerClass !== '' ? {} : {
                color: '#020E3A',
                fontSize: '14px',
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              {column}
            </span>
          </th>
        );
        // }
      }
    });
    this.setState({ ___columns }, () => {
      this.buildTable();
    });
  };
  buildTable = () => {
    if (this.props.bordered) {
    } else {
    }
  };
  handlePaginationChange = (e, { activePage }) => {
    let { data, pages } = this.state;
    let tableData = this.partirArray(activePage, data, pages);
    if (this.props.updateActivePage) {
      this.props.updateActivePage(activePage)
    }
    this.setState({ activePage, tableData }, () => this.buildRows());
  };

  handleSearch = (name) => (event) =>
    this.setState({ [name]: event.target.value }, () => this.setSearch());

  selectSearch = (value) => {
    let all = this.props.data2;
    let results = [];
    if (value) {
      all.forEach((e) => {
        if (e.punto === value.value) {
          results.push(e);
        }
      });
      this.setState({ data: results }, () => {
        let pages =
          results.length > this.state.itemsByPage
            ? Math.ceil(parseInt(results.length) / this.state.itemsByPage)
            : 1;
        let tableData = this.partirArray(1, results, pages);
        this.setState(
          {
            activePage: 1,
            pages: parseInt(pages),
            tableData,
            valor: value.value,
          },
          () => {
            this.props.renderData(this.state.data);
            this.buildRows();
          }
        );
      });
    } else {
      this.props.renderData(this.props.all2);
      this.setState(
        {
          valor: '',
        },
        () => {
          this.setSearch();
        }
      );
    }
  };

  selectSearch2 = (value) => {
    let all = this.props.data;
    let results = [];
    if (value) {
      all.forEach((e) => {
        if (e.GrupoId === value.value) {
          results.push(e);
        }
      });
      this.setState({ data: results }, () => {
        let pages =
          results.length > this.state.itemsByPage
            ? Math.ceil(parseInt(results.length) / this.state.itemsByPage)
            : 1;
        let tableData = this.partirArray(1, results, pages);
        this.setState(
          {
            activePage: 1,
            pages: parseInt(pages),
            tableData,
            grupo: value.value,
          },
          () => {
            this.props.renderData(this.state.data);
            this.buildRows();
          }
        );
      });
    } else {
      this.props.renderData(this.props.all2);
      this.setState(
        {
          grupo: '',
        },
        () => {
          this.setSearch();
        }
      );
    }
  };

  setSearch = (resetPage = true) => {
    let all = this.props.data;
    let options = this.props.options;
    let results = [];
    all.forEach((e) => {
      let found = 0;
      let keys = Object.keys(e);
      keys.forEach((key) => {
        if (
          options.searchKeys &&
          options.searchKeys.length > 0 &&
          options.searchKeys.indexOf(key) < 0
        ) {
          return;
        } else {
          if (
            (e[key]
              ? (e[key] + "")
                .toLowerCase()
                .indexOf(this.state.search.toLowerCase())
              : -1) >= 0
          )
            found += 1;
        }
      });
      if (found !== 0) results.push(e);
    });
    this.setState({ data: results }, () => {
      // let pages =
      //   results.length > this.state.itemsByPage
      //     ? results.length / this.state.itemsByPage + 1
      //     : 1;
      let pages = Math.ceil(parseInt(results.length) / this.state.itemsByPage);

      let tableData = this.partirArray(resetPage ? 1 : this.state.activePage, results, pages);
      this.setState(
        {
          activePage: resetPage ? 1 : this.state.activePage,
          pages: parseInt(pages),
          tableData,
        },
        () => {
          this.buildRows();
        }
      );
    });
  };
  XLSX = () => {
    this.setState(
      {
        xlsx: (
          <div className="ball-clip-rotate">
            <div></div>
          </div>
        ),
      },
      () => {
        let { columns, data } = this.state;
        let headers = [];
        let keys = [];
        columns.map((e) => {
          headers.push(e.label);
          keys.push(e.key);
          return e;
        });
        let obj = {
          data: data,
          options: {
            headers: headers,
            keys: keys,
            image: {
              name: 'seacargo',
            },
          },
        };
        if (headers.length > 0) {
          axios
            .post(
              'https://servicereportapi.azurewebsites.net/report/dynamic/xlsx',
              obj
            )
            .then((success) => {
              if (success.data.base64) {
                //const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';

                let fileName;
                this.props.nameXlsx
                  ? (fileName = this.props.nameXlsx)
                  : (fileName =
                    Math.floor(Math.random() * 900000) +
                    100000 +
                    Math.floor(Date.now() / 1000) +
                    '.xlsx');

                const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
                saveAs(linkSource, fileName);
              } else console.warn('Error downloading file', success);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#020e3a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            })
            .catch((error) => {
              console.error('errorr', error);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#020e3a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            });
        }
      }
    );
  };

  handleAdvancedSearch = (search) => {
    let results = search;
    if (results === undefined) results = 0;
    this.setState({ data: search }, () => {
      let pages =
        results.length > this.state.itemsByPage
          ? results.length / this.state.itemsByPage + 1
          : 1;
      let tableData;

      results === 0
        ? (tableData = [])
        : (tableData = this.partirArray(1, results, pages));

      this.setState(
        {
          activePage: 1,
          pages: parseInt(pages),
          tableData,
        },
        () => this.buildRows()
      );
    });
    this.setState({ isOpen: false });
  };

  handleSearchChange = (e, i) => {
    this.setState({ search: i.value });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const options = this.props.options ? this.props.options : {};
    return (
      <div className="col-12">
        <Grid>
          <Grid.Row>
            {this.props.options.SelectZone ? (
              <>
                <Grid.Column width={2}>
                  <h4 style={{ marginTop: '12px', textAlign: 'end' }}>
                    Almacén:
                  </h4>
                </Grid.Column>
                <Grid.Column style={{ marginLeft: '5px' }} width={3}>
                  <Select
                    placeholder="Seleccione un Almacén"
                    value={this.state.valor}
                    options={this.state.countryOptions}
                    onChange={(value) => {
                      this.selectSearch(value);
                    }}
                  />
                </Grid.Column>
              </>
            ) : (
              <></>
            )}

            {this.props.options.selectGroup ? (
              <>
                <Grid.Column style={{ marginLeft: '5px' }} width={3}>
                  <Select
                    placeholder="Grupo"
                    value={this.state.grupo}
                    options={this.state.grupoOpcion}
                    onChange={(value) => {
                      this.selectSearch2(value);
                    }}
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <InputGroup>
                    {/* <InputGroupAddon addonType="prepend">Buscar</InputGroupAddon> */}
                    <Input
                      placeholder="Nombre del Cliente"
                      onChange={this.handleSearch('search')}
                    />
                  </InputGroup>
                </Grid.Column>
                <Grid.Column style={{ marginTop: '2px' }} width={4}>
                  <em
                    className="fa-2x icon-magnifier mr-2"
                    style={{
                      color: '#a5a0a0',
                      marginTop: '3px',
                      marginLeft: '-22px',
                    }}
                    onClick={this.XLSX}
                  ></em>
                </Grid.Column>
              </>
            ) : (
              <> </>
            )}
            <div className='col-12'>

              <Row>
                <Col xs={8} md={4} lg={4}>
                  {options.search ? (
                    // <InputGroup>
                    //   <InputGroupAddon addonType="prepend">Buscar</InputGroupAddon>
                    //   <Input onChange={this.handleSearch('search')} />
                    // </InputGroup>
                    <Search

                      input={{
                        icon: "search",
                        iconPosition: "left",
                        fluid: true,
                      }}
                      placeholder="..."
                      // onSearchChange={this.handleSearchChange}
                      value={this.state.search}
                      open={false}
                      name="search"
                      id="search"
                      onSearchChange={this.handleSearch('search')}
                    />
                  ) : (
                    ''
                  )}
                </Col>
                <Col Col xs={1} md={6} lg={6}>
                  {options.modal ? (
                    <img
                      width={22}
                      height={22}
                      src={IMG}
                      alt="xml"
                      onClick={this.toggleModal}
                      style={{ cursor: 'pointer' }}
                    ></img>
                  ) : (
                    ''
                  )}
                </Col>

                {/* <Grid.Column width={4} style={{ display: 'flex', alignItems: 'center', margin: '0', padding: '0', }}>

            </Grid.Column> */}

                {/* {this.props.options.SelectZone ? (
              <></>
            ) : (
              <Grid.Column width={6}></Grid.Column>
            )} */}
                <Col Col xs={1} md={1} lg={1}>
                  {this.state.xlsx}
                </Col>
                <Col Col xs={1} md={1} lg={1}>
                  {this.state.customAdd}
                </Col>
              </Row>
            </div>

            {/* <Grid.Column width={1}>{this.state.xlsx}</Grid.Column>
            <Grid.Column width={1}>{this.state.customAdd}</Grid.Column> */}
          </Grid.Row>
        </Grid>
        {/* <div className="col-12" style={{ paddingTop: '1%' }}></div> */}
        {/* <div className="col-12" style={{ marginLeft: '-30px' }}> */}
        <Table
          responsive
          className={
            this.props.type === 'GENERIC'
              ? 'table-height table table-sticky table-bordered'
              : ''
          }
        >
          <thead style={{ textAlign: this.props.noCenter ? '' : 'center' }}>
            <tr>{this.state.___mainColumns}</tr>
            <tr style={{ cursor: 'pointer' }}>{this.state.___columns}</tr>
          </thead>
          <tbody className="table-body">{this.state.___rows}</tbody>
        </Table>

        {options && options.pagination ? (
          <div className="col-12">
            <hr />
          </div>
        ) : (
          ''
        )}
        <div className="col-12" style={{ textAlign: 'center' }}>
          {options && options.pagination ? (
            <Pagination
              ellipsisItem={null}
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={this.state.pages}
            ></Pagination>
          ) : (
            ''
          )}
        </div>
        {options && options.pagination ? (
          <div className="col-12">
            <hr />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default FormExtended;
