class Authentication {
  test = () => {};
  setAuthentication = (token, dataUser, customerSelect, email) => {
    localStorage.setItem('EmailUser', dataUser[0].Email ? dataUser[0].Email : email);
    localStorage.setItem('token', token);
    localStorage.setItem('dataUser', dataUser[0].UserId);
    localStorage.setItem('UserName', dataUser[0].Name);
    localStorage.setItem('UserNameLast', dataUser[0].LastName);
    localStorage.setItem('RolId', dataUser[0].RolId);
    localStorage.setItem('Rol', dataUser[0].Rol);
    localStorage.setItem('DATAUSER', dataUser.toString());
    localStorage.setItem('customerSelect', JSON.stringify(customerSelect)); //FIXME: quitar
  };
  getAuthentication = (value) => {
    return localStorage.getItem(value);
  };
  deleteAuthentication = (value) => {
    localStorage.removeItem(value);
  };
}

export default Authentication;
