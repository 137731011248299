const entityForm = {
  previewForm: [
    {
      type: 'label',
      label: 'Código',
      class: 'col-md-1 input-form',
      placeHolder: 'Código',
      // name: 'EntityId',
      // id: 'EntityId',
      name: 'Code',
      id: 'Code',
      style: {},
      required: true
    },
    {
      type: 'label',
      label: 'Nombre',
      placeHolder: 'Name',
      name: 'Name',
      class: 'col-md-3 input-form',
      id: 'Name',
      style: {}
    },
    {
      type: 'label',
      label: 'País',
      placeHolder: 'Country',
      name: 'Country',
      class: 'col-md-2 input-form',
      id: 'Country',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Estado',
      placeHolder: 'Estado',
      name: 'State',
      class: 'col-md-2 input-form',
      id: 'State',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Ciudad',
      placeHolder: 'City',
      name: 'City',
      class: 'col-md-2 input-form',
      id: 'City',
      style: {},
      required: false
    },
    

    {
      type: 'label',
      label: 'Fecha de Creación',
      class: 'col-md-2 input-form',
      placeHolder: 'CreateDate',
      name: 'CreateDate',
      id: 'CreateDate',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Código Postal',
      class: 'col-md-1 input-form',
      placeHolder: 'Código Postal',
      name: 'ZipCode',
      id: 'ZipCode',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Dirección',
      class: 'col-md-3 input-form',
      placeHolder: 'Dirección',
      name: 'Address',
      id: 'Address',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Dirección 2',
      class: 'col-md-2 input-form',
      placeHolder: 'Dirección 2',
      name: 'Address2',
      id: 'Address2',
      style: {},
      required: false

    },
    {
      type: 'label',
      label: 'Teléfono',
      class: 'col-md-2 input-form',
      placeHolder: 'Teléfono',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Usuarios Asociados',
      class: 'col-md-2 input-form',
      placeHolder: 'AssociatedUserId',
      name: 'AssociatedUserId',
      id: 'AssociatedUserId',
      style: {},
      required: false
    }

  ],
  editEntity: [
    {
      type: 'label',
      label: 'Código',
      class: 'col-md-2 input-form',
      placeHolder: 'Nombre',
      name: 'EntityId',
      id: 'EntityId',
      style: {},
      required: true
    },
    {
      type: 'label',
      label: 'Nombre',
      placeHolder: 'Name',
      name: 'Name',
      class: 'col-md-3 input-form',
      id: 'Name',
      style: {}
    },

    // {
    //   type: 'Input',
    //   label: 'País',
    //   class: 'col-md-3 input-form',
    //   placeHolder: 'Country',
    //   name: 'Country',
    //   id: 'Country',
    //   style: {},
    //   required: true
    // },
    // {
    //   type: 'Select',
    //   options: 'CountryId',
    //   label: 'País',
    //   name: 'Country',
    //   placeHolder: 'Elegir',
    //   class: 'col-md-3',
    //   id: 'Country',
    //   style: {},
    //   required: false
    // },
    {
      type: 'Select',
      options: 'CountryId',
      label: 'País',
      name: 'CountryId',

      class: 'col-md-2 input-form',
      id: 'CountryId',
      selectCatalogName: "Paises",
      style: {}
    },
    {
      type: 'Input',
      label: 'Ciudad',
      class: 'col-md-2 input-form',
      placeHolder: 'Ciudad',
      name: 'City',
      id: 'City',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Código Postal',
      class: 'col-md-2 input-form',
      placeHolder: 'Código Postal',
      name: 'ZipCode',
      id: 'ZipCode',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Estado',
      class: 'col-md-2 input-form',
      placeHolder: 'Estado',
      name: 'State',
      id: 'State',
      style: {},
      required: false
    },
    
    {
      type: 'Input',
      label: 'Dirección',
      class: 'col-md-3 input-form',
      placeHolder: 'Dirección',
      name: 'Address',
      id: 'Address',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Dirección 2',
      class: 'col-md-3 input-form',
      placeHolder: 'Dirección 2',
      name: 'Address2',
      id: 'Address2',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Teléfono',
      class: 'col-md-3 input-form',
      placeHolder: 'Teléfono',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    }
  ],

}
export default entityForm