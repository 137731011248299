import React, { Component, Suspense } from "react";
import ContentWrapper from "./../../../../template/Layout/ContentWrapper";
import { Row } from "reactstrap";

import { message } from "antd";

import { Tab, Icon } from "semantic-ui-react";
import dataResumen from "../../../../Model/resumenCard";
// import Documents from '../../../Components/Documents';
// import Comments from '../../../Components/Comments';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Services } from "../../Services";
import { validateIdParams } from "../../../../utils/regularExpressionValidation";
const Events = React.lazy(() => import("./Events"));
const Summary = React.lazy(() => import("./Summary"));

class ContainerDetails extends Component {
  state = {
    allSteps: [],
    id: this.props.match.params.id,
    dataResumen: dataResumen,
    dataAlmacenada: [],
    dataContainer: {},
    shipment: [],
    commentsData: [
      {
        ComType: "Tipo 1",
        ComTypeId: 1,
        Comment: "Comentario de prueba",
        CommentId: 73,
        Date: "2019-06-20",
        Module: "Comentario",
        ModuleId: 1,
        RowId: 861,
        UpdateBy: "API",
      },
      {
        ComType: "Tipo 2",
        ComTypeId: 1,
        Comment: "Comentario de prueba",
        CommentId: 73,
        Date: "2019-07-02",
        Module: "Comentario",
        ModuleId: 1,
        RowId: 861,
        UpdateBy: "API",
      },
    ],
    catalogsData: [
      {
        ComTypeId: 1,
        ModuleId: 1,
        ComType: "Supplier Follow Up",
        value: 1,
        label: "Supplier Follow Up",
      },
      {
        ComTypeId: 2,
        ModuleId: 1,
        ComType: "Order Quality",
        value: 2,
        label: "Order Quality",
      },
      {
        ComTypeId: 3,
        ModuleId: 1,
        ComType: "Order Quantities",
        value: 3,
        label: "Order Quantities",
      },
      {
        ComTypeId: 10,
        ModuleId: 1,
        ComType: "Other",
        value: 10,
        label: "Other",
      },
    ],
    stepsIMPO: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 3,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 4,
        idStep: "step4",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Despachado",
        value: "",
        id: 19,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Entregado",
        value: "",
        id: 23,
        completed: false,
        order: 6,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 7,
      //   idStep: 'step6',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    stepsEXPO: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Despachado",
        value: "",
        id: 10,
        completed: false,
        order: 3,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 4,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 6,
      //   idStep: 'step6',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    stepsDOMESTICO: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 3,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 4,
        idStep: "step4",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Entregado",
        value: "",
        id: 23,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 6,
      //   idStep: 'step5',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    stepsTERRESTRE: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 3,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Cruce de frontera",
        value: "",
        id: 13,
        completed: false,
        order: 4,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },

      {
        label: "En tránsito a destino",
        value: "",
        id: 15,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 6,
        idStep: "step6",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Despachado",
        value: "",
        id: 20,
        completed: false,
        order: 7,
        idStep: "step7",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 8,
      //   idStep: 'step8',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    // stepsIMPO: [
    //   {
    //     label: 'Reservado',
    //     value: '',
    //     id: 1,
    //     completed: false,
    //     order: 1,
    //     idStep: 'step1',
    //     middleStep: ''
    //   },
    //   {
    //     label: 'Recolectado',
    //     value: '',
    //     id: 1,
    //     completed: false,
    //     order: 3,
    //     idStep: 'step1',
    //     middleStep: ''
    //   },
    //   {
    //     label: 'Salió',
    //     value: '',
    //     id: 1,
    //     completed: false,
    //     order: 3,
    //     idStep: 'step1',
    //     middleStep: ''
    //   },
    //   {
    //     label: 'Llegó',
    //     value: '',
    //     id: 1,
    //     completed: false,
    //     order: 4,
    //     idStep: 'step1',
    //     middleStep: ''
    //   },
    //   {
    //     label: 'Despachado',
    //     value: '',
    //     id: 2,
    //     completed: false,
    //     order: 5,
    //     idStep: 'step2',
    //     middleStep: ''
    //   },
    //   {
    //     label: 'Entregado',
    //     value: '',
    //     id: 5,
    //     completed: false,
    //     order: 6,
    //     idStep: 'step4',
    //     middleStep: ''
    //   },
    //   {
    //     label: 'Retorno de vacío',
    //     value: '',
    //     id: 6,
    //     completed: false,
    //     order: 7,
    //     idStep: 'step5',
    //     middleStep: ''
    //   }
    // ],
    // stepsEXPO: [
    //   // {
    //   //   label: 'Reservado',
    //   //   value: '',
    //   //   id: 1,
    //   //   completed: false,
    //   //   order: 1,
    //   //   idStep: 'step1',
    //   //   middleStep: ''
    //   // },
    //   // {
    //   //   label: 'Recolectado',
    //   //   value: '',
    //   //   id: 1,
    //   //   completed: false,
    //   //   order: 2,
    //   //   idStep: 'step1',
    //   //   middleStep: ''
    //   // },
    //   // {
    //   //   label: 'Despachado',
    //   //   value: '',
    //   //   id: 2,
    //   //   completed: false,
    //   //   order: 3,
    //   //   idStep: 'step2',
    //   //   middleStep: ''
    //   // },
    //   // {
    //   //   label: 'Salió',
    //   //   value: '',
    //   //   id: 1,
    //   //   completed: false,
    //   //   order: 4,
    //   //   idStep: 'step1',
    //   //   middleStep: ''
    //   // },
    //   // {
    //   //   label: 'Llegó',
    //   //   value: '',
    //   //   id: 1,
    //   //   completed: false,
    //   //   order: 5,
    //   //   idStep: 'step1',
    //   //   middleStep: ''
    //   // },
    //   // {
    //   //   label: 'Entregado',
    //   //   value: '',
    //   //   id: 5,
    //   //   completed: false,
    //   //   order: 6,
    //   //   idStep: 'step4',
    //   //   middleStep: ''
    //   // },
    //   // {
    //   //   label: 'Retorno de vacío',
    //   //   value: '',
    //   //   id: 6,
    //   //   completed: false,
    //   //   order: 7,
    //   //   idStep: 'step5',
    //   //   middleStep: ''
    //   // }
    //   {
    //     label: 'Reservado',
    //     value: '',
    //     id: 2,
    //     completed: false,
    //     order: 1,
    //     middleStep: '',
    //     classValue: "subTitleCard",
    //     classTittle: "statusStepTittle"
    //   }, {
    //     label: 'Recolectado',
    //     value: '',
    //     id: 7,
    //     completed: false,
    //     order: 2,
    //     idStep: '',
    //     middleStep: '',
    //     classValue: "subTitleCard",
    //     classTittle: "statusStepTittle"
    //   },
    //   {
    //     label: 'Despachado',
    //     value: '',
    //     id: 10,
    //     completed: false,
    //     order: 3,
    //     idStep: '',
    //     middleStep: '',
    //     classValue: "subTitleCard",
    //     classTittle: "statusStepTittle"
    //   },
    //   {
    //     label: 'Salió',
    //     value: '',
    //     id: 11,
    //     completed: false,
    //     order: 4,
    //     middle: false,
    //     idStep: '',
    //     middleStep: '',
    //     classValue: "subTitleCard",
    //     classTittle: "statusStepTittle"
    //   },
    //   {
    //     label: 'Llegó',
    //     value: '',
    //     id: 17,
    //     completed: false,
    //     order: 5,
    //     idStep: 'step5',
    //     middleStep: '',
    //     classValue: "subTitleCard",
    //     classTittle: "statusStepTittle"
    //   },
    //   {
    //     label: 'Retorno de vacío',
    //     value: '',
    //     id: 21,
    //     completed: false,
    //     order: 7,
    //     idStep: 'step5',
    //     middleStep: '',
    //     classValue: "subTitleCard",
    //     classTittle: "statusStepTittle"
    //   }
    // ],
    dataTabla: [],
  };
  sendMessage = (text, type) => {
    var messageClass = "";
    if (type === "error") {
      messageClass = "error-custom-ant-message";
    } else if (type === "success") {
      messageClass = "general-custom-ant-message success-custom-ant-message";
    } else if (type === "info") {
      messageClass = "info-custom-ant-message";
    }
    message.open({
      content: text,
      className: messageClass,
    });
  };
  componentDidMount = () => {
    this.getContainerForId();
  };
  obtenerSteps = (shipment) => {
    var steps = [];
    if (
      shipment.TransportModeCode === "A" ||
      shipment.TransportModeCode === "O"
    ) {
      if (shipment.DirectionCode === "I") {
        steps = this.state.stepsIMPO;
      } else if (shipment.DirectionCode === "E") {
        steps = this.state.stepsEXPO;
      } else if (shipment.DirectionCode === "D") {
        steps = this.state.stepsDOMESTICO;
      }
    } else {
      steps = this.state.stepsTERRESTRE;
    }
    return steps;
  };
  getEvents = () => {
    const { steps, id, shipment } = this.state;
    // let idContainer = 1816;
    //axios.get(`${Services.TRACEABILITY.path}trazabilidad/containersevents/${id}`)

    axios
      .get(
        `${Services.TRACEABILITY.path}trazabilidad/shipmentevents/${shipment.ShipmentId}`
      )
      .then((response) => {
        //let datatempo = { "message": "SUCCESS", "customMessage": "FIND CONTAINER EVENTS", "data": [{ "EventId": 3750849, "RowId": 14400, "ModuleId": 4, "Module": "Contenedores", "EventDescriptionId": 5, "EventDescription": "Salida de Puerto", "EventTypeId": 7, "EventType": "Eventos del Contenedor", "InTimeLine": "1", "EventDate": "24-02-2019", "CategoryResponsableId": 3, "CategoryResponsable": "Transportista", "Origin": "iCargo", "CreateDate": "18-05-2020" }], "steps": [{ "name": "Confirmed", "date": "18-05-2020", "completed": true, "EventDescriptionId": 31 }, { "name": "Booked", "date": "18-05-2020", "completed": true, "EventDescriptionId": 34 }, { "name": "Departed", "date": "18-05-2020", "completed": false, "EventDescriptionId": 62 }, { "name": "Arrived", "date": "18-05-2020", "completed": false, "EventDescriptionId": 91 }, { "name": "Docs Released", "date": "18-05-2020", "completed": false, "EventDescriptionId": 97 }, { "name": "Customs Released", "date": "18-05-2020", "completed": false, "EventDescriptionId": 98 }], "code": 200 }

        let datatempo = {
          message: "SUCCESS",
          customMessage: "FIND CONTAINER EVENTS",
          steps: [],
          data: [],
          code: 200,
        };
        let update = false;
        for (let index = datatempo.steps.length; index >= 0; index--) {
          const element = datatempo.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = this.obtenerSteps(shipment);
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === "1") {
                f.completed = true;
              }
              // else {
              //   findNext = false;
              // }
            } else {
              findNext = false;
            }
            // if (findNext) {
            //   f.completed = true;
            // }
            return e;
          });
          return f;
        });
        newSteps.sort(function (a, b) {
          return b.order - a.order;
        });
        let findTrue = false;
        newSteps.map((e) => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function (a, b) {
          return a.order - b.order;
        });

        this.setState({ steps: newSteps, allSteps: dataEvents }, () => {});
      })
      .catch((error) => {});
  };

  getContainerForId = () => {
    let idValidate = validateIdParams(this.state.id);
    if (!idValidate) {
      this.sendMessage(`Los datos ingresados no son válidos`, "error");
      setTimeout(() => {
        this.props.history.push("/trazabilidad");
      }, 2000);
      return;
    }
    axios
      .get(
        `${Services.TRACEABILITY.path}trazabilidad/ContainerDetail/${this.state.id}`
      )
      .then((success) => {
        let dataTabla = success.data.item;
        let newResume = dataResumen.map((f) => {
          f.data.map((g) => {
            g.value = success.data.data[0][g.key];
            g.value2 = success.data.data[0][g.idlink];
            return g;
          });
          return f;
        });
        this.setState(
          {
            dataResumen: newResume,
            doEmbarque: success.data.data[0].ShipmentNumber,
            noContenedor: success.data.data[0].ContainerNo,
            dataAlmacenada: success.data.data[0],
            shipment: success.data.shipment,
            dataTabla,
          },
          () => {
            this.getEvents();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
        this.sendMessage(`Los datos ingresados no son válidos`, "error");
        setTimeout(() => {
          this.props.history.push("/trazabilidad");
        }, 2000);
        return;
      });
  };

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <legend className="styleTitleModule">
            <em
              // onClick={this.moreContact()}
              className="icon-final-08"
              style={{ color: "#020E3A", marginRight: "1em" }}
            ></em>
            Detalle del Contenedor
          </legend>

          <div
            className="float-right"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#020e3a",
              fontSize: "10px",
              marginBottom: "5px",
            }}
          >
            <Icon.Group size="large">
              <Icon
                name="angle left"
                className="_btnBack"
                size="big"
                onClick={() =>
                  this.props.history.push(
                    "/shipmentsDetal/" + this.state.shipment.ShipmentId
                  )
                }
              />
            </Icon.Group>
            <h3
              style={{
                fontWeight: "initial",
                fontSize: "18px",
              }}
              className="_btnBack"
              onClick={() =>
                this.props.history.push(
                  "/shipmentsDetal/" + this.state.shipment.ShipmentId
                )
              }
            >
              Regresar
            </h3>
          </div>
        </div>
        <Tab
          menu={{ secondary: true, className: "myWrapped", pointing: true }}
          menuPosition="right"
          panes={[
            {
              menuItem: "Resumen",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <Summary
                    dataTabla={this.state.dataTabla}
                    dataAlmacenada={this.state.dataAlmacenada}
                    steps={this.state.steps}
                    data={this.state.dataResumen}
                  ></Summary>
                </Suspense>
              ),
            },
            //{ menuItem: 'Eventos', render: () => <Suspense fallback={<div>Loading...</div>}> <Events noContenedor={this.state.noContenedor} doEmbarque={this.state.doEmbarque} steps={this.state.steps} allSteps={this.state.allSteps} ></Events></Suspense> },
            // { menuItem: 'Comentarios', render: () => <div><Comments data={this.state.commentsData} catalogs={this.state.catalogsData}> </Comments></div> },
            // { menuItem: 'Documentos', render: () => <div><Documents rolId={12} shipment="" shipmentDataPlane=""></Documents></div> },
          ]}
        />
      </ContentWrapper>
    );
  }
}
export default withRouter(ContainerDetails);
