import React, { Suspense } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import ContentWrapper from './../../template/Layout/ContentWrapper';
// import StandardReports from '../Components/Reports/StandardReports';
import { Tab, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import './styles.scss';

const StandardReports = React.lazy(() => import('./StandardReports'));
const MyReports = React.lazy(() => import('./MyReports/List'));
const SharedReports = React.lazy(() => import('./SharedReports'));

/*=============================================
=            Reports && analytics            =
=============================================*/

function ReportAnalytics(props) {
  const onChangeTab = function () {
    document.getElementById("regresar-div").innerHTML = "";
  }
  const tabs = [
    {
      menuItem: 'Reportes Estándar',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <StandardReports type="Standard" user={props._user} />
        </Suspense>
      ),
    },
    {
      menuItem: 'Mis Reportes',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <MyReports type="MyReports" user={props._user} />
        </Suspense>
      ),
    },
    {
      menuItem: 'Reportes Compartidos',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <SharedReports type="SharedReports" user={props._user} />
        </Suspense>
      ),
    },
  ];
  // ? Validación por roles de usuario
  // if(props._rolId === '1' || props._rolId === '4'){
  //   tabs.push({ menuItem: 'Mis Reportes', render: () => <Suspense fallback={<div>Loading...</div>}> <MyReports type="MyReports" user={props._user}/></Suspense>});
  //   tabs.push({ menuItem: 'Reportes Compartidos', render: () => <Suspense fallback={<div>Loading...</div>}> <SharedReports type="SharedReports" user={props._user}/></Suspense>});
  // }

  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="styleTitleModule">
          <em
            // onClick={this.moreContact()}
            className=" icon-final-11"
            style={{ color: "#020E3A" }}
          ></em>
          Reportes y Análiticos{" "}
        </legend>{
          <div id="regresar-div" style={{ marginRight: "8px" }}></div>
        }
      </div>
      <Card className="myCard">
        <CardBody>
          <Tab
            menu={{ secondary: true, className: "wrapped", pointing: true }}
            onTabChange={onChangeTab}
            //       // activeIndex={tabIndex}
            menuPosition="right"
            panes={tabs}
          />
        </CardBody>
      </Card>
    </ContentWrapper>
  );
}

export default withRouter(ReportAnalytics);
