const cardResumen = [
  {
    label: 'Información General',
    data: [
      // {
      //   //   value: 'NoAduana',
      //   //   key: '',
      //   //   label: 'Número de Do Aduana IMPO/EXPO:',
      //   //   bold: false,
      //   //   info: false
      //   // },
      //   // {
      //   //   value: 'RefForwarder',
      //   //   key: '',
      //   //   primary: true,
      //   //   label: 'Número de Do Referencia Forwarder',
      //   //   bold: false,
      //   //   info: false
      //   // },
      //   // {
      //   value: '',
      //   key: 'ContainerNo',
      //   label: 'Numero de Contenedor',
      //   bold: false
      // },
      // {
      //   value: '',
      //   key: 'Status',
      //   label: 'Estatus',
      //   bold: false,
      //   info: false,
      //   // link:'/shipmentsDetal/',
      //   // idlink:'EmbarqueId',
      //   value2: ''
      // },
      {
        value: '',
        key: 'ShipmentNumber',
        link:'/shipmentsDetal/',
        idlink:'ShipmentId',
        label: 'No de Embarque',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'ContainerType',
        label: 'Tipo de Contenedor',
        bold: false,
        info: false
      },
      // {
      //   value: '',
      //   key: 'Seal',
      //   label: 'Precinto 1',
      //   bold: false,
      //   info: false
      // },
      // {
      //   value: '',
      //   key: 'Seal2',
      //   label: 'Precinto 2',
      //   bold: false
      // },
      {
        value: '',
        key: 'Seals',
        label: 'Sello',
        bold: false
      },
      // {
      //   value: '',
      //   key: 'Temperature',
      //   label: 'Temperatura',
      //   bold: false
      // },
      {
        value: '',
        key: 'TEUS',
        label: 'Teus',
        bold: false
      },
      // {
      //   value: '',
      //   key: 'Dimensions',
      //   label: 'Dimensiones',
      //   bold: false
      // },
      
      // {
      //   value: '',
      //   key: 'Tare',
      //   label: 'Tare',
      //   primary: false,
      //   otro: true,
      // }
    ]
  },
  {
    label: 'Ruta',
    data: [
      // {
      //   value: '',
      //   key: 'ETD',
      //   label: 'ETD',
      //   bold: false
      // },
      // {
      //   value: '',
      //   key: 'ETA',
      //   label: 'ETA',
      //   bold: false
      // },
      // {
      //   value: '',
      //   key: 'PickUpPortCode',
      //   label: 'Puerto de Recolección',
      //   bold: false
      // },
      // {
      //   value: '',
      //   key: 'PickUpReference',
      //   label: 'Referencia de Recolección',
      //   bold: false
      // },
      // {
      //   value: '',
      //   key: 'DeliveryPortCode',
      //   label: 'Lugar Entrega',
      //   bold: false
      // },
      // {
      //   value: '',
      //   key: 'PickUpTransportMode',
      //   label: 'Modo de Transporte',
      //   bold: false
      // }, {
      //   value: '',
      //   key: 'DeliveryReference',
      //   label: 'Referencia del Cliente',
      //   bold: false
      // }, {
      //   value: '',
      //   key: 'DeliveryTransportMode',
      //   label: 'Modo de Transporte de Entrega',
      //   bold: false
      // }}
      {
        value: '',
        key: 'POL',
        label: 'Origen',
        bold: false
     },
     {
        value: '',
        key: 'POD',
        label: 'Destino',
        bold: false
     },
     
     // {
     //    value: '',
     //    key: 'POD',
     //    label: 'Destino Final',
     //    bold: false
     // },
     {
        value: '',
        key: 'PickUpPortCode',
        label: 'Lugar Recolección',
        bold: false
     },
     //  {
     //    value: '',
     //    key: 'PickUpTransportMode',
     //    label: 'Modo de Transporte de Recolección',
     //    bold: false
     // },
     // {
     //    value: '',
     //    key: 'PickUpReference',
     //    label: 'Referencia de Recolección',
     //    bold: false
     // },
     {
        value: '',
        key: 'DeliveryPortCode',
        label: 'Lugar Entrega',
        bold: false
     },
     // {
     //    value: '',
     //    key: 'DeliveryTransportMode',
     //    label: 'Modo de Transporte de Entrega',
     //    bold: false
     // },

     // {
     //    value: '',
     //    key: 'DeliveryReference',
     //    label: 'Referencia de Entrega',
     //    bold: false
     // },





     // {
     //    value: '',
     //    key: 'ETD',
     //    label: 'ETD',
     //    bold: false
     // },
     // {
     //    value: '',
     //    key: 'EstimatedFinalTimeArrival',
     //    label: 'ETD',
     //    bold: false
     // },
     {
        value: '',
        key: 'ETD',
        label: 'ETD',
        bold: false
     },
     {
        value: '',
        key: 'ETA',
        label: 'ETA',
        bold: false
     },
    
    ]
  },
  {
    label: 'Carga',
    data: [
      {
        value: '',
        key: 'TotalPieces',
        label: 'Total Piezas',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'Weight',
        label: 'Total Peso',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'Volume',
        label: 'Total Volumen',
        primary: false,
        otro: false
      },
      // {
      //   value: '',
      //   key: 'Weight',
      //   label: 'Peso',
      //   primary: false,
      //   otro: false
      // },
      {
        value: '',
        key: 'Commodity',
        label: 'Mercancía',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'DescriptionLoad',
        label: 'Descripción de la carga',
        primary: false,
        otro: true,
      }
      // , {
      //   value: '',
      //   key: 'PackagesType',
      //   label: 'Tipo de Paquete',
      //   primary: false,
      //   otro: true,
      // }
      // ,{
      //   value: '',
      //   key: 'ContainerType',
      //   label: 'Tipo de Contenedor',
      //   primary: false,
      //   otro: true,
      // },
    ]
  }]

export default cardResumen