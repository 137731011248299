import React, { Component } from "react";
// import ShipmentModel from '../../models/shipment.model'
// import shipmentDataPlaneModel from '../../models/shipment.plane.model'
import AddComment from "./components/add-comment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "@mui/material/Button";
// import RestService from '../../../../../../services/restService'
import Table from "./../Table";
import Loader from "./../../../Global/Loader";
import { Card, CardBody } from "reactstrap";
import axios from "axios";
import { saveAs } from "file-saver";
import { Services } from "../../Traceability/Services";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Menu, Dropdown } from "antd";
import {
  MoreOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
  MonitorOutlined,
} from "@ant-design/icons";
import "./styles.scss";

const rutaArchivos = "https://magiclog.blob.core.windows.net/seacargofile/";
// const rest = new RestService()
const size = "18px";

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      shipment: "",
      fullLoad: true,
      shipmentDataPlane: "",
      comments: [],
      disabledView: false,
      disabledDownload: false,
      data: [],
      columDescripcion: false,
      columns: this.props.storage
        ? [
            {
              label: "Acciones",
              key: "",
              minWidth: 60,
              align: "center",
              custom: (value) => (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => this.viewDocument(value, true)}>
                        <CloudDownloadOutlined style={{ fontSize: size }} />
                        <i className="global-text"> Download</i>
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => this.viewDocument(value, false)}
                      >
                        <MonitorOutlined style={{ fontSize: size }} />{" "}
                        <i className="global-text">View</i>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="topRight"
                >
                  <div>
                    <MoreOutlined
                      className="btn-expander"
                      style={{ fontSize: "26px" }}
                    />
                  </div>
                </Dropdown>
              ),
            },
            { label: "Nombre del Documento", key: "DocumentName" },
            { label: "Tipo de Documento", key: "Type" },
            // { label: 'Categoría', key: 'Category' },
            // { label: 'Fecha de Creación', key: 'CreationDate' },
            // { label: 'Actualizado por', key: 'CreateBy' },
            { label: "Fecha de Creación", key: "CreationDateDocument" },
            { label: "Actualizado por", key: "CreationDateDocument" },

            // {
            //     label: 'Descarga', key: 'URL', custom: (value) => {
            //         return (
            //             <Button style={{color: 'rgb(93, 156, 236)'}} >
            //             {/* <a href={value} className="fas fa-download fa-2x " download="sample.PDF"> </a> */}
            //             {/* <a className="fas fa-download fa-2x dataIcon" onClick={() => window.open(value.URL)}></a> */}
            //             <a className="fas fa-download fa-2x dataIcon " href={value.URL}></a>
            //             </Button>
            //         )
            //     }
            // },
            {
              label: "Visualizar",
              key: "DocumentKeyAsString",
              custom: (value) => {
                return (
                  <Button
                    style={{ color: "rgb(101, 101, 101)" }}
                    disabled={this.disabledView}
                  >
                    <button
                      style={{ color: "#5d9cec" }}
                      className="fas fa-search fa-2x  dataIcon"
                      onClick={() =>
                        value.origin && value.origin === "expo"
                          ? this.viewDocument(
                              value.DocumentKeyAsString,
                              value.DocumentName,
                              value.Type
                            )
                          : window.open(value.DocumentKeyAsString)
                      }
                    ></button>
                  </Button>
                );
              },
            },
          ]
        : this.props.columDescripcion === true
        ? [
            {
              label: "Acciones",
              key: "",
              minWidth: 60,
              align: "center",
              custom: (value) => (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => this.viewDocument(value, true)}>
                        <CloudDownloadOutlined style={{ fontSize: size }} />
                        <i className="global-text"> Download</i>
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => this.viewDocument(value, false)}
                      >
                        <MonitorOutlined style={{ fontSize: size }} />{" "}
                        <i className="global-text">View</i>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="topRight"
                >
                  <div>
                    <MoreOutlined
                      className="btn-expander"
                      style={{ fontSize: "26px" }}
                    />
                  </div>
                </Dropdown>
              ),
            },
            // { label: 'Nombre del Documento', key: 'Name' },
            // { label: 'Tipo de Documento', key: 'Type' },
            // { label: 'Descripción', key: 'Descripcion' },
            // { label: 'Fecha de Creación', key: 'CreationDate' },
            // { label: 'Actualizado por', key: 'CreateBy' },
            { label: "Nombre del Documento", key: "DocumentName" },
            { label: "Tipo de Documento", key: "Type" },
            // { label: 'Categoría', key: 'Category' },
            // { label: 'Fecha de Creación', key: 'CreationDate' },
            // { label: 'Actualizado por', key: 'CreateBy' },
            { label: "Fecha de Creación", key: "CreationDateDocument" },
            { label: "Actualizado por", key: "CreationDateDocument" },

            // {
            //   label: 'Descarga', key: 'URL', custom: (value) => {
            //     return (
            //       <Button style={{ color: 'rgb(93, 156, 236)' }} >
            //         {/* <a href={value} className="fas fa-download fa-2x " download="sample.PDF"> </a> */}
            //         {/* <a className="fas fa-download fa-2x dataIcon" onClick={() => window.open(value.URL)}></a> */}
            //         <a className="fas fa-download fa-2x dataIcon " href={value.URL}> </a>
            //       </Button>
            //     )
            //   }
            // },
            // {
            //   label: 'Visualizar', key: 'DocumentKeyAsString', custom: (value) => {
            //     return (
            //       value.Type === 'xlsx' ? <></> :
            //         <Button style={{ color: 'rgb(101, 101, 101)' }} disabled={this.disabledView}>
            //           <button style={{ color: '#5d9cec' }} className="fas fa-search fa-2x  dataIcon" onClick={() => value.origin && value.origin === 'expo' ? this.viewDocument(value.DocumentKeyAsString, value.Name, value.Type) : window.open(value.DocumentKeyAsString)}></button>
            //         </Button>
            //     )
            //   }
            // },
          ]
        : [
            {
              label: "Acciones",
              key: "",
              minWidth: 60,
              align: "center",
              custom: (value) => (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() =>
                          this.setState({ fullLoad: false }, () =>
                            this.viewDocument(
                              value,
                              true,
                              value.Type,
                              value.URL
                            )
                          )
                        }
                      >
                        {/* <CloudDownloadOutlined style={{ fontSize: size }} /> */}
                        <i className="global-text"> Descargar</i>
                      </Menu.Item>
                      {["png", "jpg", "pdf"].indexOf(value.Type) >= 0 ? (
                        <Menu.Item
                          onClick={() =>
                            this.setState({ fullLoad: false }, () =>
                              this.viewDocument(
                                value,
                                false,
                                value.Type,
                                value.URL
                              )
                            )
                          }
                        >
                          {/* <MonitorOutlined style={{ fontSize: size }} />  */}
                          <i className="global-text">Ver</i>
                        </Menu.Item>
                      ) : (
                        false
                      )}
                    </Menu>
                  }
                  placement="topRight"
                >
                  <div>
                    <MoreOutlined
                      className="btn-expander"
                      style={{ fontSize: "26px" }}
                    />
                  </div>
                </Dropdown>
              ),
            },
            { label: "Nombre del documento", key: "DocumentCategoryName" },
            { label: "Categoría del Documento", key: "Category" },
            // { label: 'Categoría', key: 'Category' },
            // { label: 'Fecha de Creación', key: 'CreationDate' },
            // { label: 'Actualizado por', key: 'CreateBy' },
            { label: "Fecha de Creación", key: "CreationDateDocument" },

            // {
            //   label: 'Descarga', key: 'URL', custom: (value) => {
            //     return (
            //       <Button style={{ color: '#020E3A' }} >
            //         {/* <a href={value} className="fas fa-download fa-2x " download="sample.PDF"> </a> */}
            //         {/* <a className="fas fa-download fa-2x dataIcon" onClick={() => window.open(value.URL)}></a> */}
            //         <a className="fas fa-download fa-2x dataIcon seacargo-icon" href={value.URL}> </a>
            //       </Button>
            //     )
            //   }
            // },
            // {
            //   label: 'Visualizar', key: 'DocumentKeyAsString', custom: (value) => {
            //     return (
            //       <Button style={{  color: '#020E3A'  }} disabled={this.disabledView}>
            //         <button style={{ color: '#020E3A' }} className="fas fa-search fa-2x  dataIcon" onClick={() => value.origin && value.origin === 'expo' ? this.viewDocument(value.DocumentKeyAsString, value.Name, value.Type) : window.open(value.DocumentKeyAsString)}></button>
            //       </Button>
            //     )
            //   }
            // },
          ],
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        data: this.props.dataDoc ? this.props.dataDoc : [],
        // columDescripcion:this.props.columDescripcion ? this.props.columDescripcion :false,
        shipment: this.props.shipment,
        shipmentDataPlane: this.props.shipmentDataPlane,
      },
      () => {
        // this.getComments()
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.dataDoc ? nextProps.dataDoc : [],
      // columDescripcion:nextProps.columDescripcion ? nextProps.columDescripcion :false,
      shipment: nextProps.shipment,
      shipmentDataPlane: nextProps.shipmentDataPlane,
    });
  };

  getComments = () => {
    let { shipment } = this.state;
    let id = shipment.id;
    // let id = 7328908;
    // axios.get(`http://192.168.1.73:3017/documents/findDocumentsWPS/${id}`)
    axios
      .get(
        `https://poseidonbooking.azurewebsites.net/documents/findDocumentsWPS/${id}`
      )
      .then((response) => {
        this.setState({ data: response.data.data });
        this.getCommentsView();
      })
      .catch((error) => {});
  };

  getCommentsView = () => {
    let { shipmentDataPlane } = this.state;
    let id = shipmentDataPlane.ShipmentId;
    // let id = 122;
    let moduleId = 3;
    // axios.get(`http://192.168.1.73:3017/documents/findDocumentsView/${moduleId}/${id}`)
    let url = `https://poseidonbooking.azurewebsites.net/documents/findDocumentsView/${moduleId}/${id}`;
    axios
      .get(url)
      .then((response) => {
        let newData = this.state.data;
        let viewData = response.data.data;
        viewData.map((e) => {
          newData.push(e);
          return e;
        });
        this.setState({ data: newData });
      })
      .catch((error) => {});
  };

  toggle = (evt) => {
    // this.setState(prevState => ({
    //   modal: !prevState.modal
    // }));
  };

  callback = (success) => {
    this.setState(
      (prevState) => ({
        modal: !prevState.modal,
      }),
      () => this.getComments()
    );
  };

  onError = (error) => {
    console.log(error);
    this.setState(
      (prevState) => ({
        modal: !prevState.modal,
      }),
      () => this.getComments()
    );
  };

  viewDocument = (id, name, Type, URL) => {
    console.log("view");
    let data = {
      id: id,
      name: name,
    };

    // let file_path = (rutaArchivos + URL)
    // var a = document.createElement('A');
    // a.href = file_path;
    // a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    // axios({
    //   url: encodeURI(rutaArchivos + URL),
    //   method: 'GET',
    //   responseType: 'blob', // important
    // }).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'file.pdf');
    //   document.body.appendChild(link);
    //   link.click();
    // });

    axios({
      method: "get",
      url: encodeURI(rutaArchivos + URL), // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
      responseType: "blob",
    }).then(function (response) {
      console.log("URL", URL);
      if (name) {
        saveAs(response.data, URL);
      } else {
        const typeUrl =
          Type === "png" || Type === "jpg"
            ? `image/${Type}`
            : "application/pdf";
        var file = new Blob([response.data], { type: typeUrl });
        var fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
      }
    });

    this.setState({ fullLoad: true });
    // axios.post(`${Services.Post_Preview_Doc_Export_ForId.path}/document/DocumentsPreview/`, data).then(success => {
    //   var base64str = success.data.base64;
    //   let pdfWindow = window.open("")
    //   pdfWindow.document.write(Type === 'png' || Type === 'jpg' ?
    //     (`<iframe width='100%'  height='100%'  src=${base64str} ></iframe> `)
    //     :rutaArchivos//  (`<iframe width='100%' type="application/${Type}"  height='100%' src='data:application/${Type};base64, ` + encodeURI(base64str) + "'></iframe> "))
    //     (`<iframe width='100%' type="application/${Type}"  height='100%' src='${rutaArchivos}${URL}'></iframe> `))
    // }).catch(error => {
    //   console.warn(error);
    // });
  };

  render() {
    return (
      <>
        <Loader hidden={this.state.fullLoad}></Loader>
        <Card className="myCard">
          <CardBody>
            <div className="row">
              <div className="col-12">
                <Table
                  headerClass="headerTable"
                  columns={this.state.columns}
                  data={this.state.data}
                  itemsByPage={10}
                  options={{
                    large: false,
                    downloads: {
                      pdf: false,
                      xlsx: false,
                    },
                    pagination: true,
                    search: true,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-11"></div>
              {/* <div className="col-1">
                <Fab color="primary" size="small" aria-label="add" onClick={this.toggle}>
                  <AddIcon />
                </Fab>
              </div> */}
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Create new document</ModalHeader>
          <ModalBody>
            <AddComment
              callback={this.callback}
              onError={this.onError}
              shipmentDataPlane={this.state.shipmentDataPlane}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Tracking;
