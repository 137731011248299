export const Services = {
   "REPORTS": {
      "path": "https://seacargoapireportes.azurewebsites.net/",
      "method": "",
      "descrp": "API TO HANDLE REPORTS"
   },
   "DOWNLOAD": {
      "path": "https://servicereportapi.azurewebsites.net/",
      "method": "GET",
      "descrp": "Download files"
   }
}