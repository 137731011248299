import React from "react";
/**
 * Roles
 * 1	"Administrador"
 * 2	"Sc logistics SU"
 * 3  "Sc logistics"
 * 4	"Cliente"
 */
let details = navigator.userAgent;
let regexp = /android|iphone|kindle|ipad/i;
let isMobileDevice = regexp.test(details);

export const MenuHandler = {
  1: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-final_mesa-de-trabajo-1",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-final-02",
      path: "/trazabilidad",
      translate: "sidebar.nav.trazabilidad",

      // submenu: [
      //   {
      //     name: 'Integrada',
      //     path: '/integrada',
      //   },
      //   {
      //     name: 'Internacional',
      //     path: '/shipments',
      //   },
      //   {
      //     name: 'Aduana',
      //     path: '/customs',
      //   },
      //   {
      //     name: 'Almacén',
      //     path: '/storage',
      //   },
      //   {
      //     name: 'Detalle Inventario',
      //     path: '/inventaryDetail',
      //   },
      // ],
    },
    // window.innerWidth < 777 ?
    isMobileDevice
      ? {
          name: "Reportes y Analíticos",
          icon: " icon-final-11",
          translate: "sidebar.nav.reportesanaliticos",
          path: "/Reporting",
        }
      : {
          name: "Reportes y Analíticos",
          icon: " icon-final-11",
          translate: "sidebar.nav.reportesanaliticos",
          submenu: [
            {
              name: "Mis Reportes",
              path: "/Reporting",
            },
            {
              name: "SCL Go Analytics",
              path: "/scl-go-analytics",
            },
          ],
        },
    {
      name: "Gestión Documental",
      icon: "icon-file-excel",
      path: "/load-sato",
      translate: "sidebar.nav.rssfeed",
    },
    {
      name: "RSS Feeds",
      icon: "icon-final-16",
      path: "/rssfeed",
      translate: "sidebar.nav.rssfeed",
    },
    {
      name: "Herramientas",
      icon: "icon-final-17",
      path: "/herramientas",
      translate: "sidebar.nav.herramientas",
    },
    {
      name: "Administración",
      icon: "icon-final-37",
      path: "/admin",
      translate: "sidebar.nav.administracion",
      submenu: [
        {
          name: "Usuarios",
          path: "/usuarios",
        },
        {
          name: "Organizaciones",
          path: "/entidades",
        },
      ],
    },
  ],
  2: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-final_mesa-de-trabajo-1",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-final-02",
      path: "/trazabilidad",
      translate: "sidebar.nav.trazabilidad",

      // submenu: [
      //   {
      //     name: 'Integrada',
      //     path: '/integrada',
      //   },
      //   {
      //     name: 'Internacional',
      //     path: '/shipments',
      //   },
      //   {
      //     name: 'Aduana',
      //     path: '/customs',
      //   },
      //   {
      //     name: 'Almacén',
      //     path: '/storage',
      //   },
      //   {
      //     name: 'Detalle Inventario',
      //     path: '/inventaryDetail',
      //   },
      // ],
    },
    {
      name: "Reportes y Analíticos",
      icon: " icon-final-11",
      // path: '/Reporting',
      translate: "sidebar.nav.reportesanaliticos",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/Reporting",
        },
        {
          name: "SCL Go Analytics",
          path: "/scl-go-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-final-16",
      path: "/rssfeed",
      translate: "sidebar.nav.rssfeed",
    },
    {
      name: "Herramientas",
      icon: "icon-final-17",
      path: "/herramientas",
      translate: "sidebar.nav.herramientas",
    },
    // {
    //   name: 'Administración',
    //   icon: 'icon-final-37',
    //   path: '/admin',
    //   translate: 'sidebar.nav.administracion',
    //   submenu: [
    //     {
    //       name: 'Usuarios',
    //       path: '/usuarios',
    //     }
    //     ,
    //     {
    //       name: 'Entidades',
    //       path: '/entidades',
    //     },
    //   ],
    // },
  ],
  3: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-final_mesa-de-trabajo-1",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-final-02",
      path: "/trazabilidad",
      translate: "sidebar.nav.trazabilidad",

      // submenu: [
      //   {
      //     name: 'Integrada',
      //     path: '/integrada',
      //   },
      //   {
      //     name: 'Internacional',
      //     path: '/shipments',
      //   },
      //   {
      //     name: 'Aduana',
      //     path: '/customs',
      //   },
      //   {
      //     name: 'Almacén',
      //     path: '/storage',
      //   },
      //   {
      //     name: 'Detalle Inventario',
      //     path: '/inventaryDetail',
      //   },
      // ],
    },
    {
      name: "Reportes y Analíticos",
      icon: " icon-final-11",
      // path: '/Reporting',
      translate: "sidebar.nav.reportesanaliticos",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/Reporting",
        },
        {
          name: "SCL Go Analytics",
          path: "/scl-go-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-final-16",
      path: "/rssfeed",
      translate: "sidebar.nav.rssfeed",
    },
    {
      name: "Herramientas",
      icon: "icon-final-17",
      path: "/herramientas",
      translate: "sidebar.nav.herramientas",
    },
    // {
    //   name: 'Administración',
    //   icon: 'icon-final-37',
    //   path: '/admin',
    //   translate: 'sidebar.nav.administracion',
    //   submenu: [
    //     {
    //       name: 'Usuarios',
    //       path: '/usuarios',
    //     }
    //     ,
    //     {
    //       name: 'Entidades',
    //       path: '/entidades',
    //     },
    //   ],
    // },
  ],
  4: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-final_mesa-de-trabajo-1",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-final-02",
      path: "/trazabilidad",
      translate: "sidebar.nav.trazabilidad",

      // submenu: [
      //   {
      //     name: 'Integrada',
      //     path: '/integrada',
      //   },
      //   {
      //     name: 'Internacional',
      //     path: '/shipments',
      //   },
      //   {
      //     name: 'Aduana',
      //     path: '/customs',
      //   },
      //   {
      //     name: 'Almacén',
      //     path: '/storage',
      //   },
      //   {
      //     name: 'Detalle Inventario',
      //     path: '/inventaryDetail',
      //   },
      // ],
    },
    {
      name: "Reportes y Analíticos",
      icon: " icon-final-11",
      // path: '/Reporting',
      translate: "sidebar.nav.reportesanaliticos",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/Reporting",
        },
        // {
        //   name: "SCL Go Analytics",
        //   path: "/scl-go-analytics"
        // }
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-final-16",
      path: "/rssfeed",
      translate: "sidebar.nav.rssfeed",
    },
    {
      name: "Herramientas",
      icon: "icon-final-17",
      path: "/herramientas",
      translate: "sidebar.nav.herramientas",
    },
    // {
    //   name: 'Administración',
    //   icon: 'icon-final-37',
    //   path: '/admin',
    //   translate: 'sidebar.nav.administracion',
    //   submenu: [
    //     {
    //       name: 'Usuarios',
    //       path: '/usuarios',
    //     }
    //     ,
    //     {
    //       name: 'Entidades',
    //       path: '/entidades',
    //     },
    //   ],
    // },
  ],
  5: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "Tablero de Control",
      icon: "icon-final_mesa-de-trabajo-1",
      path: "/dashboard",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Trazabilidad",
      icon: "icon-final-02",
      path: "/trazabilidad",
      translate: "sidebar.nav.trazabilidad",

      // submenu: [
      //   {
      //     name: 'Integrada',
      //     path: '/integrada',
      //   },
      //   {
      //     name: 'Internacional',
      //     path: '/shipments',
      //   },
      //   {
      //     name: 'Aduana',
      //     path: '/customs',
      //   },
      //   {
      //     name: 'Almacén',
      //     path: '/storage',
      //   },
      //   {
      //     name: 'Detalle Inventario',
      //     path: '/inventaryDetail',
      //   },
      // ],
    },
    {
      name: "Reportes y Analíticos",
      icon: " icon-final-11",
      // path: '/Reporting',
      translate: "sidebar.nav.reportesanaliticos",
      submenu: [
        {
          name: "Mis Reportes",
          path: "/Reporting",
        },
        {
          name: "SCL Go Analytics",
          path: "/scl-go-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-final-16",
      path: "/rssfeed",
      translate: "sidebar.nav.rssfeed",
    },
    {
      name: "Herramientas",
      icon: "icon-final-17",
      path: "/herramientas",
      translate: "sidebar.nav.herramientas",
    },
    // {
    //   name: 'Administración',
    //   icon: 'icon-final-37',
    //   path: '/admin',
    //   translate: 'sidebar.nav.administracion',
    //   submenu: [
    //     {
    //       name: 'Usuarios',
    //       path: '/usuarios',
    //     }
    //     ,
    //     {
    //       name: 'Entidades',
    //       path: '/entidades',
    //     },
    //   ],
    // },
  ],
};
