import React from 'react';
import { FormControlLabel, FormGroup, RadioGroup} from '@material-ui/core';
import { Form, Input, Dropdown } from 'semantic-ui-react';
import { DatePicker, TimePicker } from 'antd';

import CreateContext from './../../store/CreateContext';
import { RadioSelect, CheckSelect } from './../../components/RadioButtons';
import Header from './../../components/Header';
import CircleButton from './../../components/CircleButton';
import { Daily, Montly} from './../../model/';
import { Days, Frequency, Months, AllDays } from './config';
import './styles.scss';

/*=============================================
=            Schedule Report                  =
=============================================*/

function ScheduleReport(){
  const context = React.useContext(CreateContext);
  const [email, setEmail] = React.useState(undefined);
  const [emailAdd, setEmailAdd] = React.useState(false);

  const CheckBuilder = ({data, handler, type}) => {
    return (
      <FormGroup>
        {data.map((item,ind) => (
          <FormControlLabel
            control={
              <CheckSelect
                icon={'check_circle'}
                handler={() => handler(item, type)}
                selected={context.config[type][item.value]}
                id={ind}
              ></CheckSelect>
            }
            label={item.label}
          />
        ))}
      </FormGroup>
    );
  };
  
  function handleChangeDateTime(data){
    if(data.id === 'startDate'){
      context.update_config({key: 'startDate', value: { value: data.value, dateString: data.dateString }});
    }else if(data.id === 'endDate'){
      context.update_config({key: 'endDate', value: { value: data.value, dateString: data.dateString }});
    }else if (data.id === 'hour'){
      context.update_config({key: 'hour', value: { value: data.value, timeString: data.timeString }});
    }
  }
  function handleChangeDay(evt, data){
    context.update_config({key: 'day', value: data.value});
  }

  function handleChange(evt){
    if(evt.target.value){
      context.set_frequency(evt.target.value);
      if(evt.target.value === 'Daily'){
        context.update_config({key: 'Monthly', value: {...Montly()}});
      }else if(evt.target.value === 'Monthly'){
        context.update_config({key: 'Daily', value: {...Daily()}});
      }
    }
  }

  function onUpdateRecurrance(item, type){
    if(type === 'Daily'){
      context.config[type][item.value] = !context.config[type][item.value];
      context.update_config({key: 'Daily', value: {...context.config[type]}});
    }else if(type === 'Monthly'){
      context.config[type][item.value] = !context.config[type][item.value];
      context.update_config({key: 'Monthly', value: {...context.config[type]}});
    }
    
  }

  function onChangeEmail(evt, data){
    if(data.value){
      if(validateEmail(data.value)){
      // if(validateEmail(email)){
        setEmail(data.value);
        if(!emailAdd){
          setEmailAdd(true);
        }
      } else {
        setEmail(data.value);
        setEmailAdd(false);
      }
    } else {
      setEmail('');
      setEmailAdd(false);
    }
  }

  function validateEmail(email){
    const regexEmail = new RegExp(/^\S+@\S+\.\S+$/);
    // const regexEmail = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/);
    return regexEmail.test(email);
  }
  function onAddEmail(){
    context.add_email(email);
    setEmail('');
    setEmailAdd(false);
  }
  function onADD(evt){
    if (validateEmail(email) && evt.key === 'Enter'){
      onAddEmail();
    }
  }
  


  return (
    <div className='col-md-12' hidden={context.report_type === 'scheduled' ? false: true}>
      <Header
        as='h6'
        icon='clock'
        title='Programación'
      />
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-3'>
            <div className='row'>
              <div className='col-md-6 d-flex justify-content-end'>
                <p  className='colorFuente schedule-title'>Frecuencia</p>
              </div>
              <div className='col-md-6'>
                <RadioGroup aria-label="frequency" id='frequency' name="frequency" value={context.config.frequency} onChange={(event) => {
                  handleChange(event);
                }}>
                  {
                    Frequency.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item.value}
                          id={index}
                          control={
                            <RadioSelect/>
                          }
                          label={item.label}
                        />
                      );
                    })
                  }
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='row'>
              <div className='col-md-6 d-flex justify-content-end'>
                <p className='schedule-title colorFuente'>Recurrencia</p>
              </div>
              <div className='col-md-6'>
                {
                  context.config.frequency === 'Daily' ?
                    <CheckBuilder data={Days} handler={onUpdateRecurrance} type='Daily'/>:
                    <CheckBuilder data={Months} handler={onUpdateRecurrance} type='Monthly'/>
                }
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='row'>
              <div className='col-md-12'>
                <label className='colorFuente schedule-label'>Fecha de inicio</label>
                <DatePicker placeholder={'Fecha de inicio'} className={'datetime-picker-schedule'} 
                value={context.config.startDate.value} format={'DD-MM-YYYY'} bordered={true} onChange={(date, dateString) => {handleChangeDateTime({value: date, dateString, id: 'startDate'});}}/>
              </div>
              <div className='col-md-12'>
                <label className='colorFuente schedule-label'>Fecha de término</label>
                <DatePicker placeholder={'Fecha de término'} className={'datetime-picker-schedule'} 
                value={context.config.endDate.value} format={'DD-MM-YYYY'} bordered={true}
                 onChange={(date, dateString) => { handleChangeDateTime({value: date, dateString, id: 'endDate'});}}/>
              </div>
              <div className='col-md-12' hidden={context.config.frequency === 'Daily' ? true: false}>
                <label className='colorFuente schedule-label'>Día</label>
                <Form size='tiny'>
                  <Form.Group>
                    <Form.Field required width={16}>
                      <Dropdown fluid search selection options={AllDays()} className={'clearable-fix schedule-days'}  placeholder='Dia' id='day' name='day' onChange={handleChangeDay} value={`${context.config.day}`} />
                    </Form.Field>                  
                  </Form.Group>
                </Form>
              </div>                         
              <div className='col-md-12'>
                <label className='colorFuente schedule-label'>Hora</label>
                <TimePicker placeholder={'Hora'} className={'time-picker-schedule'} use12Hours format="h:mm a" value={context.config.hour.value} onChange={(time, timeString) => {handleChangeDateTime({value: time, timeString, id: 'hour'});}} />
              </div>
              <div className='col-md-12'>
                <label className='colorFuente schedule-label'>Zona Horaria</label>
                <p className='text-muted'>{context.config.timeZoneStr}</p>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='row'>
              <div className='col-md-12'>
                <label className='colorFuente schedule-label'>Enviar a:</label>
                <div className='row'>
                  <div className='col-md-9'>
                    <Form size='tiny'>
                      <Form.Group>
                        <Form.Field required width={16} size={'tiny'}>
                          <Input size='tiny'  placeholder='Correo electrónico' id='sendTo' autoComplete="off" name='sendTo' value={email} onChange={onChangeEmail} onKeyPress={onADD}/>
                        </Form.Field>                  
                      </Form.Group>
                    </Form>
                  </div>
                  <div className='col-md-3 '>
                    <CircleButton onClick={onAddEmail} icon={'add'} disabled={!emailAdd}/>
                  </div>
                </div>

              </div>
              <div className='col-md-12'>
                <label className='colorFuente schedule-label'>Lista de correos</label>
                <div className='d-flex flex-column email'>
                  {
                    context.emailList.map((email, index) => {
                      return (
                        <div className='d-flex align-items-center email__container' key={index}>
                          <div className='email__item'>
                            <label className='colorFuente'>{email}</label>
                          </div>
                          <div className='email__action'>
                            <CircleButton disabled={false} color={'danger'} icon={'delete'} onClick={() => { context.remove_email(email);}}/>
                          </div>
                        </div>
                      );
                    }) 
                  }
                </div>            
              </div>
            </div>
          </div>                              
        </div>
      </div>
    </div>
  );
}
export default ScheduleReport;