import React from "react";
import moment from "moment";
import {
  Label,
  Container,
  Col,
  Form,
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
} from "reactstrap";
import { Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import imagenLogin from "./../../../resources/wallpaperSEA.gif";
import logoSeaCargo from "./../../../resources/Logo-login.png";
import { Services } from "./Services";

import Authentication from "../../../../src/services/Authentication.js";
import SessionStorage from "../../../../src/services/SessionStorage";
import { SetNewListGroups } from "../../../state/actions/Header";

import "./styles.scss";

const auth = new Authentication();
const dt = new Date();
const BackgroundHead = {
  // height: "100%",
  backgroundImage: "url(" + imagenLogin + ")",
  backgroundSize: "cover",
  // backgroundPosition: "center",
  // width: "calc(100vw + 48px)",
  // margin: -24,
  // padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Login(props) {
  const [redirectlogin, setRedirectlogin] = React.useState({
    login: {
      email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
      valid: false,
      password: localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "",
      check: localStorage.getItem("email") ? true : false,
    },
  });
  let [password, setPassword] = React.useState(false);
  const [recoverPassword, setrecoverPassword] = React.useState("");
  const [response, setresponse] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  /*=============================================
  =            Handle Login Function            =
  =============================================*/
  function change(event) {
    document.getElementById("email_sin").setAttribute("hidden", "hidden");
    document.getElementById("pass_sin").setAttribute("hidden", "hidden");
    document.getElementById("error_user").setAttribute("hidden", "hidden");
    if (event.target.name === "email") {
      let value = event.target.value;
      const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
      if (emailRegex.test(value)) {
        redirectlogin.login.valid = true;
        document.getElementById("input_email").className = "input100";
        document.getElementById("email_alert").setAttribute("hidden", "hidden");
      } else {
        redirectlogin.login.valid = false;
        // document.getElementById('input_email').className = 'invalidInput';
        document.getElementById("email_alert").removeAttribute("hidden");
      }
      value = value.toLowerCase()
      const pointer = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    } else {
      let value = event.target.value;
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    }
  }

  function changeCorreo(event) {
    document.getElementById("correo_alert").removeAttribute("hidden");
    document
      .getElementById("correo_alert_base")
      .setAttribute("hidden", "hidden");
    document.getElementById("correo_sin").setAttribute("hidden", "hidden");
    let value = event.target.value;
    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    if (emailRegex.test(value)) {
      document.getElementById("input_correo").className = "input100";
      document.getElementById("correo_alert").setAttribute("hidden", "hidden");
      document.getElementById("correo_aler_button").removeAttribute("hidden");
    } else {
      document.getElementById("input_correo").className = "invalidInput";
      document.getElementById("correo_alert").removeAttribute("hidden");
      document
        .getElementById("correo_aler_button")
        .setAttribute("hidden", "hidden");
    }
    value = value.toLowerCase()
    setrecoverPassword(value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleLoginId();
  }
  async function handleLoginId() {
    try {
      setLoading(true);
      if (!redirectlogin.login.email) {
        document.getElementById("email_sin").removeAttribute("hidden");
        setLoading(false);
      } else if (!redirectlogin.login.password) {
        document.getElementById("pass_sin").removeAttribute("hidden");
        setLoading(false);
      } else {
        document.getElementById("email_sin").setAttribute("hidden", "hidden");
        document.getElementById("pass_sin").setAttribute("hidden", "hidden");
        let data = {
          email: redirectlogin.login.email,
          password: redirectlogin.login.password,
        };

        const response = await axios.post(
          `${Services.ADMIN.path}oauth/setAuthentication`,
          data
        );

        console.log("response??", response.data);

        let token = response.data.token;
        let VariableBranch = response.data.data[0].VariableBranch;
       
        let redirectUserData = response.data.data;
        if (redirectlogin.login.check) {
          localStorage.setItem("email", redirectlogin.login.email);
          localStorage.setItem("password", redirectlogin.login.password);
        }
        let id = response.data.data[0].UserId;

        const groups = await axios.get(
          `${Services.ADMIN.path}admin/customerForId/v2/${id}`
        );

        await props.SetNewListGroups({
          group_list: groups.data.resp,
          clients: groups.data.clients,
          nit: groups.data.NIT,
          lang: "es",
          variableBranch: VariableBranch,
        });
        // groups.data.clients.push(71744);
        // groups.data.clients.push(965730);
        // groups.data.clients.push(10016);
        // groups.data.clients.push(965003);
        // groups.data.clients.push(73874);
        // groups.data.clients.push(71624);
        // groups.data.clients.push(71624);
        const session = new SessionStorage();

        session.addItem("group_list", JSON.stringify(groups.data.resp));
        session.addItem("clients", JSON.stringify(groups.data.clients));
        session.addItem("nit", JSON.stringify(groups.data.NIT));
        session.addItem("lang", "ES");
        auth.setAuthentication(
          token,
          redirectUserData,
          groups.data.resp,
          redirectlogin.login.email
        );

        await props.history.push("/dashboard");
        setLoading(false);

        const createOffsetValue = (date) => {
          let sign = date.getTimezoneOffset() > 0 ? '-' : '+';
          let offset = Math.abs(date.getTimezoneOffset());
          let hours = Math.floor(offset / 60);
          let minutes = ((offset % 60) / 60) * 10;
          return sign + hours + '.' + minutes;
        };

        let userAudit = {
          UsuarioId: id,
          SessionId: token,
          StartConnection: new Date(),
          timezone: createOffsetValue(new Date()),
          Origin: 'web',
        };
        navigator.sendBeacon(`${Services.AUDIT.path}user/login`, JSON.stringify(userAudit))
      }
    } catch (error) {
      setLoading(false);
      document.getElementById("error_user").removeAttribute("hidden");

      console.error("handleLoginId", error);
    }
  }

  function handleAccount() {
    if (!recoverPassword) {
      document.getElementById("correo_sin").removeAttribute("hidden");
    } else {
      document.getElementById("correo_sin").setAttribute("hidden", "hidden");
      let data = {
        correo: recoverPassword,
      };

      axios
        .post(`${Services.ADMIN.path}admin/recover`, data)
        .then(() => {
          document.getElementById("correo_exitoso").removeAttribute("hidden");
          setrecoverPassword("");
        })
        .catch((error) => {
          if (error.response) {
            setresponse(error.response.data.message);
            document
              .getElementById("correo_alert_base")
              .removeAttribute("hidden");
            console.error("error", error.response.data.message);
          }
        });
    }
  }

  function funtionRegresa() {
    setPassword(!password);
    setrecoverPassword("");

    document.getElementById("input_correo").className = "input100";
    document.getElementById("correo_sin").setAttribute("hidden", "hidden");
    document.getElementById("correo_alert").setAttribute("hidden", "hidden");
    document
      .getElementById("correo_alert_base")
      .setAttribute("hidden", "hidden");
    document
      .getElementById("correo_aler_button")
      .setAttribute("hidden", "hidden");
    document.getElementById("correo_exitoso").setAttribute("hidden", "hidden");
  }

  if (redirectlogin.login.check) {
    localStorage.setItem("email", redirectlogin.login.email);
    localStorage.setItem("password", redirectlogin.login.password);
  } else {
    localStorage.removeItem("email", "email");
    localStorage.removeItem("password", "password");
  }

  return (
    <div style={BackgroundHead}>
      <div className="login-page">
        <Container>
          {!password ? (
            <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
              <Form onSubmit={handleSubmit}>
                {/* <div className="wrap-login100 p-t-0 p-b-0"> */}
                <Card className="loginContainer">
                  {/* <div className="col-12">
                    <center>
                      <img
                        src={nameRol4ll}
                        alt="App Logo"
                        width={'240px'}
                        height={'120px'}
                      />
                    </center>
                  </div> */}
                  <CardHeader>
                    <div style={{ width: "71%" }} className="logo-container">
                      <img
                        style={{ marginTop: "1em" }}
                        src={logoSeaCargo}
                        alt="now-logo"
                      />
                    </div>
                  </CardHeader>
                  <div className="logo-container2">
                    <span className={"tex_1"}>Tus embarques</span>{" "}
                    <span className={"tex_2"}>siempre cerca.</span>
                  </div>
                  <CardBody>
                    {/* <div
                      style={{ marginLeft: '-1px' }}
                      className="wrap-input100 row validate-input m-b-12 "
                      data-validate="Usuario es Requerido"
                    > */}
                    <Col xs={11} className="ml-auto mr-auto">
                      <Input
                        style={{ borderRadius: "0px" }}
                        icon="icon-final-19"
                        placeholder="Usuario"
                        iconPosition="left"
                        fluid
                        id="input_email"
                        type="text"
                        value={redirectlogin.login.email}
                        name="email"
                        onChange={change}
                      />
                    </Col>
                    <br />
                    <Col xs={11} className="mr-auto ml-auto">
                      <Input
                        icon="demo-icon icon-lock"
                        placeholder="Contraseña"
                        iconPosition="left"
                        fluid
                        type="password"
                        value={redirectlogin.login.password}
                        name="password"
                        onChange={change}
                      />
                    </Col>
                    {/* </div> */}
                    <div
                      className="_alert"
                      id="email_alert"
                      hidden
                      style={{
                        textAlign: "center",
                        color: "#BA2B30",
                        fontSize: "12px",
                      }}
                    >
                      Introduce un email válida
                    </div>
                    {/* <div
                      style={{ marginLeft: '-1px' }}
                      className="row validate-input m-b-12"
                      data-validate="Contraseña es Requerida"
                    >
                      <span className="focus-input100"></span>
                    </div> */}
                    <div
                      style={{
                        textAlign: "center",
                        color: "#BA2B30",
                        fontSize: "12px",
                      }}
                      className="_alert"
                      id="email_sin"
                      hidden
                    >
                      Email electrónico
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#BA2B30",
                        fontSize: "12px",
                      }}
                      className="_alert"
                      id="pass_sin"
                      hidden
                    >
                      Contraseña es Requerida
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#BA2B30",
                        fontSize: "12px",
                      }}
                      className="_alert"
                      id="error_user"
                      hidden
                    >
                      Usuario y/o Contrasela Invalida
                    </div>
                    {/* </div> */}
                    <br />
                    {/* <div className=" col-8"> */}
                    {/* <div
                        style={{ fontSize: "15px", color: "#000" }}
                        className="col-12"
                      > */}
                    <Label>
                      <Input
                        type="checkbox"
                        style={{ marginRight: "8px" }}
                        onChange={() => {
                          redirectlogin.login.check =
                            !redirectlogin.login.check;
                          setRedirectlogin({
                            redirectlogin,
                            ...redirectlogin,
                          });
                        }}
                        checked={redirectlogin.login.check}
                      />
                      Recordar Contraseña
                    </Label>
                    {/* </div> */}
                    {/* </div> */}

                    <br />
                    <Label
                      className="forgot_pass"
                      onClick={() => {
                        setPassword(!password);
                      }}
                    >
                      <b style={{ fontSize: "12px", color: "#002297" }}>
                        ¿Olvidaste tu Contraseña?
                      </b>
                    </Label>
                    <br />
                    <Col xs={5} className="ml-auto mr-auto">
                      <Button
                        block
                        type="submit"
                        style={{ fontWeight: "bold", background: "#FF0000" }}
                        // color='danger'

                        disabled={loading}
                      >
                        {loading ? "Ingresando.." : "Entrar"}
                      </Button>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <div className="_copy_rigth">
                        Copyright © {dt.getFullYear()} | Sea Cargo Logistics{" "}
                        <br />
                        Todos los derechos reservados.
                      </div>
                      <div className="_terminos">
                        <a
                          target="_blank"
                          href="politicas_privacidad"
                        >
                          Política de Privacidad
                        </a>{" "}
                        |{" "}
                        <a
                          target="_blank"
                          href="terminos-y-condiciones"
                        >
                          Términos y Condiciones
                        </a>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
                {/* </div> */}
              </Form>
            </Col>
          ) : (
            <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
              <Form>
                <Card className="loginContainer">
                  <CardHeader>
                    <div className="logo-container">
                      <img src={logoSeaCargo} alt="now-logo" />
                    </div>
                    <div className="logo-container">
                      <span className={"tex_1"}>Tus embarques</span>{" "}
                      <span className={"tex_2"}>siempre cerca.</span>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="col-12">
                      <label style={{ color: "#003F70", fontSize: "24px" }}>
                        Recuperar Contraseña
                      </label>
                    </div>
                    <div className="col-12">
                      <label style={{ color: "#003F70", fontSize: "12px" }}>
                        Ingresa tu email con el que te registraste por primera
                        vez y se te enviará un correo de confirmación a tu
                        cuenta.
                      </label>
                    </div>
                    <br />
                    {/* <div style={{ height: 10 }} /> */}
                    <div className="col-12" style={{ textAlign: "center" }}>
                      {/* <div
                        className="wrap-input100 validate-input m-b-12 "
                        data-validate="Correo es requerio"
                      > */}
                      <Input
                        icon="fas fa-lock"
                        placeholder="Email recuperar contraseña"
                        iconPosition="left"
                        fluid
                        // className="input100"
                        id="input_correo"
                        type="text"
                        value={recoverPassword}
                        name="correo"
                        onChange={changeCorreo}
                      />
                      {/* <span className="focus-input100"></span>
                      </div> */}
                      <div
                        style={{
                          // padding: '3px',
                          // marginTop: '-14px',
                          color: "#BA2B30",
                          fontSize: "12px",
                        }}
                        className="_alert"
                        id="correo_alert"
                        hidden
                      >
                        Introduce un email válido
                      </div>
                      <div
                        style={{
                          color: "#BA2B30",
                          fontSize: "12px",
                        }}
                        className="_alert"
                        id="correo_alert_base"
                        hidden
                      >
                        {response}
                      </div>
                      <div
                        style={{
                          color: "#BA2B30",
                          fontSize: "12px",
                        }}
                        className="_alert"
                        id="correo_sin"
                        hidden
                      >
                        Email requerido
                      </div>
                      <div
                        style={{
                          color: "#BA2B30",
                          fontSize: "12px",
                        }}
                        className="_alert"
                        id="error_correo"
                        hidden
                      >
                        Parece que tu email no esta registado
                      </div>
                      <div
                        style={{
                          color: "#052389",
                          fontSize: "14px",
                        }}
                        className="_alert"
                        id="correo_exitoso"
                        hidden
                      >
                        Email enviado exitosamente
                      </div>
                    </div>
                    <br />
                    <Row>
                      <Col xs={5} className="ml-auto mr-auto">
                        <Button
                          style={{ fontWeight: "bold", background: "#FF0000" }}
                          block
                          onClick={funtionRegresa}
                        >
                          Regresar{" "}
                        </Button>
                      </Col>
                      <Col xs={5} className="ml-auto mr-auto">
                        <Button
                          className="login100-form-btn-blue"
                          style={{ fontWeight: "bold" }}
                          block
                          id="correo_aler_button"
                          onClick={handleAccount}
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          )}
        </Container>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  SetNewListGroups: (new_group_list) =>
    dispatch(SetNewListGroups(new_group_list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
