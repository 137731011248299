import React, { Component, Suspense } from "react";
import axios from "axios";
import _ from "lodash";
import { Row, Card, CardBody, CardText, Col } from "reactstrap";
import { Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import ContentWrapper from "./../../../template/Layout/ContentWrapper";
// import Map from './../../Components/Maps/map';
import Loader from "./../../../Global/Loader";
import { withRouter } from "react-router-dom";
import Cards from "../../Components/Cards";
import "./../Components/styles/cards.scss";
import { Services } from "../Services";
import { DatePicker } from "antd";
import "./styles.scss";
// import ClientesList from '../../../../services/storageClient.js';
const Map = React.lazy(() => import("./../../Components/Maps/map"));

const statusInter = [
  { Estatus: "Instruccionado", EstatusId: 61, count: 0, key: 1 },
  { Estatus: "Reservado", EstatusId: 62, count: 0, key: 2 },
  { Estatus: "Carga Lista", EstatusId: 63, count: 0, key: 3 },
  { Estatus: "Recolectado", EstatusId: 64, count: 0, key: 4 },
  { Estatus: "Salida", EstatusId: 65, count: 0, key: 5 },
  { Estatus: "Arribó", EstatusId: 66, count: 0, key: 6 },
  { Estatus: "En transito a Entregar", EstatusId: 72, count: 0, key: 7 },
  { Estatus: "Entregado", EstatusId: 73, count: 0, key: 8 },
  // { Estatus: "Facturado", EstatusId: 71, count: 0, key: 9 },
];

class Shipments extends Component {
  state = {
    map: (
      <Suspense fallback={<div>Loading...</div>}>
        <Map
          polylineData={[]}
          heidIcon={60}
          disableDefaultUI={true}
          // style={{ maxHeight: "300px" }}
          marker={[]}
          visible={true}
          type={"polylineDrawer"}
          data={[]}
          dataDrawer={""}
        // zoom={3}
        />
      </Suspense>
    ),
    // clientesSelect: clientSave.getAuthentication('client'),
    clientesSelect: this.props.clients
      ? JSON.stringify(this.props.clients)
      : "[0]",
    activePage: 1,
    limit: 10,
    paramsxml: {},
    numberPage: 1,
    totalPages: 1,
    totalPagesExt: 1,
    search: "",
    isSearch: false,
    totalCard: [],
    dataDrawer: "",
    visible: false,
    selected: "",
    polyline: [],
    data: [],
    // dataMap: mapJson.dataMap,
    dataAux: [],
    dataOut: [],
    labelStatus: "closed",
    loading: true,
    graphs: [],
    markers: [],
    dataMap: [],
    donutChart: [],
    donutChartTrafico: [
      { id: "Expo", label: "Expo", value: 30, color: "#0bc637" },
      { id: "Triangulado", label: "Trinagulado", value: 40, color: "#149e16" },
      { id: "Impo", label: "Inpo", value: 30, color: "#39ef64" },
    ],
    barTable: [
      { status: "Arribo", Cancelled: 2, CancelledColor: "#efbc04" },
      { status: "Zarpo", Cancelled: 5, CancelledColor: "#efbc04" },
      { status: "Carga Lista", Cancelled: 5, CancelledColor: "#efbc04" },
    ],
    config: [
      {
        title: "Estatus",
        idExcel: "Status",
        xls: true,
        header: { link: false, hide: true, icon: false },
        data: {
          link: false,
          progress: false,
          icon: false,
          status: false,
          hide: true,
        },
        id: "null",
        className: "hidden",
      },
      {
        title: "Cliente",
        xls: true,
        hide: true,
        id: "CustomerName",
        className: "col-md-1 col-lg-1 col-sm-6",
      },
      {
        title: "",
        xls: false,
        first: true,
        foot: { show: false, style: { fontSize: "12px", fontWeight: "bold" } },
        header: { link: false, hide: true, icon: false },
        data: { link: false, progress: false, icon: false, status: false },
        id: "ShipmentId",
        link: "/shipmentsDetal/",
        className: "col-md-3 col-lg-2 col-sm-6",
      },
      {
        title: "No. de Embarque",
        xls: true,
        hide: true,
        id: "ShipmentNumber",
        className: "col-md-1 col-lg-1 col-sm-6",
      },
      // {
      //   title: "Cliente",
      //   xls: true,
      //   foot: {
      //     show: false,
      //     style: { fontSize: "12px", fontWeight: "bold", color: "green" },
      //   },
      //   data: { link: false, progress: false, icon: false, status: false },
      //   header: { link: false },
      //   id: "CustomerName",
      //   link: "/shipmentsDetal/",
      //   className: "col-md-3 col-lg-1 col-sm-6",
      // },
      {
        title: "Embarcador",
        xls: true,
        foot: {
          show: false,
          style: { fontSize: "12px", fontWeight: "bold", color: "green" },
        },
        data: { link: false, progress: false, icon: false, status: false },
        header: { link: false },
        id: "ShipperName",
        link: "/shipmentsDetal/",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Consignatario",
        xls: true,
        icon: "fa-2x mr-2 fas fa-arrow-alt-circle-left",
        colorLogo: "#643296c2",
        id: "ConsigneeName",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Ref. Cliente",
        xls: true,
        type: 7,
        id: "CustomertRef",
        className: "col-md-2 col-lg-1 col-sm-1",
      },
      {
        title: "Viaje/ No Vuelo",
        xls: true,
        type: 7,
        id: "VesselVoyageFlight",
        className: "col-md-2 col-lg-1 col-sm-1",
      },
      {
        title: "No Contenedor",
        xls: true,
        type: 7,
        id: "Containers",
        className: "col-md-2 col-lg-1 col-sm-1",
      },

      // {
      //   title: "Ref. Consignatario",
      //   xls: true,
      //   type: 7,
      //   id: "ConsigneeRef",
      //   className: "col-md-2 col-lg-1 col-sm-1",
      // },
      // {
      //   title: "No Contenedor",
      //   xls: true,
      //   link: "/shipmentsDetal/",
      //   id: "RefCliente",
      //   className: "col-md-3 col-lg-1 col-sm-6",
      // },
      {
        title: "Origen",
        xls: true,
        link: "/shipmentsDetal/",
        id: "POL",
        id2: "POLName",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Destino",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        link: "/shipmentsDetal/",
        id: "POD",
        id2: "PODName",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "ETD",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        id: "ETD",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "ETA",
        xls: true,
        data: { link: false, progress: false, icon: false, status: false },
        link: "/shipmentsDetal/",
        id: "ETA",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      {
        title: "Progreso",
        xls: true,
        data: { link: false, progress: true, icon: false, status: false },
        id: "Progress",
        className: "col-md-3 col-lg-1 col-sm-6",
      },
      // {title: '',id:'null',link:'/shipmentsDetal/',className: ''},
    ],
    advancedSearch: [
      {
        title: "Información del Embarque",
        data: [
          [
            {
              title: "Organizaciones",
              // type: "select",
              type: "multipleSelect",
              UseIN: true,
              id: "CustomerName",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Embarcador",
              // type: "select",
              type: "multipleSelect",
              UseIN: true,
              id: "ShipperName",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Consignatario",
              // type: "select",
              type: "multipleSelect",
              UseIN: true,
              id: "ConsigneeName",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
          ],
          [
            // {
            //   title: "Número de Embarque",
            //   type: "text",
            //   id: "ShipmentNumber",
            //   class: "col-3",
            //   values: [],
            //   value: "",
            // },
            {
              title: "Estatus de Embarque",
              type: "multipleSelect",
              UseIN: true,
              id: "Status",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Origen",
              // type: "select",
              type: "multipleSelect",
              UseIN: true,
              id: "POL",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
            {
              title: "Destino",
              // type: "select",
              type: "multipleSelect",
              UseIN: true,
              id: "POD",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
            // {
            //   title: "Destino Final",
            //   type: "text",
            //   id: "POD",
            //   class: "col-3",
            //   values: [],
            //   value: "",
            // },

            {
              title: "Modo de Transporte",
              type: "select",
              id: "TransportMode",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },

            {
              title: "Incoterm",
              type: "select",
              id: "Incoterm",
              class: "col-xs-6 col-md-4 col-lg-4",
              values: [],
              value: "",
            },
          ],
          // [

          //   {
          //     title: "Incoterm",
          //     type: "select",
          //     id: "Incoterm",
          //     class: "col-3",
          //     values: [],
          //     value: "",
          //   },
          //   // {
          //   //   title: "Carrier",
          //   //   type: "select",
          //   //   id: "Carrier",
          //   //   class: "col-3",
          //   //   values: [],
          //   //   value: "",
          //   // },
          // ],
        ],
      },
      {
        title: "Fechas",
        data: [
          [
            // {
            //   title: "Fecha Embarque",
            //   type: "dateRange",
            //   id: "FechaRecoleccion",
            //   class: "col-3",
            //   values: [],
            //   value: "",
            //   date: { init: "", end: "" },
            // },
            {
              title: "ETD",
              type: "dateRange",
              id: "ETD",
              class: "col-xs-6 col-md-3 col-lg-3",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
            {
              title: "ETA",
              type: "dateRange",
              id: "ETA",
              class: "col-xs-6 col-md-3 col-lg-3",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
            {
              title: "ATD",
              type: "dateRange",
              id: "ATD",
              class: "col-xs-6 col-md-3 col-lg-3",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },

            {
              title: "ATA",
              type: "dateRange",
              id: "ATA",
              class: "col-xs-6 col-md-3 col-lg-3",
              values: [],
              value: "",
              date: { init: "", end: "" },
            },
          ],
        ],
      },
      // {
      //   title: "Información del Contenedor",
      //   data: [
      //     [
      //       {
      //         keyUnikey: true,
      //         title: "Número de Contenedor",
      //         type: "text",
      //         id: "Cliente",
      //         class: "col-4",
      //         values: [],
      //         value: "",
      //       },
      //     ],
      //   ],
      // },
    ],
    advancedObj: [],
    url: "",
    showMap: false,
    fullLoader: false
  };

  componentDidMount = async () => {
    // this.getListShipments();
    // this.getCatalogs();
    // this.getMapShipments();

    Promise.all([
      await this.getListShipments(true),
      await this.getCatalogs()
    ]).finally(() => {
      setTimeout(() => {
        this.setState({ fullLoader: true })
      }, 1000);
    });

    window.addEventListener("resize", () => {
      let newGraphs = this.state.graphs;
      newGraphs.map((e) => {
        e.width = this.calculateWidth();
        return e;
      });
      this.setState({ graphs: newGraphs });
    });
  };

  getCatalogs = async () => {
    let data = {
      client: this.state.clientesSelect,
      VariableBranch: this.props.variableBranch
        ? JSON.stringify(this.props.variableBranch)
        : null,
    };
    // axios.get(`${Services.Get_Traceability_Imports.Path}customs/allCustomImports`, {params:{EsDTA:0, search: this.state.search, allData: true}} ).then(success => {
    // axios.get(`${Services.Get_Traceability_Shipments.Path}shipments/catalogShipment`).then(success => {
    await axios
      .post(`${Services.TRACEABILITY.path}trazabilidad/catalogShipment`, data)
      .then((success) => {
        let POL = [];
        success.data.data.POL.map((e) => {
          if (e.POL) {
            POL.push({
              key: e.POL + "_pol",
              text: e.POL,
              value: e.POL + "_pol",
              // value: e.POL, label: e.POL ? e.POL : "Sin Valor"
            });
          }
        });
        let POD = [];
        success.data.data.POD.map((e) => {
          if (e.POD) {
            POD.push({
              key: e.POD,
              text: e.POD,
              value: e.POD + "_pod",
              // value: e.POD, label: e.POD ? e.POD : "Sin Valor"
            });
          }
        });
        let TransportMode = success.data.data.TransportMode.map((e) => {
          return {
            value: e.TransportMode,
            label: e.TransportMode ? e.TransportMode : "Sin Valor",
          };
        });
        let Carrier = [];
        success.data.data.Carrier.map((e) => {
          if (e.Carrier) {
            Carrier.push({
              key: e.Carrier,
              text: e.Carrier,
              value: e.Carrier + "_caName",
              // value: e.Carrier,
              // label: e.Carrier ? e.Carrier : "Sin Valor",
            });
          }
        });
        //let Status = success.data.data.Estatus.map(e => { return { value: e.Status, label: e.Status ? e.Status : 'Sin Valor' }; });
        let Status = [];

        success.data.data.Estatus.map((e, index) => {
          if (e.Status) {
            Status.push({
              key: e.Status,
              text: e.Status,
              value: e.Status + "_status",
            });
          }
        });
        let ShipperName = [];
        success.data.data.Proveedor.map((e) => {
          if (e.ShipperName) {
            ShipperName.push({
              key: e.ShipperName,
              text: e.ShipperName,
              value: e.ShipperName + "_sName",
              // value: e.ShipperName,
              // label: e.ShipperName ? e.ShipperName : "Sin Valor",
            });
          }
        });
        let ConsigneeName = [];
        success.data.data.Consignatario.map((e) => {
          if (e.ConsigneeName) {
            ConsigneeName.push({
              key: e.ConsigneeName,
              text: e.ConsigneeName,
              value: e.ConsigneeName + "_coName",
              // value: e.ConsigneeName,
              // label: e.ConsigneeName ? e.ConsigneeName : "Sin Valor",
            });
          }
        });
        let DeliveryReference = success.data.data.RecoleccionEntrega.map(
          (e) => {
            return {
              value: e.DeliveryReference,
              label: e.DeliveryReference ? e.DeliveryReference : "Sin Valor",
            };
          }
        );
        let Incoterm = success.data.data.Incoterm.map((e) => {
          return {
            value: e.Incoterm,
            label: e.Incoterm ? e.Incoterm : "Sin Valor",
          };
        });
        let CustomerName = [];
        success.data.data.Cliente.map((e) => {
          if (e.CustomerName) {
            CustomerName.push({
              key: e.CustomerName,
              text: e.CustomerName,
              value: e.CustomerName + "_cName",
              // value: e.CustomerName,
              // label: e.CustomerName ? e.CustomerName : "Sin Valor",
            });
          }
        });
        let newAdvanced = this.state.advancedSearch;
        newAdvanced.map((e) => {
          e.data.map((f) => {
            f.map((g) => {
              if (g.id === "POL") {
                g.values = POL;
              }
              if (g.id === "POD") {
                g.values = POD;
              }
              if (g.id === "TransportMode") {
                g.values = TransportMode;
              }
              if (g.id === "Carrier") {
                g.values = Carrier;
              }
              if (g.id === "Status") {
                g.values = Status;
              }
              if (g.id === "ShipperName") {
                g.values = ShipperName;
              }
              if (g.id === "ConsigneeName") {
                g.values = ConsigneeName;
              }
              if (g.id === "DeliveryReference") {
                g.values = DeliveryReference;
              }
              if (g.id === "Incoterm") {
                g.values = Incoterm;
              }
              if (g.id === "CustomerName") {
                g.values = CustomerName;
              }
              return g;
            });
            return f;
          });
          return e;
        });
        this.setState({
          advancedSearch: newAdvanced,
          // fullLoader: true,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  getListShipments = async (init) => {
    //limpiar valores
    if (this.state.advancedObj && this.state.advancedObj.length > 0) {
      this.state.advancedObj.map((obj) => {
        if (obj.UseIN) {
          let data = [];
          if (obj.value && obj.value.length > 0) {
            obj.value.map((d) => {
              data.push(d.split("_")[0]);
            });
          }
          obj.value = _.cloneDeep(data);
        }
      });
    }

    let data = {
      client: this.state.clientesSelect,
      pag: this.state.numberPage,
      limit: this.state.limit,
      EsDTA: 0,
      search: this.state.search,
      advanced: this.state.advancedObj,
      VariableBranch: this.props.variableBranch
        ? JSON.stringify(this.props.variableBranch)
        : null,
    };
    this.setState({
      url: encodeURI(
        `${Services.TRACEABILITY.path}trazabilidad/listShipments?search=${this.state.search}&allData=true`
      ),
      paramsxml: data,
    });
    // // axios.post(`${Services.Get_Traceability_Shipments.path}shipments/listShipments`, data, {params:{pag:this.state.numberPage,limit:this.state.limit, EsDTA:0, search: this.state.search}} ).then(success => {
    await axios.post(`${Services.TRACEABILITY.path}trazabilidad/listShipments`, data)
      .then((success) => {
        let data = success.data.data.map((e) => {
          let TraspormodeId = 1;
          e.TraspormodeId = TraspormodeId;
          e.id = e.ShipmentNumber;
          return e;
        });
        this.setState({
          totalPages: success.data.totalPage,
          totalPagesExt: success.data.totalPage,
          data: data,
          dataAux: data,
          dataOut: data,
          // fullLoader: true
        });
        if (!init) {
          setTimeout(() => {
            this.setState({
              fullLoader: true
            })
          }, 1000)
        }

        // if (loader) {
        //   this.setState({ fullLoader: true });
        // }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  setAdvanced = (val) => {
    this.setState({ advancedObj: val, fullLoader: false }, () => {
      this.getListShipments();
    });
  };

  changePage = (page) => {
    this.setState(
      {
        numberPage: page,
        activePage: page,
        fullLoader: false
      },
      () => {
        this.getListShipments();
      }
    );
  };

  handleChange = () => (event) => {
    let val = event.target.value;
    if (!this.state.isSearch) {
      this.setState({ isSearch: true }, () => {
        this.setState(
          {
            search: val,
            activePage: 1,
            limit: 10,
            numberPage: 1,
            totalPages: 1,
          },
          () => {
            this.getListShipments();
          }
        );
      });
    }
  };

  newSearch = (val) => {
    if (val.length < 1) {
      this.setState(
        {
          search: val,
          isSearch: false,
          activePage: 1,
          limit: 10,
          numberPage: 1,
          totalPages: 1,
        },
        () => {
          this.getListShipments();
        }
      );
    } else {
      if (val.length < this.state.search.length) {
        this.setState(
          {
            search: val,
            activePage: 1,
            limit: 10,
            numberPage: 1,
            totalPages: 1,
          },
          () => {
            this.getListShipments();
          }
        );
      } else {
        this.setState({ search: val }, () => {
          this.getListShipments();
        });
      }
    }
  };

  calculateWidth = () => {
    if (window.innerWidth >= 1756) {
      return (window.innerWidth / 4.8).toString();
    } else {
      return (window.innerWidth / 5.5).toString();
    }
  };

  handleSelectedFile = (id) => {
    let { selected } = this.state;
    try {
      if (selected) {
        let selectedItem = document.getElementById(selected);
        if (selectedItem) {
          selectedItem.classList.remove("_cardSelected");
        }
      }
      let out = this.searchSelected(id);
      this.setState({
        selected: id,
        dataOut: [out],
        labelStatus: id,
        activePage: 1,
        totalPages: 1,
      });
    } catch (e) {
      console.log(e);
    }
  };

  searchSelected = (ID) => {
    let { dataAux } = this.state;
    let FOUND = dataAux.find((o) => o.id === ID);
    return FOUND;
  };
  toggleDrawerMap = (data) => (evt) => {
    if (evt) evt.preventDefault();
    this.handleSelectedFile(data.id);
    // let dataPost = {
    //   client: this.state.clientesSelect,
    //   ShipmentId: data.id
    // };

    let markers = [];
    let polylineData = [];
    if (
      data.POLLatitude &&
      data.POLLongitude &&
      data.PODLatitude &&
      data.PODLongitude
    ) {
      if (data.POLLatitude && data.POLLongitude) {
        let obj = {
          anchorX: 30,
          anchorY: 45,
          key: `From`,
          title: "Origen",
          name: data.ShipmentId,
          data: data,
          position: {
            lat: data.POLLatitude,
            lng: data.POLLongitude,
          },
          icon: {
            url:
              data.TransportMode === "Air"
                ? "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Aereo-Mapa[1].png"
                : data.TransportMode === "Ocean"
                  ? "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Maritimo-Mapa[1].png"
                  : "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Terrestre-Mapa[1].png",
          },
        };

        let position = {
          lat: Number(data.POLLatitude),
          lng: Number(data.POLLongitude),
        };
        polylineData.push(position);
        markers.push(obj);
      }
      if (data.PODLatitude && data.PODLongitude) {
        let obj = {
          key: `To`,
          title: "Destino",
          name: data.ShipmentId,
          anchorX: 30,
          anchorY: 45,
          data,
          position: {
            lat: data.PODLatitude,
            lng: data.PODLongitude,
          },
          icon: {
            url:
              data.TransportMode === "Air"
                ? "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Aereo-Mapa[1].png"
                : data.TransportMode === "Ocean"
                  ? "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Maritimo-Mapa[1].png"
                  : "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Terrestre-Mapa[1].png",
          },
        };
        let position = {
          lat: Number(data.PODLatitude),
          lng: Number(data.PODLongitude),
        };
        polylineData.push(position);
        markers.push(obj);
      }
    }

    this.setState(
      {
        markers: markers,
        dataMap: polylineData,
        polyline: polylineData,
        // totalCard: totalCard,
        showMap: true,
      },
      () => {
        this.setState({
          visible: true,
          dataDrawer: data,
          map: (
            <Suspense fallback={<div>Loading...</div>}>
              <Map
                polylineData={this.state.polylineData}
                heidIcon={60}
                style={{ maxHeight: "300px" }}
                marker={this.state.markers}
                visible={true}
                type={"polylineDrawer"}
                data={this.state.polylineData}
                dataDrawer={data}
                zoom={3}
                closeDrawerEvent={this.resetTableCards}
                showDrawerInfo={true}
              />
            </Suspense>
          ),
        });
      }
    );
  };

  redirectTo = (id) => (evt) => {
    // evt.preventDefault();
    // this.props.history.push('/tracking/'+id)
  };

  resetTableCards = (evt) => {
    if (evt) evt.preventDefault();
    let { data, totalPagesExt } = this.state;
    this.setState(
      {
        dataOut: data,
        labelStatus: "closed",
        activePage: 1,
        totalPages: totalPagesExt,
        visible: false,
        showMap: false,
        markers: [],
        dataMap: [],
        polyline: [],
      },
      () => {
        this.setState({
          map: (
            <Suspense fallback={<div>Loading...</div>}>
              <Map
                polylineData={this.state.polylineData}
                heidIcon={60}
                style={{ maxHeight: "300px" }}
                showDrawerInfo={false}
                marker={this.state.markers}
                visible={true}
                type={"polylineDrawer"}
                polyline={this.state.polyline}
                data={this.state.polylineData}
                dataDrawer={""}
                zoom={3}
              />
            </Suspense>
          ),
        });
      }
    );
  };

  backres = (backResponse) => {
    this.setState({
      data: backResponse,
      dataAux: backResponse,
      dataOut: backResponse,
    });
  };
  resetAdvance = () => {
    let { advancedSearch } = this.state;
    advancedSearch.map((advData) => {
      advData.data.map((data) => {
        data.map((d) => {
          d.value = "";
        });
      });
    });
    this.setState({ advancedSearch });
  };
  render() {
    var timestamp = Date.now();
    var date = new Date(timestamp);
    let {
      url,
      labelStatus,
      data,
      dataOut,
      totalPages,
      activePage,
      fullLoader,
    } = this.state;
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader}></Loader>
        <div className="content-heading">
          <legend style={{ marginLeft: "10px" }} className="styleTitleModule">
            <em
              // onClick={this.moreContact()}
              className="icon-final-02"
              style={{ color: "#020E3A" }}
            ></em>
            Trazabilidad de Embarques
          </legend>
        </div>
        {/* <Col lg={12} md={12}>
          <Row>
            <Col lg={10} md={12}></Col>
          </Row>
        </Col> */}
        <Col lg={12} md={12}>
          {/* <Row> */}
          {/* <Col xs={12} sm={12} md={12}> */}
          <div style={{ display: this.state.showMap ? "inherit" : "none" }}>
            {this.state.map}
          </div>
          {/* </Col> */}
          {/* <CardBody> */}
          {/* <div className=""> */}
          <br></br>
          <Cards
            paramsxml={this.state.paramsxml}
            clientesSelect={this.state.clientesSelect}
            advancedSearch={(val) => {
              this.setAdvanced(val);
            }}
            advancedSearchData={this.state.advancedSearch}
            urlReport={url}
            newSearch={(value) => {
              this.newSearch(value);
            }}
            resetAdvance={() => {
              this.resetAdvance();
            }}
            pagination={false}
            broswer={true}
            config={this.state.config}
            headerCard={this.state.headerCard}
            toggleDrawer={this.toggleDrawerMap}
            visible={this.state.visible}
            data={dataOut}
            dataSearch={data}
            reset={this.resetTableCards}
            labelStatus={labelStatus}
            backRes={this.backres}
            nameXlsx={`Embarques${date.getDate() +
              "" +
              (date.getMonth() + 1) +
              "" +
              date.getFullYear()
              }.xlsx`}
          ></Cards>
          {/* </div> */}
          <div className="col-12">
            <center>
              <Pagination
                ellipsisItem={null}
                activePage={activePage}
                siblingRange={0}
                size={window.innerWidth < 993 ? "mini" : ""}
                // firstItem={null}
                // lastItem={null}
                pointing
                secondary
                totalPages={totalPages}
                onPageChange={(e, { activePage }) =>
                  this.changePage(activePage)
                }
              />
            </center>
          </div>
          {/* </CardBody> */}
          {/* </Row> */}
        </Col>
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  clients: state.header.clients,
  variableBranch: state.header.variableBranch,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shipments));
