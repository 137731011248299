import React, { Component } from "react";
import pubsub from "pubsub-js";
import _ from "lodash";
import { Checkbox } from "@mui/material/";
import { Container, Divider, Dropdown, List } from "semantic-ui-react";
// import { InputGroup, Input, Col, CardBody, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";

import ToggleState from "../Common/ToggleState";
import TriggerResize from "../Common/TriggerResize";
import ToggleFullscreen from "../Common/ToggleFullscreen";
import HeaderRun from "./Header.run";
import { Drawer, Space, Radio } from "antd";
// import smallIMG from "./../../../resources/LogoSCL-small.png";
import smallIMG from "./../../../resources/Favicon.png";
import largeIMG from "./../../../resources/logoLarge.png";
import { Link, withRouter } from "react-router-dom";
import Authentication from "../../../../src/services/Authentication.js";
import { SetNewListGroups } from "./../../../state/actions/Header/";
import SessionStorage from "./../../../services/SessionStorage";
import { Services } from "../Services";
import i18n from "../../../i18n";

import "./style.scss";
import { Col, Input, Row, CardBody } from "reactstrap";

const auth = new Authentication();
const session = new SessionStorage();

class Header extends Component {
  constructor(props) {
    super(props);
    i18n.changeLanguage(this.props.lang);
    this.state = {
      redirect: false,
      openModal: false,
      openDrop: false,
      dataDrop: [],
      dataDrop1: [],
      checkAll: true,
      rolId: auth.getAuthentication("RolId"),
      cout: 0,
      visible: true,
      isMobil: false,
    };
  }
  componentDidMount() {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    const data = this.props.group_list;

    let cout = 0;
    data.map((e) => {
      e.data.map((f) => {
        f.hidden = false;
        if (f.check) {
          cout = cout + 1;
        }
        return f;
      });
      return e;
    });
    this.setState(
      {
        dataDrop: [...data] ? [...data] : [],
        dataDrop1: [...data] ? [...data] : [],
        cout,
        isMobil: isMobileDevice,
      },
      () => {}
    );

    HeaderRun();
  }
  /*=============================================
    =            Handle Togle User Block         =
    =============================================*/
  toggleUserblock(e) {
    e.preventDefault();
    pubsub.publish("toggleUserblock");
  }
  handleLogOut = () => {
    const createOffsetValue = (date) => {
      var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date.getTimezoneOffset());
      var hours = Math.floor(offset / 60);
      var minutes = ((offset % 60) / 60) * 10;
      return sign + hours + "." + minutes;
    };

    let endSession = {
      SessionId: auth.getAuthentication("token"),
      EndConnection: new Date(),
      timezone: createOffsetValue(new Date()),
      Logout: "1",
    };

    navigator.sendBeacon(
      `${Services.AUDIT.path}user/logout`,
      JSON.stringify(endSession)
    );
  };

  removeToken = () => {
    this.handleLogOut();
    auth.deleteAuthentication("token");
    auth.deleteAuthentication("dataUser");
    auth.deleteAuthentication("customerSelect");
    session.deleteItem("clients");
    session.deleteItem("nit");
    session.deleteItem("group_list");
    this.props.history.push("/");
  };

  handleSearch = (event) => {
    let { search } = this.state;
    search = event.target.value;
    let haveSearch = false;
    if (search.length > 0) {
      haveSearch = true;
    }
    let all = [...this.state.dataDrop];
    let newAll = all.map((e) => {
      let newE = { ...e };

      let newData2 = newE.data.map((item) => {
        const nameData = item.value
          ? item.value.toUpperCase()
          : "".toUpperCase();
        const nameNIT = item.NIT ? item.NIT.toUpperCase() : "".toUpperCase();
        const idData = item.id
          ? item.id.toString().toUpperCase()
          : "".toUpperCase();
        const textData = search.toUpperCase();

        if (
          nameData.indexOf(textData) > -1 ||
          idData.indexOf(textData) > -1 ||
          nameNIT.indexOf(textData) > -1
        ) {
          item.hidden = false;
        } else {
          item.hidden = haveSearch ? true : false;
        }
        return item;
      });
      newE.data = newData2;
      return newE;
    });
    let finalAll = newAll.filter((item) => {
      return item.data.length > 0;
    });
    this.setState({ dataDrop: finalAll, search });
  };

  handeInputData = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  checkGroup = (index, all, uncheck) => {
    let newData = this.state.dataDrop;
    if (all) {
      if (uncheck) {
        newData[index].check = false;
        newData[index].data.map((e) => {
          e.check = false;
          return e;
        });
      } else {
        newData[index].check = true;
        newData[index].data.map((e) => {
          e.check = true;
          return e;
        });
      }
    } else {
      newData[index].check = !newData[index].check;
      newData[index].data.map((e) => {
        e.check = newData[index].check;
        return e;
      });
    }

    let cout = 0;
    newData.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          cout = cout + 1;
        }
        return f;
      });
      return e;
    });

    this.setState({ dataDrop: newData, cout });
  };

  getCheck = async () => {
    let clientes = [];
    let NIT = [];
    let dataDrop = _.cloneDeep(this.state.dataDrop);
    this.state.dataDrop.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          clientes.push(f.id);
          NIT.push(f.NIT);
        }
        return f;
      });
      return e;
    });
    this.setState({
      openDrop: false,
      openModal: false,
      search: "",
    });
    session.updateItem("clients", JSON.stringify(clientes));
    session.updateItem("nit", JSON.stringify(NIT));
    session.updateItem("group_list", JSON.stringify(this.state.dataDrop));

    await this.props.SetNewListGroups({
      group_list: this.state.dataDrop,
      clients: clientes,
      nit: NIT,
      variableBranch: this.props.variableBranch,
    });
    dataDrop.map((data) => {
      data.data.map((entity) => {
        entity.hidden = false;
      });
    });
    this.setState({ dataDrop }, () => {
      this.props.changeProps();
    });
  };

  change = () => {
    this.setState({ openDrop: false, openModal: false });
  };

  checkClient = (index, ind) => {
    let newData = this.state.dataDrop;
    newData[index].data[ind].check = !newData[index].data[ind].check;
    if (!newData[index].data[ind].check) {
      newData[index].check = false;
    }
    let cout = 0;
    newData.map((e) => {
      e.data.map((f) => {
        if (f.check) {
          cout = cout + 1;
        }
        return f;
      });
      return e;
    });

    this.setState({ dataDrop: newData, cout });
  };

  checkAll = () => {
    if (!this.state.checkAll) {
      let newData = this.state.dataDrop;
      newData.map((e, index) => {
        this.checkGroup(index, true, false);
        return e;
      });
      this.setState({ checkAll: true });
    } else {
      let newData = this.state.dataDrop;
      newData.map((e, index) => {
        this.setState({ checkAll: false });
        this.checkGroup(index, true, true);
        return e;
      });
    }
  };

  render() {
    let {
      redirect,
      searchValue,
      dataDrop,
      checkAll,
      cout,
      visible,
      isMobil,
      openDrop,
      openModal,
    } = this.state;

    // console.log('isMobil-->', isMobil);
    if (redirect) return <Redirect to="/login" />;
    let acction;
    if (searchValue === "" || searchValue === undefined) {
    } else {
      acction = (
        <Link to={`/search/${encodeURIComponent(searchValue)}`}>
          <button className="d-none" type="submit">
            Submit
          </button>
        </Link>
      );
    }
    if (dataDrop && dataDrop.length > 0) {
      dataDrop.map((data) => {
        data.value = data.value === "Entities" ? "Entidades" : data.value;
      });
    }
    return (
      <header className="topnavbar-wrapper">
        {/* START Top Navbar */}
        <nav className="navbar topnavbar">
          {/* START navbar header */}
          <div className="navbar-header">
            <a className="navbar-brand" href="/dashboard">
              <div className="brand-logo">
                <img
                  className="img-fluid"
                  src={largeIMG}
                  alt="App Logo"
                  style={{
                    // marginRight: "1em",
                    // maxWidth: "65%"
                    width: "90px",
                  }}
                />
              </div>
              <div className="brand-logo-collapsed">
                <img
                  className="img-fluid"
                  src={smallIMG}
                  alt="App Logo"
                  style={{
                    width: "42px",
                  }}
                />
              </div>
            </a>
          </div>
          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <TriggerResize>
                <ToggleState state="aside-collapsed">
                  <a
                    title="Menú"
                    href=" "
                    className="nav-link d-none d-md-block d-lg-block d-xl-block"
                  >
                    <em className="fas fa-bars"></em>
                  </a>
                </ToggleState>
              </TriggerResize>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <ToggleState state="aside-toggled" nopersist={true}>
                <a href=" " className="nav-link sidebar-toggle d-md-none">
                  <em className="fas fa-bars" style={{ color: "#020E3A" }}></em>
                </a>
              </ToggleState>
            </li>
            {/* START User avatar toggle */}
            <li
              className="nav-item"
              // className="nav-item d-none d-md-block icono-nav-seacargo"
            >
              <a className="nav-link" onClick={this.toggleUserblock}>
                {/* <em className="icon-final-19"></em> */}
                <i className="icon-final-19"></i>
                {/* <i class="far fa-user"></i> */}
              </a>
            </li>
            {/* END User avatar toggle */}
            {/* START lock screen */}
            <li
              className="nav-item"
              // className="nav-item d-none d-md-block"
            >
              <button
                style={{ marginTop: "-6px" }}
                className="nav-link"
                id="Popover1"
                onClick={() => {
                  this.setState({
                    openDrop: isMobil ? false : !openDrop,
                    openModal: isMobil ? !openDrop : false,
                  });
                }}
              >
                <p>
                  <img
                    style={{
                      cursor: "pointer",
                      fontSize: "13px",
                      width: "23px",
                    }}
                    title="Organizaciones"
                    src="https://fsposeidon.blob.core.windows.net/seacargopic/fabrica.png"
                  ></img>
                  <i
                    style={{
                      fontSize: "11px",
                      marginLeft: "4px",
                      fontStyle: "normal",
                    }}
                  >
                    ▼
                  </i>
                  {/* </p>
                <em
                  className="icon-final-20"
                  style={{ cursor: "pointer", fontSize: "13px" }}
                  title="Organizaciones"
                > */}

                  {/* Clientes */}

                  {/* </em> */}
                </p>
              </button>
              <div
                style={{
                  background: "#FFF",
                  width: isMobil ? window.innerWidth : "730px",
                  height: "370px",
                  position: "absolute",
                  top: "45px",
                  left: "340px",
                  textAlign: "start",
                  border: "1px solid rgba(34,36,38,.15)",
                  boxShadow: "0 2px 3px 0 rgba(34,36,38,.15)",
                }}
                hidden={!openDrop}
                // className="selectCustom"
              >
                <CardBody>
                  <div style={{ textAlign: "right", paddingBottom: "3px" }}>
                    <Button
                      style={{ backgroundColor: "#ff0000", color: "#fff" }}
                      onClick={this.change}
                      size="mini"
                    >
                      x
                    </Button>{" "}
                    <Button
                      style={{ color: "#fff", backgroundColor: "#020e3a" }}
                      onClick={this.getCheck}
                      size="mini"
                    >
                      ✓
                    </Button>{" "}
                  </div>
                  <Row>
                    <Col xs={12} md={7} lg={7} style={{ paddingBottom: "5px" }}>
                      <Input
                        value={this.state.search}
                        placeholder="Nombre de la Entidad"
                        onChange={this.handleSearch}
                      />
                    </Col>
                    <Col xs={12} md={5} lg={5} className="centerver">
                      <h5
                        style={{
                          color: "#0e0e0e",
                          fontWeight: "bold",
                        }}
                      >
                        Organizaciones Seleccionadas: {cout}
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
                <div class="ui divider"></div>
                <div
                  style={{
                    overflowY: "scroll",
                    height: isMobil ? window.innerHeight : "230px",
                    // width: window.innerWidth > 769 ? 710 : window.innerWidth
                    // height: '230px'
                    maxHeight: "100%",
                  }}
                  className="heightOtganizaciones"
                >
                  <List
                    style={{
                      color: "black",
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginBottom: "0px",
                    }}
                    as="ol"
                  >
                    <List.Item as="li" value="">
                      <Checkbox
                        checked={checkAll}
                        onChange={this.checkAll}
                        value={""}
                      />
                      Seleccionar todas
                    </List.Item>
                  </List>
                  <div>
                    <List
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                      as="ol"
                    >
                      {dataDrop.map((e, index) => {
                        if (e.hidden) {
                          return <></>;
                        } else {
                          return (
                            <div style={{ marginTop: "-10px" }}>
                              <List.Item as="ol">
                                {!openDrop ? (
                                  <></>
                                ) : (
                                  e.data.map((f, ind) => {
                                    if (f.hidden) {
                                      return <></>;
                                    } else {
                                      return (
                                        <div
                                          className="squaredThree"
                                          style={{
                                            fontWeight: "initial",
                                            fontSize: "12px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            value={f.id}
                                            onChange={() => {
                                              this.checkClient(index, ind);
                                            }}
                                            id={"squaredThree" + ind}
                                            name="check"
                                            checked={f.check}
                                          />

                                          <label for={"squaredThree" + ind}>
                                            <em
                                              style={{
                                                color: "#F5F5F6",
                                                margin: "2px",
                                              }}
                                              className="fas fa-check"
                                            ></em>
                                          </label>
                                          <span style={{ marginLeft: "10px" }}>
                                            {" "}
                                            {f.NIT} -- {f.value}
                                          </span>
                                        </div>
                                      );
                                    }
                                  })
                                )}
                              </List.Item>
                              {index + 1 !== dataDrop.length ? (
                                <Dropdown.Divider />
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        }
                      })}
                    </List>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul className="navbar-nav flex-row">
            <li className="nav-item">
              {this.state.rolId !== "6" ? (
                <a className="nav-link" href=" " data-search-open="">
                  <em
                    className="icon-final-21"
                    title="Búsqueda rápida"
                    style={{ color: "#020E3A" }}
                  ></em>
                </a>
              ) : (
                ""
              )}
            </li>
            <li className="nav-item">
              <a
                onClick={this.removeToken}
                // className="nav-item d-none d-md-block"
                className="nav-link"
              >
                <Link
                  to=" "
                  title="Cerrar sesión"
                  // className="nav-link"
                  style={{ color: "#020E3A" }}
                >
                  <em className="icon-final-22"></em>
                </Link>
              </a>
            </li>

            <li className="nav-item d-none d-md-block">
              <ToggleFullscreen
                title="Pantalla Completa"
                className="nav-link"
              />
            </li>
          </ul>
          <form className="navbar-form">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                onChange={this.handeInputData}
                placeholder="Búsqueda por: No. de Embarque, No. de Contenedor, Referencia de cliente,
                 HBL, HAWB,  Consignatario, Orígen o Destino"
              />
              <div
                className="main-close-especial fa fa-times navbar-form-close" /* onClick={()=> this.navigateToExactPath()} */
              ></div>
            </div>
            {acction}
          </form>
          <Modal
            size="fullscreen"
            open={openModal}
            // onClose={() => dispatch({ type: 'close' })}
          >
            <Modal.Header>
              <div style={{ textAlign: "right", paddingBottom: "5px" }}>
                <Button
                  style={{ backgroundColor: "#ff0000", color: "#fff" }}
                  onClick={this.change}
                  size="mini"
                >
                  x
                </Button>{" "}
                <Button
                  style={{ color: "#fff", backgroundColor: "#020e3a" }}
                  onClick={this.getCheck}
                  size="mini"
                >
                  ✓
                </Button>{" "}
              </div>
              <Row>
                <Col xs={12} md={7} lg={7} style={{ paddingBottom: "5px" }}>
                  <Input
                    value={this.state.search}
                    placeholder="Nombre de la Entidad"
                    onChange={this.handleSearch}
                  />
                </Col>
                <Col xs={12} md={5} lg={5} className="centerver">
                  <h5
                    style={{
                      color: "#0e0e0e",
                      fontWeight: "bold",
                    }}
                  >
                    Organizaciones Seleccionadas: {cout}
                  </h5>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Content>
              <div
                style={{
                  overflowY: "scroll",
                  height: window.innerHeight,
                  // width: window.innerWidth > 769 ? 710 : window.innerWidth
                  // height: '230px'
                }}
                className="heightOtganizaciones"
              >
                <List
                  style={{
                    color: "black",
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginBottom: "0px",
                  }}
                  as="ol"
                >
                  <List.Item as="li" value="">
                    <Checkbox
                      checked={checkAll}
                      onChange={this.checkAll}
                      value={""}
                    />
                    Seleccionar todas
                  </List.Item>
                </List>
                <div>
                  <List
                    style={{
                      color: "black",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                    as="ol"
                  >
                    {dataDrop.map((e, index) => {
                      if (e.hidden) {
                        return <></>;
                      } else {
                        return (
                          <div style={{ marginTop: "-10px" }}>
                            <List.Item as="ol">
                              {!openModal ? (
                                <></>
                              ) : (
                                e.data.map((f, ind) => {
                                  if (f.hidden) {
                                    return <></>;
                                  } else {
                                    return (
                                      <div
                                        className="squaredThree"
                                        style={{
                                          fontWeight: "initial",
                                          fontSize: "12px",
                                          // marginBottom: "10px"
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value={f.id}
                                          onChange={() => {
                                            this.checkClient(index, ind);
                                          }}
                                          id={"squaredThree" + ind}
                                          name="check"
                                          checked={f.check}
                                        />

                                        <label for={"squaredThree" + ind}>
                                          <em
                                            style={{
                                              color: "#F5F5F6",
                                              margin: "2px",
                                            }}
                                            className="fas fa-check"
                                          ></em>
                                        </label>
                                        <span style={{ marginLeft: "10px" }}>
                                          {" "}
                                          {f.NIT} -- {f.value}
                                        </span>
                                      </div>
                                    );
                                  }
                                })
                              )}
                            </List.Item>
                            {index + 1 !== dataDrop.length ? (
                              <Dropdown.Divider />
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }
                    })}
                  </List>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        </nav>

        {/* 
        <Drawer placement="top"
          onClose={() => {
            this.setState({
              openModal: openModal,
            });
          }}
          maskClosable={true}
          closable={false}
          visible={this.state.openselect}
        >
          <Row>
            <Col xs={12} sm={12} lg={12}>
              <div style={{ textAlign: 'right' }}>
                <Button style={{ backgroundColor: "#ff0000", color: "#fff" }} onClick={this.change}>
                  x
                </Button>
                <Button style={{ color: "#fff", backgroundColor: "#020e3a" }} onClick={this.getCheck}>
                  ✓
                </Button>
              </div>
            </Col>
            <Col xs={12} md={3} lg={3} style={{ paddingBottom: '5px' }}>
              <Input
                value={this.state.search}
                placeholder="Nombre de la Entidad"
                onChange={this.handleSearch}
              />
            </Col>
            <Col xs={12} md={5} lg={5} className='centerver'>
              <h5
                style={{
                  color: '#0e0e0e',
                  fontWeight: 'bold',
                }}
              >
                Organizaciones Seleccionadas: {cout}
              </h5>
              <hr></hr>

            </Col>
          </Row>

          <List
            style={{
              color: 'black',
              fontSize: '13px',
              fontWeight: 'bold',
            }}
            as="ol"
          >
            <List.Item as="li" value="">
              <Checkbox
                checked={checkAll}
                onChange={this.checkAll}
                value={''}
              />
              Seleccionar todas
            </List.Item>
          </List>
          <div>
            <List
              style={{
                color: 'black',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
              as="ol"
            >
              {dataDrop.map((e, index) => {
                if (e.hidden) {
                  return <></>;
                } else {
                  return (
                    <div style={{ marginTop: "-10px" }}>
                      <List.Item as="ol">
                        {!this.state.openselect ? <></> : e.data.map((f, ind) => {
                          if (f.hidden) {
                            return <></>;
                          } else {
                            return <div className="squaredThree" style={{
                              fontWeight: 'initial',
                              fontSize: '12px',
                              marginBottom: "10px"
                            }} >
                              <input type="checkbox" value={f.id}
                                onChange={() => {
                                  this.checkClient(
                                    index,
                                    ind
                                  )
                                }}
                                id={"squaredThree" + ind} name="check" checked={f.check} />

                              <label for={"squaredThree" + ind}><em style={{
                                color: "#F5F5F6",
                                margin: "2px"
                              }} className="fas fa-check"></em></label>
                              <span style={{ marginLeft: "10px" }}> {f.NIT} -- {f.value}</span>
                            </div>;
                          }
                        })}
                      </List.Item>
                      {index + 1 !== dataDrop.length ? (
                        <Dropdown.Divider />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                }
              })}
            </List>
          </div>
        </Drawer> */}
        {/* 
        <Popover
          flip
          target="Popover1"
          toggle={function noRefCheck() { }}
        >
          <PopoverHeader>
            Popover Title
          </PopoverHeader>
          <PopoverBody>
            Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </PopoverBody>
        </Popover> */}
      </header>
    );
  }
}
const mapStateToProps = (state) => ({
  group_list: state.header.group_list,
  variableBranch: state.header.variableBranch,
});
const mapDispatchToProps = (dispatch) => ({
  SetNewListGroups: (new_group_list) =>
    dispatch(SetNewListGroups(new_group_list)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
