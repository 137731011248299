import React, { Component } from "react";
import _ from "lodash";
import { Card, CardBody, CardHeader } from "reactstrap";
import Fade from "react-reveal/Fade";
import { Input } from "reactstrap";
import Loader from "../../../../../Global/Loader";
import { Button } from "reactstrap";
import Table from "../../../../Components/Table";
import axios from "axios";
import { Services } from "../../../Services";
import { Dropdown, Icon } from "semantic-ui-react";
import { Modal, Popover, message, Transfer } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TemplateForm from "./../../Forms/template";
import ContainerWrapper from "../../../../../template/Layout/ContentWrapper";
import "./styles/styles.scss";
import { height } from "@mui/system";
const { confirm } = Modal;
const tableEntityConf = [
  { label: "ID", key: "EntityId" },
  { label: "Nombre Organización", key: "Name" },
  { label: "Código Organización", key: "Code" },
  { label: "Teléfono", key: "Telephone" },
  { label: "Pais", key: "Country" },
];

const tableBranchConf = [
  { label: "Branch Code", key: "BranchCode" },
  { label: "Nombre Branch", key: "BranchName" },
];
class createUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rolOperative: false,
      data: {},
      loaderNotify: false,
      TemplateForm,
      isEdit: false,
      createFormData: "",
      generalInfo: "",
      idUsuario: this.props.match ? this.props.match.params.id : -1,
      userInfo: {
        ConfirmPassword: "",
        Email: "",
        LastName: "",
        Name: "",
        Password: "",
        Position: "",
        RolId: "",
        Telephone: "",
      },
      unEditedInfo: {},
      selectCatalog: [],
      camposUsuario: true,
      camposValidos: false,
      guardarDisabled: true,
      confirmPassError: false,
      loadingInfo: true,
      isEditing: false,
      tablaClientes: "",
      tablaBranch: "",
    };
  }

  componentDidMount = async () => {
    // this.getRoles();
    // this.getUser();
    await this.getCatalogs();
    if (this.state.idUsuario > 0) {
      this.getUser();
    } else {
      this.setState({ loadingInfo: false }, () => {
        this.renderForm(false);
      });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        newSelected: [],
        selectcheck: false,
        Form: nextProps.form,
        reset: true,
      },
      () => {
        // this.getRoles();
        // this.getUser();
      }
    );
  };

  handleChangeDrop = (attribute, isEdit) => (evt, data) => {
    let { userInfo, showClientes, guardarDisabled, rolOperative } = this.state;
    userInfo[attribute] = data.value;
    if (attribute === "RolId") {
      userInfo.Entities = [];
      if ([1, 2].indexOf(data.value) >= 0) {
        showClientes = false;
      } else {
        showClientes = true;
      }
      if ([5].indexOf(data.value) >= 0) {
        rolOperative = true;
      } else {
        rolOperative = false;
      }

      guardarDisabled = !this.validarCampos(isEdit);
      //  else if (newSelected.length <= 0 && flatNext) {
      //   flatNext = false;
      // }
    }

    this.setState({ userInfo, showClientes, guardarDisabled, rolOperative }, () =>
      this.renderForm(isEdit)
    );
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index && value != null && value != "";
  };

  handleChangeFilter =
    (campo, isEdit) => (nextTargetKeys, direction, moveKeys) => {
      let { userInfo, guardarDisabled } = this.state;
      if (userInfo[campo]) {
        if (direction === "left") {
          moveKeys.forEach((key) => {
            var removeKey = userInfo[campo].indexOf(key);
            if (removeKey > -1) {
              userInfo[campo].splice(removeKey, 1);
            }
          });
          this.sendMessage("Retirado", "info");
        } else {
          moveKeys.forEach((key) => {
            userInfo[campo].push(key);
          });
          this.sendMessage("Agregado", "info");
        }

        userInfo[campo] = userInfo[campo].filter(this.onlyUnique);
      } else {
        this.sendMessage("Agregado", "info");
        userInfo[campo] = moveKeys;
      }
      guardarDisabled = true;
      if (
        [1, 2].indexOf(userInfo["RolId"]) >= 0 ||
        (userInfo[campo] && userInfo[campo].length > 0)
      ) {
        guardarDisabled = false;
      }

      this.setState({ userInfo, guardarDisabled });
    };

  sendMessage = (text, type) => {
    var messageClass = "";
    if (type === "error") {
      messageClass = "general-custom-ant-message error-custom-ant-message";
    } else if (type === "success") {
      messageClass = "general-custom-ant-message success-custom-ant-message";
    } else if (type === "info") {
      messageClass = "info-custom-ant-message";
    }
    message.open({
      content: text,
      className: messageClass,
    });
  };

  editarInformacionAlta = () => {
    this.setState({ showClientes: false, camposUsuario: true }, () => {
      this.renderForm(false);
    });
  };

  getUser = async () => {
    let { userInfo, tablaClientes, unEditedInfo } = this.state;
    axios
      .get(
        `${Services.ADMIN.path}admin/getInfoUser/${this.state.idUsuario}`,
        {}
      )
      .then((response) => {
        userInfo = response.data.user[0];
        userInfo.Entities = this.parsearArrayInt(
          userInfo.EntitiesIds ? userInfo.EntitiesIds.split(",") : []
        );

        userInfo.Branch = this.parsearArrayInt(
          userInfo.BranchCodes ? userInfo.BranchCodes.split(",") : [],
          "string"
        );

        userInfo.Reportes = this.parsearArrayInt(
          userInfo.ReportsIds ? userInfo.ReportsIds.split(",") : []
        );
        userInfo.ReportsNames = this.obtenerNombreReportes(userInfo.Reportes);
        // tablaClientes =
        // unEditedInfo = _.cloneDeep(userInfo);
        userInfo.ConfirmPassword = "";
        userInfo.Password = "";
        this.setState(
          {
            rolOperative: [5].indexOf(userInfo.RolId) >= 0,
            userInfo,
            isEdit: true,
            tablaClientes: this.obtenerTablaClientes(userInfo.Entities),
            tablaBranch: this.obtenerTablaBranch(userInfo.Branch),
            unEditedInfo: _.cloneDeep(userInfo),
            loadingInfo: false,
          },
          () => {
            this.generarInfoGeneral();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  parsearArrayInt = (arr, type) => {
    let intArr = [];
    arr.map((s) => {
      if (type === "string") {
        intArr.push(s);
        return;
      }
      intArr.push(parseInt(s));
    });
    return intArr;
  };

  obtenerTablaClientes = (EntitiesIds) => {
    var arrayEntity = [];

    EntitiesIds.map((entId) => {
      this.state.selectCatalog["Entities"].map((catEnt) => {
        if (entId === catEnt.key) {
          arrayEntity.push(catEnt.data);
        }
      });
    });

    return (
      <Table
        columns={tableEntityConf}
        data={arrayEntity}
        itemsByPage={5}
        options={{
          large: true,
          downloads: {
            pdf: false,
            xlsx: false,
          },
          pagination: true,
          search: true,
        }}
      />
    );
  };

  obtenerTablaBranch = (BranchIds) => {
    var arrayBranch = [];

    BranchIds.map((entId) => {
      this.state.selectCatalog["Branch"].map((catEnt) => {
        if (entId === catEnt.key) {
          arrayBranch.push(catEnt.data);
        }
      });
    });

    return (
      <Table
        columns={tableBranchConf}
        data={arrayBranch}
        itemsByPage={5}
        options={{
          large: true,
          downloads: {
            pdf: false,
            xlsx: false,
          },
          pagination: true,
          search: true,
        }}
      />
    );
  };

  obtenerNombreReportes = (ReportsIds) => {
    let ReportsName = [];
    if (ReportsIds && ReportsIds.length > 0) {
      ReportsIds.map((id) => {
        this.state.selectCatalog["Reportes"].map((reporte) => {
          if (id === reporte.key) {
            ReportsName.push(reporte.text);
          }
        });
      });
    }
    return ReportsName;
  };

  getCatalogs = async () => {
    let { selectCatalog } = this.state;
    await axios
      .get(`${Services.ADMIN.path}admin/catalogs`, {})
      .then((response) => {
        selectCatalog["Reportes"] = response.data.reportes;
        selectCatalog["Roles"] = response.data.roles;
        selectCatalog["Entities"] = response.data.clientes;
        selectCatalog["Branch"] = response.data.branch;
        this.setState(
          {
            selectCatalog,
          },
          () => {
            this.renderForm();
          }
        );
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleChange = (name, isEdit) => (event) => {
    let { userInfo, errorPass, confirmPassError, guardarDisabled } = this.state;
    userInfo[name] = event.target.value;
    if (name === "Password" || name === "ConfirmPassword") {
      const emailRegex = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/
      );
      let pass = userInfo["Password"] ? userInfo["Password"] : "";
      if (emailRegex.test(pass)) {
        errorPass = false;
      } else {
        errorPass = true;
      }

      if (userInfo["ConfirmPassword"] !== userInfo["Password"]) {
        confirmPassError = true;
      } else {
        confirmPassError = false;
      }
      if (isEdit === true) {
        if (userInfo["ConfirmPassword"] === "" && userInfo["Password"] === "") {
          camposValidos = true;
          errorPass = false;
        } else {
          camposValidos = !confirmPassError && !errorPass;
        }
      } else {
        camposValidos = !confirmPassError && !errorPass;
      }
    }
    if (!confirmPassError && !errorPass) {
      var camposValidos = this.validarCampos(isEdit);
    }
    //Habilitar guardado en edicion
    if (isEdit) {
      guardarDisabled = !camposValidos;
    }

    this.setState(
      { userInfo, errorPass, camposValidos, confirmPassError, guardarDisabled },
      () => this.renderForm(isEdit)
    );
  };

  validarCampos = (isEdit) => {
    let { userInfo } = this.state;
    var campos = TemplateForm[isEdit ? "editUser" : "createUser"];
    var valido = true;
    campos.map((campo) => {
      if (campo.required) {
        if (!userInfo[campo.id] || userInfo[campo.id] === "") {
          valido = false;
        }
      }
    });
    var flagEntities = false;
    if (isEdit) {
      if ([1, 2].indexOf(userInfo.RolId) >= 0) {
        flagEntities = true;
      } else {
        flagEntities = userInfo.Entities && userInfo.Entities.length > 0;
      }
    } else {
      flagEntities = true;
    }

    return valido && flagEntities;
  };

  // handleChangeSelect = (selected) => (event) => {
  //   /**
  //     1-Admin
  //     2-SeaCargo SU
  //   */
  //   let { showClientes, flatNext, newSelected } = this.state;
  //   console.log("showClientes");
  //   showClientes = true;

  //   if (selected === "RolId") {
  //     if (event && [1, 2].indexOf(event.value) >= 0) {
  //       showClientes = false;
  //     } else if (newSelected.length <= 0 && flatNext) {
  //       flatNext = false;
  //     }

  //   }
  //   document.getElementById(selected).style.color = 'black';
  //   if (event && event.value) {
  //     let { user } = this.state;
  //     user[selected] = event.value;
  //     this.setState({ selected, showClientes, flatNext }, () => {
  //       this.buildFormCreate();
  //     });
  //   } else {
  //     let { user } = this.state;
  //     user[selected] = '';
  //     this.setState({ selected, showClientes, flatNext }, () => {
  //       this.buildFormCreate();
  //     });
  //   }
  // };

  recieve = (array) => {
    this.setState({
      newSelected: array,
    });
  };

  dataReport = (data, edit) => {
    let dataReport = { data: data, userId: this.props._user };

    this.setState({
      dataReport,
    });
  };
  viewPass = (isEdit) => {
    let { viewpass, TemplateForm } = this.state;
    for (
      let i = 0;
      i < TemplateForm[isEdit ? "editUser" : "createUser"].length;
      i++
    ) {
      const e = TemplateForm[isEdit ? "editUser" : "createUser"][i];
      if (e.id === "ConfirmPassword" || e.id === "Password") {
        e.datatype = viewpass ? "" : "Password";
      }
    }
    this.setState({ TemplateForm }, () => {
      this.renderForm(isEdit);
    });
  };

  /*
    ####################################GENERACION DE CAMPOS####################################
   */
  renderForm = (isEdit) => {
    let {
      TemplateForm,
      userInfo,
      selectCatalog,
      confirmPassError,
      showCatalogModal,
      viewpass,
      preview,
      errorPass,
    } = this.state;
    let createFormData = TemplateForm[isEdit ? "editUser" : "createUser"].map(
      (e) => {
        if (e.type === "Input")
          return (
            <div style={{ marginTop: "15px" }} className={e.class} key={e.id}>
              <Fade left opposite>
                {" "}
                <span id={e.id} style={e.style}>
                  <label>
                    {e.label}{" "}
                    {e.required === true ? (
                      <span style={{ color: "#ff0000" }}>*</span>
                    ) : (
                      ""
                    )}
                  </label>
                </span>
              </Fade>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  required
                  onFocus={(e) => {
                    e.target.setAttribute("autocomplete", "none");
                  }}
                  type={e.datatype}
                  placeholder={e.placeHolder}
                  name={e.id}
                  value={userInfo[e.id]}
                  maxlength={e.maxlength}
                  minlength={e.minlength}
                  className={e.id === "Email" ? "email-lower" : ""}
                  autoComplete="new-password"
                  // autoComplete="off"
                  onChange={this.handleChange(e.id, isEdit ? true : false)}
                  max={e.max}
                />
                {e.id === "ConfirmPassword" ? (
                  <em
                    className="mr-2 far fa-eye"
                    onClick={() =>
                      this.setState({ viewpass: !viewpass }, () =>
                        this.viewPass(isEdit)
                      )
                    }
                    style={{
                      color: "rgb(0, 121, 184)",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                  ></em>
                ) : (
                  ""
                )}
              </div>
              {!preview && errorPass && e.id === "Password" ? (
                <div style={{ width: "100%" }}>
                  <div
                    id="pass_diferentes"
                    style={{ marginTop: "10px", fontSize: "smaller" }}
                    className="col-12"
                  >
                    <label>
                      La contrasña debe de estar compuesta por:
                      <p style={{ color: "#BA2B30", fontSize: "smaller" }}>
                        Mínimo 8 dígitos y hasta máximo 15 dígitos. Al menos una
                        letra mayúscula y una minúscula. Un carácter especial
                        (*!$%&?).
                      </p>
                    </label>
                    {/* <ul style={{ color: '#BA2B30' }}>
                                <li>Minimun 8 digits and up to maximum 15 digits.</li>
                                <li>At least one uppercase and one lowercase letter.</li>
                                <li>A special character(*$%&?_-)</li>
                            </ul> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {!preview && confirmPassError && e.id === "ConfirmPassword" ? (
                <div style={{ width: "100%" }}>
                  <div
                    id="pass_diferentes"
                    style={{ marginTop: "10px", fontSize: "smaller" }}
                    className="col-12"
                  >
                    <label>Las contraseñas no coinciden</label>
                    {/* <ul style={{ color: '#BA2B30' }}>
                                <li>Minimun 8 digits and up to maximum 15 digits.</li>
                                <li>At least one uppercase and one lowercase letter.</li>
                                <li>A special character(*$%&?_-)</li>
                            </ul> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        else if (e.type === "Select")
          return (
            <div style={{ marginTop: "15px" }} className={e.class} key={e.id}>
              <Fade left opposite>
                <span id={e.id} style={e.style}>
                  {" "}
                  <label>
                    {e.label}{" "}
                    {e.required ? (
                      <span style={{ color: "#ff0000" }}>*</span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                </span>
              </Fade>

              <Dropdown
                className="search-drops"
                placeholder={e.label}
                id={e.id}
                options={selectCatalog[e.selectCatalogName]}
                clearable
                selection
                fluid
                allowAdditions
                value={userInfo[e.id]}
                onChange={this.handleChangeDrop(e.id, isEdit)}
              />
              <Fade left opposite>
                <label style={e.style}>{e.error}</label>
              </Fade>
            </div>
          );
        else
          return (
            <div
              className={e.class}
              style={{ marginTop: "20px" }}
              key={e.id}
            ></div>
          );
      }
    );
    this.setState({
      createFormData,
      showCatalogModal,
      userInfo,
      isEdit,
    });
  };
  generarInfoGeneral = () => {
    let { userInfo, isEdit, showClientes } = this.state;
    let generalInfo = TemplateForm["previewForm"].map((e) => {
      var attrInfo = "";
      var extraClass = "";

      if (e.type === "label") {
        attrInfo = <span>{userInfo[e.id]}</span>;
      } else if (e.type === "list") {
        // attrInfo = <ul>
        //     {userInfo[e.id].map(element => {
        //         return <li>{element}</li>
        //     })}
        // </ul>
        attrInfo = <span>{userInfo[e.id].length}</span>;
        extraClass = "";
      } else if (e.type === "select") {
        if (userInfo[e.id]) {
          let catalog = this.state.selectCatalog[e.selectCatalogName];

          var value = catalog.filter((cat) => cat.key === userInfo[e.id]);

          attrInfo = <span>{value[0].text}</span>;
        }
      } else if (e.type === "password") {
        if (this.state.isEdit) {
          return;
        }
        attrInfo = <span>***********</span>;
      }
      return (
        <div className={e.class} style={{ paddingTop: "1%" }}>
          {
            <div className="row">
              <div className={"col-md-12 col-lg-12 col-sm-6 " + extraClass}>
                <span style={{ fontWeight: "bold" }}>{e.label}</span>
              </div>
              <div className={"col-md-12 col-lg-12 col-sm-6 " + extraClass}>
                {attrInfo}
              </div>
            </div>
          }
        </div>
      );
    });

    if (!isEdit) {
      showClientes = true;
    }
    var guardarDisabled = true;
    if ([1, 2].indexOf(userInfo["RolId"]) >= 0) {
      guardarDisabled = false;
      showClientes = false;
    } else if (userInfo.Entities && userInfo.Entities.length > 0) {
      guardarDisabled = false;
    } else if (userInfo.Branch && userInfo.Branch.length > 0) {
      guardarDisabled = false;
    }
    this.setState({
      camposUsuario: false,
      showClientes,
      guardarDisabled,
      generalInfo,
      rolOperative: [5].indexOf(userInfo["RolId"]) >= 0,
    });
  };

  confirmaGuardado = async () => {
    let { isEdit } = this.state;
    var mensaje = "¿Estás seguro de crear el usuario?";
    if (isEdit) {
      mensaje = "¿Estás seguro que deseas actualizar la información?";
    }
    confirm({
      title: <hr className="hr-popover"></hr>,
      icon: false,
      cancelText: "No",
      okText: "Si",
      content: (
        <div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <p class="seacargo-pop-icon">
              <em class="fas fa-exclamation-triangle"></em>
            </p>
            <span>{mensaje}</span>
          </div>
        </div>
      ),
      onOk: () => {
        if (isEdit) {
          this.PutUser();
        } else {
          this.saveUser();
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  saveUser = async () => {
    let { userInfo } = this.state;
    userInfo["Email"] = userInfo["Email"].toLowerCase();

    let data = { data: userInfo };
    /**
         1-Admin
         2-SeaCargo SU
       */

    if ([1, 2, 5].indexOf(data.data["RolId"]) >= 0) {
      data.data.Entities = [];
    }

    delete data["VariableBranch"];

    await axios
      .post(`${Services.ADMIN.path}admin/createUser`, data)
      .then((response) => {
        this.sendMessage("El Usuario se ha guardado correctamente!", "success");
        this.sleep(1000).then(() => {
          this.props.history.push("/usuarios");
        });
      })
      .catch((error) => {
        this.sendMessage(error.response.data.message, "error");
      });
  };

  cambiaEdicion = () => {
    let { showClientes } = this.state;
    if ([1, 2].indexOf(this.state.userInfo.RolId) >= 0) {
      showClientes = false;
    } else {
      showClientes = true;
    }
    this.setState(
      {
        isEditing: true,
        camposUsuario: true,
        showClientes,
        guardarDisabled: false,
      },
      () => {
        this.renderForm(true);
      }
    );
  };

  cancelarEdicion = () => {
    let { showClientes, unEditedInfo } = this.state;
    if ([1, 2].indexOf(this.state.userInfo.RolId) >= 0) {
      showClientes = false;
    } else {
      showClientes = true;
    }
    this.setState(
      {
        userInfo: _.cloneDeep(unEditedInfo),
        isEditing: false,
        camposUsuario: false,
        showClientes: false,
      },
      () => {
        this.generarInfoGeneral();
      }
    );
  };

  PutUser = async () => {
    let { userInfo } = this.state;
    userInfo["Email"] = userInfo["Email"].toLowerCase();
    delete userInfo["VariableBranch"];

    let data = { data: userInfo };
    /**
         1-Admin
         2-SeaCargo SU
       */

    if ([1, 2, 5].indexOf(data.data["RolId"]) >= 0) {
      data.data.Entities = [];
    }

    await axios
      .put(`${Services.ADMIN.path}admin/updateUser`, data)
      .then((response) => {
        this.sendMessage(
          "El Usuario se ha actualizado correctamente!",
          "success"
        );
        this.sleep(1000).then(() => {
          this.props.history.push("/usuarios");
        });
      })
      .catch((error) => {
        this.sendMessage(error.response.data.data.message, "error");
      });
  };

  validateSaveUser = (e) => (evt) => {
    let { editActive, Form, userInfo, isEdit, showClientes } = this.state;

    let validation = [];
    let errors = "";
    let Forms = TemplateForm.createUser;

    Forms.map((e) => {
      if (e.required === true) {
        validation.push(e);
      }
      return e;
    });
    validation.forEach((e) => {
      if (this.state.userInfo[e.id] === "") {
        errors += e.label + ", ";
        document.getElementById(e.id).style.color = "red";
      } else {
        document.getElementById(e.id).style.color = "black";
      }
    });
    if (errors.length > 0) {
      console.log("HAY ERRORES ", errors);
      message.error({
        content: "Required fields: " + errors + "",
      });
    } else {
      // if (this.state.errorPass) {
      this.generarInfoGeneral();
      // } else {
      //   message.error({
      //     content: "The Password does not conform to the specified format",
      //   });
      // }
    }
  };

  filterOption = (a, b, c) => {
    return b.label.toLowerCase().indexOf(a.toLowerCase()) > -1;
  };

  sendNotification = () => {
    let { userInfo } = this.state;
    let data = {
      name: userInfo.Name + " " + userInfo.LastName,
      email: userInfo.Email,
    };

    axios
      .post(`${Services.MAIL.path}send/nofity-new-user`, data)
      .then((response) => {
        this.setState(
          {
            loaderNotify: false,
          },
          () => this.sendMessage("Se ha enviado la notificación", "success")
        );
      })
      .catch((error) => {
        console.warn(error);
        this.setState(
          {
            loaderNotify: false,
          },
          () =>
            this.sendMessage("No se ha podido enviar la notificación", "error")
        );
      });
  };

  render() {
    let {
      showClientes,
      loadingInfo,
      isEditing,
      isEdit,
      tablaClientes,
      camposUsuario,
      createFormData,
      loaderNotify,
      rolOperative,
      guardarDisabled,
      generalInfo,
      selectCatalog,
      tablaBranch,
    } = this.state;

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    return loadingInfo ? (
      <Loader />
    ) : (
      <ContainerWrapper>
        <div className="content-heading">
          <legend style={{ marginLeft: "10px" }} className="styleTitleModule">
            <em
              // onClick={this.moreContact()}
              className="icon-final-37"
              style={{ color: "#020E3A" }}
            ></em>
            Administración
          </legend>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#020e3a",
              fontSize: "10px",
              marginBottom: "5px",
            }}
          >
            <Icon.Group size="large">
              <Icon
                name="angle left"
                className="_btnBack"
                size="big"
                onClick={() => this.props.history.push("/usuarios")}
              />
            </Icon.Group>
            <h3
              style={{
                fontWeight: "initial",
                fontSize: "18px",
              }}
              className="_btnBack"
              onClick={() => this.props.history.push("/usuarios")}
            >
              Regresar
            </h3>
          </div>
        </div>
        <Card>
          <CardHeader>
            <div className="col-12">
              <h3>Usuarios</h3>
              <hr className="hr-seacargo-red"></hr>
            </div>
          </CardHeader>
          <CardBody>
            <div className="col-12">
              {camposUsuario ? (
                <div>
                  {isEdit ? <h4>Edición Usuario</h4> : <h4>Nuevo Usuario</h4>}

                  <div className="row">
                    {/* <div className="col-12"> */}
                    {createFormData}

                    {/* </div> */}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-8">
                      <h4>
                        {isEdit
                          ? "Detalle del Usuario"
                          : "Información del Usuario"}
                      </h4>
                    </div>
                    <div className="col-4">
                      {isEdit && !isEditing ? (
                        <em
                          style={{ fontSize: "25px", marginRight: "10px" }}
                          className="fas fa-pencil-alt clickable float-right"
                          onClick={() => this.cambiaEdicion()}
                        ></em>
                      ) : (
                        false
                      )}
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-12"> */}
                    {generalInfo}

                    {/* </div> */}
                  </div>
                </div>
              )}

              {isEdit && !isEditing ? (
                <div
                  className="row col-12"
                  style={{ marginTop: "50px", marginBottom: "25px" }}
                >
                  <div
                    className={
                      isMobileDevice ? "col-12 divcenter " : "row col-1"
                    }
                  >
                    {loaderNotify ? (
                      <Icon.Group size="large">
                        <Icon
                          loading={loaderNotify}
                          name="circle notch"
                          size="large"
                        />
                        <Icon color="black" name="mail outline" />
                      </Icon.Group>
                    ) : (
                      <em
                        className="fa-2x mr-2 far fa-envelope"
                        onClick={() =>
                          this.setState({ loaderNotify: true }, () =>
                            this.sendNotification()
                          )
                        }
                        style={{
                          cursor: "pointer",
                          // textAlign: isMobileDevice ? "center" : "",
                        }}
                      ></em>
                    )}
                  </div>{" "}
                  <div
                    style={{
                      textAlign: isMobileDevice ? "center" : "",
                    }}
                    className={isMobileDevice ? "col-12" : "row col-11"}
                  >
                    <span
                      style={{
                        // textAlign: isMobileDevice ? "center" : "",
                        fontSize: "14px",
                        padding: "6px",
                        marginLeft: isMobileDevice ? "" : "-1em",
                      }}
                    >
                      Enviar notificación de nuevo usuario
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div style={{ marginTop: "50px", marginBottom: "25px" }}>
                <h4>
                  {isEdit ? "Reportes Asignados" : "Asignación de Reportes"}
                </h4>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-4 col-lg4">
                  {isEdit && !isEditing ? (
                    <div className="row">
                      <div className={"col-md-12 col-lg-12 col-sm-6 "}>
                        <span style={{ fontWeight: "bold" }}>Reporte</span>
                      </div>
                      <div className={"col-md-12 col-lg-12 col-sm-6 "}>
                        {this.state.userInfo.ReportsIds
                          ? this.state.userInfo.ReportsNames.join(", ")
                          : ""}
                      </div>
                    </div>
                  ) : (
                    <div className="input-form">
                      <Fade left opposite>
                        <span id="Reportes">
                          {" "}
                          <label>Reporte </label>
                        </span>
                      </Fade>

                      <Dropdown
                        className="search-drops"
                        placeholder="Reporte"
                        id="Reportes"
                        options={
                          selectCatalog["Reportes"]
                            ? selectCatalog["Reportes"]
                            : []
                        }
                        clearable
                        selection
                        fluid
                        allowAdditions
                        multiple
                        value={this.state.userInfo["Reportes"]}
                        onChange={this.handleChangeDrop("Reportes", isEdit)}
                      />
                      {/* <Fade left opposite><label style="" >""</label></Fade> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-12 "
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              {!isEdit ? (
                camposUsuario ? (
                  <span>
                    <Button
                      variant="contained"
                      // disabled={
                      //   !this.state.camposValidos
                      // }
                      color="primary"
                      className="button float-right"
                      onClick={this.validateSaveUser()}
                    >
                      Siguiente
                    </Button>

                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "red",
                        marginRight: "10px",
                      }}
                      color="danger"
                      className="button float-right"
                      onClick={() => this.props.history.push("/usuarios")}
                    >
                      Cancelar
                    </Button>
                  </span>
                ) : (
                  false
                )
              ) : (
                false
              )}
            </div>

            <div className="col-12" style={{ marginTop: "5%" }}>
              {showClientes && (!camposUsuario || (isEdit && isEditing)) ? (
                rolOperative ? (
                  <>
                    <div style={{ marginTop: "50px", marginBottom: "25px" }}>
                      <h4>{isEdit ? "Asociar Branch" : "Asociar Branch"}</h4>
                      <p style={{ color: "#BA2B30", fontSize: "smaller" }}>
                        Por lo menos debes asociar un branch*
                      </p>
                    </div>
                    <Transfer
                      style={{
                        marginTop: "25px",
                        width: "100%",
                        maxWidth: "100%",
                        justifyContent: "center",
                        height: "350px",
                      }}
                      locale={{
                        itemUnit: "Branch",
                        itemsUnit: "Branch",
                        searchPlaceholder: "",
                      }}
                      dataSource={this.state.selectCatalog["Branch"]}
                      showSearch
                      filterOption={this.filterOption}
                      onChange={this.handleChangeFilter(
                        "Branch",
                        this.state.isEdit
                      )}
                      render={(item) => item.label}
                      targetKeys={this.state.userInfo["Branch"]}
                      operations={["Asociar", "Quitar"]}
                      // showSelectAll={false}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "50px", marginBottom: "25px" }}>
                      <h4>
                        {this.state.isEdit
                          ? "Asociar Organizaciones"
                          : "Asociar Organizaciones"}
                      </h4>
                      <p style={{ color: "#BA2B30", fontSize: "smaller" }}>
                        Por lo menos debes asociar una organizacion *
                      </p>
                    </div>
                    <Transfer
                      style={{
                        marginTop: "25px",
                        width: "100%",
                        maxWidth: "100%",
                        justifyContent: "center",
                        height: "350px",
                      }}
                      locale={{
                        itemUnit: "Organización",
                        itemsUnit: "Organizaciones",
                        searchPlaceholder: "",
                      }}
                      dataSource={this.state.selectCatalog["Entities"]}
                      showSearch
                      filterOption={this.filterOption}
                      onChange={this.handleChangeFilter(
                        "Entities",
                        this.state.isEdit
                      )}
                      render={(item) => item.label}
                      targetKeys={this.state.userInfo["Entities"]}
                      operations={["Asociar", "Quitar"]}
                      // showSelectAll={false}
                    />
                  </>
                )
              ) : (
                <div></div>
              )}
            </div>
            {(!this.state.camposUsuario && !isEdit) || (isEdit && isEditing) ? (
              <div
                className="col-12"
                style={{ marginTop: "15px", marginBottom: "15px" }}
              >
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="contained"
                  disabled={guardarDisabled}
                  color="primary"
                  className="button float-right"
                  onClick={() => this.confirmaGuardado()}
                >
                  Guardar
                </Button>
                {isEdit && isEditing ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#020e3a",
                      opacity: "80%",
                    }}
                    color="primary"
                    className="button float-right"
                    onClick={() => {
                      this.cancelarEdicion();
                    }}
                  >
                    Cancelar
                  </Button>
                ) : (
                  false
                )}
                {!isEdit ? (
                  <Button
                    variant="contained"
                    // disabled={
                    //   !this.state.siguienteEnabled ? this.state.newSelected.length <= 0 : false
                    // }
                    style={{
                      backgroundColor: "#020e3a",
                      opacity: "80%",
                    }}
                    color="secondary"
                    className="button float-right"
                    onClick={() => {
                      this.editarInformacionAlta();
                    }}
                  >
                    Atras
                  </Button>
                ) : (
                  false
                )}
              </div>
            ) : (
              <></>
            )}
            {isEdit && !isEditing ? (
              rolOperative ? (
                <div className="">
                  <div className="col-12">{tablaBranch}</div>
                </div>
              ) : (
                <div className="">
                  <div className="col-12">{tablaClientes}</div>
                </div>
              )
            ) : (
              false
            )}
          </CardBody>
        </Card>
      </ContainerWrapper>
    );
  }
}

export default withRouter(createUsers);
