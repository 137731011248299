import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import Axios from "axios";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import { Services } from "./Services"
import Loader from "../../Global/Loader";
import ColFeeds from './components/ScrollFeeds/';
import "./styles.scss";

/*=============================================
=            reportes             =
=============================================*/
class Rssfeed extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
  };


  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">

          <legend className="styleTitleModule">
            <em
              // onClick={this.moreContact()}
              className="icon-final-16"
              style={{ color: "#020E3A" }}
            ></em>
            RSS Feeds{" "}
          </legend>

        </div>

        {/* 
        <Grid >
          <Grid.Row>
            <Grid.Column style={{ borderRight: "2px solid #FF0000" }} width={8}>
              <ColFeeds apiPath={`${Services.RSS.path}api/v1/rss-feed/mexico`} title='México' />
            </Grid.Column>
            <Grid.Column width={8}>
              <ColFeeds apiPath={`${Services.RSS.path}api/v1/rss-feed/internacional`} title='Internacional' />
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        <Row>
          <Col xs={12} md={6} lg={6} className='dimensiones'
          // style={{ borderRight: "2px solid #FF0000" }}
          >
            <CardBody>
              <ColFeeds apiPath={`${Services.RSS.path}api/v1/rss-feed/mexico`} title='México' />
            </CardBody>
          </Col>

          <Col xs={12} md={6} lg={6}>
            <CardBody>
              <ColFeeds apiPath={`${Services.RSS.path}api/v1/rss-feed/internacional`} title='Internacional' />
            </CardBody>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Rssfeed);
