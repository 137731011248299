import React, { Component } from 'react';
import { Row, Label } from 'reactstrap';
import { Pagination } from 'semantic-ui-react';
import { CardShipment } from './Shipment'

class Cards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            activePageShipment: 0,

            totalPagesShipment: 0

        };
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.data) this.setState({ data: nextProps.data, dataPage: nextProps.data, allData: nextProps.data }, () => { this.initPages() });
    }

    initPages = () => {
        const { dataPage } = this.state

        let pagShipment = Math.ceil(parseInt(dataPage.Shipments.Total) / 10)

        let totalShipment = dataPage.Shipments.Total

        this.setState({
            activePageShipment: 1,
            totalPagesShipment: pagShipment,
            totalShipment

        }, () => {
            this.buildPageResults()
        })
    }

    buildPageResults = () => {
        let { activePageShipment } = this.state

        let dataShipment = this.setResultsByPage(activePageShipment, this.state.dataPage.Shipments.Shipments)


        this.setState({
            dataShipment
        }, () => {
            this.buildCards()
        })
    }

    setResultsByPage = (page, all) => {
        page = page - 1
        const pag = all.slice((page) * 10, (page + 1) * 10)
        return pag
    }

    changePage = (page, from) => {

        if (from === 'shipment') {
            this.setState({
                activePageShipment: page,
            }, () => {
                this.buildPageResults()
            })
        }

    }

    buildCards = () => {
        const { dataShipment } = this.state

        /* =====================================Shipment===================================================*/
        let tagsShipment = dataShipment.map((e, index) => {
            return (
                <div className="col-12" key={index}>
                    <CardShipment element={e} ></CardShipment>
                </div>
            )
        })
        this.setState({
            cardsShipment: ''
        }, () => this.setState({ cardsShipment: tagsShipment }))

    }


    render() {
        let { activePageShipment, totalShipment } = this.state
        return (
            <Row>

                <div className="col-12">
                    <br />
                    <h4>Embarques</h4>
                    <Label>{(activePageShipment * 10) > totalShipment ? totalShipment : activePageShipment * 10} de {totalShipment} resultados</Label>
                    <div className="col-12" style={{ marginTop: "10px" }}>
                        {this.state.cardsShipment}
                    </div>
                    {(totalShipment !== 0 ?
                        <div className="col-12">
                            <center>
                                <Pagination
                                    activePage={activePageShipment}
                                    ellipsisItem={null}
                                    totalPages={this.state.totalPagesShipment}
                                    onPageChange={(e, { activePage }) => this.changePage(activePage, 'shipment')} />
                            </center>
                        </div> : ""
                    )}
                </div>

            </Row>
        );
    }
}
export default Cards


