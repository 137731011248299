export const Services = {
   "ADMIN": {
      "path": "https://seacargoapiadmin.azurewebsites.net/",
      "method": "",
      "descrp": "API TO HANDLE ADMIN"
   },
   "AUDIT": {
      "path": "https://seacargoapiauditoria.azurewebsites.net/",
      "method": "POST",
      "descrp": "AUDIT LOGIN, MODULE"
   }
}