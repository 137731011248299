export const IncotermsInfo = {
    grupoC: {
        grupoTitle: 'Grupo C - Entrega Indirecta',
        grupoData: `La parte vendedora contrata el vehículo para el transporte de los
        productos, sin embargo el exportador queda eximido de cualquier
        responsabilidad derivada de deterioros o costos adicionales.`,
        cfr: {
            title: 'Cost and Freight / Costo y Flete en el Puerto Convenido.',
            data: `El vendedor corre con los gastos en origen más el flete
            marítimo internacional.`
        },
        cif: {
            title: 'Cost, Insurance and Freight o Costo, Seguro y Flete, en el Puerto Convenido.',
            data: `Es igual que el CFR pero el vendedor se obliga también a la
            contratación de un seguro de carga internacional.`
        },
        cpt: {
            title: 'Carriage Paid To o Transporte Pagado Hasta.',
            data: `El vendedor paga todos los gastos en su país de origen más
            el transporte internacional. Es principalmente aéreo.`
        },
        cip: {
            title: 'Carriage and Insurance Paid to o Transporte y Seguro Pagados Hasta.',
            data: `Es igual al incoterm CPT pero adicionalmente el vendedor se
            obliga a la contratación de un seguro de carga internacional.`
        }
    },
    grupoD: {
        grupoTitle: 'Grupo D - Entrega Directa',
        grupoData: `Los costos y los riesgos corren a cuenta del vendedor desde la
        salida de la mercancía hasta su entrega en el lugar pactado.`,
        dpu: {
            title: 'Delivered at Place Unloaded o Entregada en Lugar Descargada.',
            data: `Es igual que el incoterm DAP solo que se agrega también la
            descarga de la mercancía a las obligaciones del vendedor.`
        },
        dap: {
            title: 'Delivered at Place o Entregada en Lugar Pactado.',
            data: `El vendedor se hace cargo de todos los gastos en su país de
            origen más el flete internacional y los gastos de transporte en
            el país destino, con excepción de los trámites aduanales de
            importación y la descarga de la mercancía.`
        },
        ddp: {
            title: 'Delivered Duty Paid o Entregada con Derechos Pagados.',
            data: `En este incoterm el vendedor se hace cargo de todos los
            gastos de la cadena logística, de principio a fin.`
        }
    },
    grupoE: {
        grupoTitle: 'Grupo E - Entrega Directa a la Salida',
        grupoData: `La parte vendedora proporciona la mercancía al comprador en
        sus propias instalaciones.`,
        exw: {
            title: 'Ex Works o en Fábrica.',
            data: `Este incoterm es el que menos responsabilidades conlleva
            para el vendedor. Se trata de dejar la mercancía debidamente
            empacada en la fábrica o bodega del vendedor para que el
            comprador recolecte y se haga cargo de toda la logística
            internacional.`
        }
    },
    grupoF: {
        grupoTitle: 'Grupo F - Entrega Indirecta',
        grupoData: `El vendedor dispone los bienes a un transportista seleccionando
        y abonado por el comprador.`,
        fca: {
            title: 'Free Carrier o Franco Transportista.',
            data: `Principalmente se utiliza en tráfico aéreo, el vendedor se
            obliga a dejar la mercancía despachada y libre de maniobras
            en el aeropuerto o bodega del transporte.`
        },
        fas: {
            title: 'Free Alongside Ship o Libre al Costado del Buque.',
            data: `El vendedor deja la carga despachada de exportación a un
            costado del buque en el puerto de origen.`
        },
        fob: {
            title: 'Free On Board o libre a bordo, en el Puerto de Carga Convenido.',
            data: `Probablemente el incoterm más conocido, el vendedor se
            obliga a dejar la carga despachada a bordo del buque para
            su tránsito internacional.`
        }
    }
}