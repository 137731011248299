import React from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "./style.scss";

function ImageUpload(props) {
  return (
    <FilePond
      onupdatefiles={(fileItems) => {
        //props.callFile(fileItems);
      }}
      allowMultiple={true}
      maxFiles={1}
      //server="https://magic-api-datatransformerservice.devsandbox.magicanalytics.app/api/v1/excel/getdata"
      server={{
        url: `${props.url}bi/sato/${props.userId}`,
        process: {
          onload: (res) => {
            const data = JSON.parse(res);
            if (data.message == "success") {
              props.callFile();
            }
            return data.data;
          },
        },
      }}
      acceptedFileTypes={props.filesAccepted}
      labelFileTypeNotAllowed={"El formato de archivo no es válido"}
      labelTapToUndo=""
      labelFileProcessingComplete={"Carga completa"}
      maxFileSize={"2MB"}
      labelIdle={"Seleccione sus archivos o arrástrelos hasta aquí"}
      fileValidateTypeLabelExpectedTypes={"Solo se permiten archivos .xlsx"}
      labelMaxFileSizeExceeded={"El tamaño de archivo excede los 2MB"}
      labelMaxFileSize={"Solo se permiten archivos de máximo 2MB"}
      labelFileProcessing={"Cargando"}
      labelTapToCancel={"Toque para cancelar"}
    />
  );
}

export default ImageUpload;
