const adminForm = {
  createUser: [
    {
      type: 'hidden',
      name: 'Email',
      id: 'Email',
    },
    {
      type: 'Input',
      label: 'Nombre',
      class: 'col-md-3 input-form',
      placeHolder: 'Nombre',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Apellido',
      placeHolder: 'Apellido',
      name: 'Lastname',
      class: 'col-md-3 input-form',
      id: 'LastName',
      style: {},
      required: true
    },

    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3 input-form',
      placeHolder: 'Email',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },

    {
      type: 'Select',
      options: 'RolId',
      label: 'Rol',
      name: 'RolId',

      class: 'col-md-3 input-form',
      id: 'RolId',
      selectCatalogName: "Roles",
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Posición',
      class: 'col-md-3 input-form',
      placeHolder: 'Posición',
      name: 'Position',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Teléfono',
      class: 'col-md-3 input-form',
      placeHolder: 'Teléfono',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Contraseña',
      class: 'col-md-3 input-form',
      placeHolder: '',
      name: 'Password',
      id: 'Password',
      datatype: 'Password',
      style: {},
      required: true
    },

    {
      type: 'Input',
      label: 'Confirmar Contraseña',
      class: 'col-md-3 input-form',
      placeHolder: '',
      name: 'ConfirmPassword',
      id: 'ConfirmPassword',
      datatype: 'Password',
      style: {},
      required: true
    },
    // {
    //   type: 'Transfer',
    //   label: 'Select Office',
    //   class: 'col-md-4 input-form',
    //   placeHolder: '',
    //   name: 'Offices',
    //   id: 'Offices',
    //   datatype: 'array',
    //   catalogRenderName: "label",
    //   catalogName: "Offices",
    //   localeUnit: "Office",
    //   style: {},
    //   iconStyle: { marginLeft: "10px", fontSize: "20px", color: "green" },
    //   required: true
    // },
    // {
    //   type: 'Transfer',
    //   label: 'Select Customer',
    //   class: 'col-md-4 input-form',
    //   placeHolder: '',
    //   name: 'Customers',
    //   id: 'Customers',
    //   catalogRenderName: "label",
    //   catalogName: "Customers",
    //   localeUnit: "Customer",
    //   style: {},
    //   iconStyle: { marginLeft: "10px", fontSize: "20px", color: "green" },
    //   required: true
    // },
    // {
    //   type: 'Transfer',
    //   label: 'Select Country',
    //   class: 'col-md-4 input-form',
    //   placeHolder: '',
    //   name: 'Countries',
    //   id: 'Countries',
    //   catalogRenderName: "label",
    //   catalogName: "Countries",
    //   localeUnit: "Country",
    //   style: {},
    //   iconStyle: { marginLeft: "10px", fontSize: "20px", color: "green" },
    //   required: true
    // },
  ],
  editUser: [
    {
      type: 'Input',
      label: 'Nombre',
      class: 'col-md-3 input-form',
      placeHolder: 'Nombre',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Apellido',
      placeHolder: 'Apellido',
      name: 'Lastname',
      class: 'col-md-3 input-form',
      id: 'LastName',
      style: {},
      required: true
    },

    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3 input-form',
      placeHolder: 'Email',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },

    {
      type: 'Select',
      options: 'RolId',
      label: 'Rol',
      name: 'RolId',

      class: 'col-md-3 input-form',
      id: 'RolId',
      selectCatalogName: "Roles",
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Posición',
      class: 'col-md-3 input-form',
      placeHolder: 'Posición',
      name: 'Position',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Teléfono',
      class: 'col-md-3 input-form',
      placeHolder: 'Teléfono',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Cambiar contraseña',
      class: 'col-md-3 input-form',
      placeHolder: '',
      name: 'Password',
      id: 'Password',
      datatype: 'Password',
      style: {},
      required: false
    },

    {
      type: 'Input',
      label: 'Confirmar contraseña',
      class: 'col-md-3 input-form',
      placeHolder: '',
      name: 'ConfirmPassword',
      id: 'ConfirmPassword',
      datatype: 'Password',
      style: {},
      required: false
    },
  ],
  previewForm: [
    {
      type: 'label',
      label: 'Nombre',
      class: 'col-md-3 input-form',
      placeHolder: 'Nombre',
      name: 'Name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'label',
      label: 'Apellido',
      placeHolder: 'Apellido',
      name: 'Lastname',
      class: 'col-md-3 input-form',
      id: 'LastName',
      style: {},
      required: true
    },
    {
      type: 'label',
      label: 'Posición',
      placeHolder: 'Apellido',
      name: 'Position',
      class: 'col-md-3 input-form',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'label',
      label: 'Email',
      class: 'col-md-3 input-form',
      placeHolder: 'Email',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },

    {
      type: 'select',
      options: 'RolId',
      label: 'Rol',
      name: 'RolId',

      class: 'col-md-3 input-form',
      id: 'RolId',
      selectCatalogName: "Roles",
      style: {},
      required: true
    },
    {
      type: 'label',
      label: 'Teléfono',
      class: 'col-md-3 input-form',
      placeHolder: 'Teléfono',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    }
    ,
    {
      type: 'password',
      label: 'Password *',
      class: 'col-md-3 input-form',
      placeHolder: '',
      name: 'Password',
      id: 'Password',
      datatype: 'Password',
      style: {},
      required: true
    }

    // {
    //   type: 'Input',
    //   label: 'Confirm Password',
    //   class: 'col-md-4 input-form',
    //   placeHolder: '',
    //   name: 'ConfirmPassword',
    //   id: 'ConfirmPassword',
    //   datatype: 'Password',
    //   style: {},
    //   required: true
    // }
  ]
}
export default adminForm