import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

/*=============================================
=            Card Toptals Conponent           =
=============================================*/
function CardTotals(props) {
  let words = props.title.split(" ");
  return (
    <Col xl={props.xl} md={props.md} sm={props.sm}
      style={{ padding: '5px' }}
    >
      <div className="flex-row align-items-center align-items-stretch border-0">
        <div className={props.propertiesIcon}>{props.image}</div>
        <div className={props.propertiesTitle}>
          <div className={props.titleClass}>{words.map((w) => {
            return <>{w}<br></br></>
          })}</div>
          <div className={props.totalClass ? props.totalClass : "h4 mt-0"}>{props.total}</div>

        </div>
      </div>
    </Col >
  );
}

CardTotals.defaultProps = {
  xl: 3,
  md: 6,
  sm: 12,
};

CardTotals.propTypes = {
  xl: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  propertiesIcon: PropTypes.string,
  image: PropTypes.element,
  propertiesTitle: PropTypes.string,
  total: PropTypes.number,
  title: PropTypes.string,
};

export default CardTotals;
