import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
// import logoSCL from '../resources/Logorojouber.png'
import logoSCL from '../resources/Logo-privacidad.png'
import logoSCL2 from '../resources/Logo-login.png'
// import { withRouter } from 'react-router-dom';
// import MenuImageView from '../../../resources/Roldn-Logistics.png';
// import ImagenROll from '../../../resources/Login_Logo2.png';
import './stylesPages.scss'


function Terminos_condociones(props) {

	return (
		<div>
			<Col xs={12} md={7} lg={7} className="ml-auto mr-auto">
				<CardBody>
					<div className='content'>
						<Row>
							<Col xs={4} md={3} lg={3} className='mr-auto' style={{ textAlign: "left" }} >
								<img src={logoSCL2} alt="logo" />
							</Col>
							{/* <Col xs={3} style={{ textAlign: "right" }} className='ml-auto'> */}
							{/* <img style={{ width: "55%" }} src={logoSCL} alt="logo" /> */}
							{/* <img src={logoSCL2} alt="logo" /> */}
							{/* </Col> */}
						</Row>
						<br />
						<br />
						<h5 className="header1  text-center"  >
							TÉRMINOS Y CONDICIONES – SCL GO
						</h5>
						<br />
						<br />
						<h6 className="headerTable">
							Recogida y tratamiento de datos de carácter personal
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }}>
							Los datos de carácter personal son los que pueden ser utilizados para identificar a una persona o ponerse en contacto con ella.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS puede solicitar datos personales de usuarios al acceder a aplicaciones de la empresa o de otras empresas afiliadas, así como la posibilidad de que entre estas empresas puedan compartir esos datos para mejorar los productos y servicios ofrecidos. Si no se facilitan esos datos personales, en muchos casos no podremos ofrecer los productos o servicios solicitados, es por ello que usted debe garantizar que cuenta con las debidas autorizaciones del titular de la información facilitada por usted.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							¿Qué datos de carácter personal se pueden recopilar?
						</p>

						<ul className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							<li>
								Al crear un ID, descargar una actualización de software, se recopilan diferentes datos, como nombre, dirección postal, número de teléfono, dirección de correo electrónico.
							</li>
							<li>
								Cuando se comparten contenidos con familiares y amigos o se invita a otras personas a participar en los servicios o foros, pueden recogerse los datos que facilitamos sobre esas personas, como su nombre, domicilio, correo electrónico y número de teléfono. Se utilizarán dichos datos para completar sus operaciones, ejecutar el servicio correspondiente o para combatir cualquier anomalía detectada.
							</li>
						</ul>
						<br />
						<h6 className="headerTable">
							Propósito del tratamiento de datos de carácter persona
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS podrá utilizar los datos personales suministrados para:
						</p>
						<ul >
							<li>
								Mantenerle informado acerca de los últimos servicios, las actualizaciones de software disponibles y los próximos eventos.
							</li>
							<li>
								Elaborar, perfeccionar, gestionar, proporcionar y mejorar los servicios, nuestros contenidos y nuestra publicidad.
							</li>
							<li>
								También se utilizan esos datos de carácter personal con propósitos internos, incluyendo auditorías, análisis de datos y sondeos, para mejorar los productos, servicios y comunicaciones a clientes.
							</li>
							<li>
								Si participa en un sorteo, un concurso o una promoción, pueden usarse los datos proporcionados para administrar estos programas
							</li>
						</ul>
						<br />
						<h6 className="headerTable">
							Recopilación y tratamiento de datos de carácter no personal
						</h6>
						<br />

						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS también recopilará datos de un modo que, por sí mismos, no pueden ser asociados directamente a una persona determinada. Estos datos de carácter no personal y públicos se pueden recopilar, tratar, transferir y publicar con cualquier intención. Estos son algunos ejemplos de las clases de datos de carácter no personal que SEA CARGO LOGISTICS puede recopilar y los fines para los que se realiza su tratamiento:
						</p>
						<ul >
							<li>
								Datos tales como profesión, idioma, código postal, identificador único de dispositivo, etc. para comprender mejor la conducta de nuestros clientes y mejorar nuestros servicios y anuncios publicitarios.
							</li>
							<li>
								Datos sobre cómo se usan determinados servicios, incluidas las consultas de búsqueda. Esta información se puede utilizar para incrementar la importancia de los resultados que aportan los servicios ofrecidos.
							</li>
							<li>
								Datos sobre cómo usa su dispositivo y las aplicaciones a los desarrolladores la mejora de esas aplicaciones.
							</li>
						</ul>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Si juntamos datos de carácter no personal con datos personales, los datos mezclados serán tratados como datos personales mientras sigan estando combinados.
						</p>
						<br />
						<h6 className="headerTable">
							Divulgación a terceros
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Ocasionalmente SEA CARGO LOGISTICS puede facilitar determinados datos de carácter personal a socios estratégicos que trabajen con nosotros para proveer productos y servicios o nos ayudan en nuestras actividades de marketing. No se compartirán los datos con ningún tercero para sus propios fines de marketing.
						</p>
						<br />
						<h6 className="headerTable">
							Otros terceros
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Es posible que SEA CARGO LOGISTICS divulgue datos de carácter personal por mandato legal, en el marco de un proceso judicial o por petición de una autoridad pública, tanto dentro como fuera de su país de residencia. Igualmente se puede publicar información personal si es necesaria o conveniente por motivos de seguridad nacional, para acatar la legislación vigente o por otras razones relevantes de orden público.
						</p>
						<br />
						<h6 className="headerTable">
							Protección de datos de carácter personal
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS garantizará la protección de los datos personales mediante cifrado durante el tránsito y, los alojados en instalaciones, con medidas de seguridad físicas
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Al usar ciertos productos, servicios o aplicaciones o al publicar opiniones en foros, salas de chat o redes sociales, el contenido y los datos de carácter personal que se comparta serán visible para otros usuarios, que tendrán la posibilidad de leerlos, compilarlos o usarlos.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Usted será responsable de los datos de carácter personal que distribuya o proporcione en estos casos.
						</p>
						<br />
						<h6 className="headerTable">
							Integridad y conservación de datos de carácter personal
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS garantizará la exactitud y la calidad de los datos personales, se conservarán durante el tiempo necesario para cumplir los fines para los que fueron recopilados , salvo que la ley exija conservarlos durante más tiempo.
						</p>
						<br />
						<h6 className="headerTable">
							Acceso a los datos de carácter personal
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Respecto a los datos de carácter personal que conservamos, SEA CARGO LOGISTICS le ofrece acceso a ellos para cualquier fin, incluyendo las solicitudes de rectificación en caso de que sean incorrectos o de eliminación en caso de no estar obligados a conservarlos por imperativo legal o por razones legítimas de negocio. Nos reservamos el derecho a no tramitar aquellas solicitudes que sean improcedentes o desobligantes que pongan en riesgo la privacidad de terceros, que resulten inviables o para las que la legislación local no exija derecho de acceso. Las solicitudes de acceso, rectificación o eliminación podrán enviarse a nuestra dirección SEA CARGO LOGISTICS o en la cuenta de correo electrónico privacidad@seacargo.com.
						</p>
						<br />

						<h6 className="headerTable">
							Niños y educación
							<br />
							Esta aplicación no está destinada para menores de edad y por ende, se prohíbe su uso por parte de dicha población.
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS es consciente de la necesidad de establecer precauciones adicionales para preservar la privacidad y la seguridad de los menores que utilizan las aplicaciones y exigir consentimiento de sus progenitores en caso de que no tengan la edad mínima exigida por la legislación (en México, 18 años).
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Si se han recopilado datos personales de un menor de 18 años, sin el consentimiento necesario, se debe eliminar esa información lo antes posible.
						</p>
						<br />
						<h6 className="headerTable">
							Servicios de localización
						</h6>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Para prestar servicios de localización SEA CARGO LOGISTICS podrá reunir, utilizar y compartir datos exactos sobre ubicaciones, incluyendo la situación geográfica en tiempo real de su ordenador o de su dispositivo.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							Salvo que nos den su consentimiento, estos datos de localización se recogen de manera anónima de forma que no pueden utilizarse para identificarlo. personalmente, y son usados para suministrar y mejorar sus servicios de localización.
						</p>
						<br />
						<p className="valueTable" style={{ textAlign: "justify", textJustify: "inter-word" }} >
							SEA CARGO LOGISTICS Se reserva el derecho de cambiar los términos y condiciones de la presente en cualquier momento.
						</p>
						<br />
					</div>
				</CardBody>
			</Col>
		</div>
	);
}
export default Terminos_condociones;