import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'ika.jvectormap/jquery-jvectormap-1.2.2.min.js';
import 'ika.jvectormap/jquery-jvectormap-world-mill-en.js';
import 'ika.jvectormap/jquery-jvectormap-us-mill-en.js';
import 'ika.jvectormap/jquery-jvectormap-1.2.2.css';

/** Wrapper component for jquery-vectormap plugin */
class VectorMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  static propTypes = {
    /** series entry of options object */
    series: PropTypes.object.isRequired,
    /** markers entry of options object */
    markers: PropTypes.array.isRequired,
    /** jvectormap options object */
    options: PropTypes.object.isRequired,
    /** height of the container element */
    height: PropTypes.string,
  };

  static defaultProps = {
    height: '300px',
  };

  initMap() {
    this.props.options.markers = this.props.markers;
    this.props.options.series = this.props.series;
    $(this.mapElement).vectorMap(this.props.options);
    this.setState({ loaded: true });
  }
  componentDidMount = () => {
    this.initMap();
  };
  componentWillReceiveProps(nextProps) {
    let { loaded } = this.state;
    if (nextProps.markers.length > 0 && loaded !== true) {
      this.setState({ markers: nextProps.markers }, () => this.initMap());
    }
  }

  setRef = (node) => (this.mapElement = node);

  render() {
    return <div ref={this.setRef} style={{ height: this.props.height }} />;
  }
}

export default VectorMap;
