import React, { useState, useEffect } from "react";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import ImageUpload from "../Components/ImageUpload";
import { message } from "antd";
import { Services } from "./Services.js";

const filesAccepted = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const maxSizeFile = 2000000;

function LoadPO(props) {
  const [file, setFile] = useState([]);

  function sendMessage(text, type) {
    var messageClass = "";
    if (type === "error") {
      messageClass = "general-custom-ant-message error-custom-ant-message";
    } else if (type === "success") {
      messageClass = "general-custom-ant-message success-custom-ant-message";
    } else if (type === "info") {
      messageClass = "info-custom-ant-message";
    }
    message.open({
      content: text,
      className: messageClass,
    });
  }

  function callFile() {
    sendMessage("El archivo se ha guardado correctamente!", "success");
    setFile([]);
  }

  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend style={{ marginLeft: "10px" }} className="styleTitleModule">
          <em
            // onClick={this.moreContact()}
            className="icon-file-excel"
            style={{ color: "#020E3A" }}
          ></em>
          Gestión documental
        </legend>
      </div>

      {/* <Col xs={12} md={12} lg={12}> */}
      <Card className="myCard">
        <CardBody>
          <Row>
            <Col xs={12} sm={12} md={7} lg={8}>
              <Col xs={12} sm={12} md={11} lg={9} className="mr-auto ml-auto">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-11 align-items-center mr-auto ml-auto pb-5">
                  <span className="label-euro bold mr-2">
                    {"Cargar archivo"}
                  </span>
                  <ImageUpload
                    callFile={callFile}
                    lng={"MX"}
                    filesAccepted={filesAccepted}
                    maxSizeFile={maxSizeFile}
                    url={Services.BI.path}
                    userId={props._user}
                  />
                </div>
              </Col>
            </Col>
            <Col xs={12} sm={12} md={5} lg={4} className="left-col-border">
              <div className="d-flex justify-content-center">
                <span className="download-template">
                  {"Instrucciones de carga"}
                </span>
              </div>
              <div className="pb-2">
                <p className="label-euro bold">{"1.- Cargar archivo."}</p>
                <p className="cardNameResumen">
                  {"Puede cargar el archivo de dos maneras:".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
                <p className="cardNameResumen">
                  {"- Arrastrando: Arrastre el archivo al rectángulo gris en la app web de SCLGo.".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
                <p className="cardNameResumen">
                  {"- Explorador de archivos: Haga clic en el rectángulo gris, selecciona el archivo en el explorador y haz clic en *Abrir*.".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
              </div>

              <div className="pb-2">
                <p className="label-euro bold">
                  {"2.- Enviar archivo SATO al sistema."}
                </p>
                <p className="cardNameResumen">
                  {"- De clic en el botón *Cargar*.".replaceAll("*", '"')}
                </p>
                <p className="cardNameResumen">
                  {"- EXTRA: Si desea cancelar la operación haga clic en el cuadro de diálogo".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
              </div>
              <div className="pb-2">
                <p className="label-euro bold">{"3.- Validación."}</p>
                <p className="cardNameResumen">
                  {"- El el sistema verificará el archivo, si se valida correctamente se mostrara un mensaje de confirmación".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
              </div>
              <div className="pb-2">
                <p className="label-euro bold">{"NOTAS"}</p>
                <p className="cardNameResumen">
                  {"- Solo se puede subir un archivo por proceso.".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
                <p className="cardNameResumen">
                  {"- El archivo solo puede estar en formato .xlsx".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
                <p className="cardNameResumen">
                  {"- El tamaño máximo permitido por archivo es de 2MB".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
                <p className="cardNameResumen">
                  {"- La primera fila del archivo no será leída, se toma como encabezado".replaceAll(
                    "*",
                    '"'
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* </Col> */}
      {/* <Col xs={4} md={4} lg={4}>
					<Card className="myCard">
						col-4
					</Card>
				</Col> */}
    </ContentWrapper>
  );
}

export default withRouter(LoadPO);
