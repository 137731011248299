import React from 'react';
import { Input, Form, TextArea } from 'semantic-ui-react';

import CreateContext from '../../store/CreateContext';
import './styles.scss';

function ReportConfig(){
  const context = React.useContext(CreateContext);

  const onSave = (type, value, valid) => {
    if(type === 'report_name'){
      context.set_report_name({value, valid});
    }else if(type === 'report_descr'){
      context.set_report_description({value, valid});
    }
  };

  const handleReportInfo = (evt, data) => {
    const id = evt.target.id;
    const value = data.value;
    if(value){
      onSave(id, value, true);
    } else {
      onSave(id, value, true);
    }
  };

  return (
    <div className='col-md-12'>
      <Form size='tiny colorFuente'>
        <Form.Group>
          <Form.Field required width={6}>
            <label className="colorFuente">Nombre del reporte</label>
            <Input fluid placeholder='Nombre del reporte' value={context.report_name.value} id='report_name' name='report_name' onChange={handleReportInfo}/>
          </Form.Field>
          <Form.Field required width={6} >
            <label className="colorFuente">Descripción</label>
            <TextArea fluid placeholder='Descripción' value={context.report_description.value} id='report_descr' name='report_descr' onChange={handleReportInfo}/>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ReportConfig;