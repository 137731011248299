import React from "react";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { Services } from "./../../Services";
import "./styles.scss";
/*=============================================
=            Catalogs Trends By Year          =
=============================================*/

function CatYears(props) {
  const [CatalogYears, setCatalogYears] = React.useState([]);
  const [date, setDate] = React.useState(moment(new Date()).format("YYYY"));
  const { onChangeYear, clients } = props;

  const GetCatalog = React.useCallback(async () => {
    try {
      let data = {
        customer: clients ? JSON.stringify(clients) : "[0]",
      };

      //############ INVOCACION COMENTADA ##############
      let CatYears = await Axios.post(
        `${Services.DASHBOARD.path}dashboard/getYears`,
        data
      );
      const dataCats = CatYears.data.data.year;
      const year = new Date();
      let today = [
        {
          flag: year.getFullYear(),
          key: year.getFullYear(),
          text: year.getFullYear(),
          value: year.getFullYear(),
        },
      ];
      const findYear = dataCats.find((e) => e.flag == today[0].flag);
      if (!findYear) {
        Array.prototype.push.apply(today, dataCats);
        setCatalogYears(today);
      } else {
        setCatalogYears(dataCats);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  function onChangeDate(evt, data) {
    setDate(data.value);
    onChangeYear(data);
  }

  React.useEffect(() => {
    GetCatalog();
  }, [GetCatalog]);

  return (
    <div>
      <Dropdown
        className="catalog-year-container"
        text={date}
        scrolling
        icon={"angle down"}
        placeholder="Year"
        onChange={onChangeDate}
        defaultValue={date}
        options={CatalogYears}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CatYears);
