import React, { Component } from 'react';
class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container">
        <span>&copy; Copyright {year}, Sea Cargo Logistics . Todos los derechos reservados.</span>
      </footer>
    );
  }
}

export default Footer;
