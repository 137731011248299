import React from 'react';
import propTypes from 'prop-types';
import { Item, Icon, Label } from 'semantic-ui-react';
import { Typography, Image } from 'antd';
import './styles.scss';
import moment from 'moment';
import { Col, Row } from 'reactstrap'
// import fallback from './../../../../../resources/logoLarge.png';

moment.locale('es');
const { Paragraph } = Typography;
const seaCargoImg = [
  "https://magiclog.blob.core.windows.net/seacargopic/seaPic1.png",

  "https://magiclog.blob.core.windows.net/seacargopic/seaPic2.png",

  "https://magiclog.blob.core.windows.net/seacargopic/seaPic3.png"


];
const roldanImg = "https://fsposeidon.blob.core.windows.net/roldan/R.jpg";
/**
 * Date Formatter function
 * @param {*} date - Value date from rss items
 */
const DateFormatter = (date) => {
  if (date) {
    date = date.trim();
    let DATE = moment(new Date(date)).startOf('hour').fromNow();
    return DATE;
  }
  return '';
};

// TYPE: rss, dashbord
/**
 * Item Feed for dashboard
 * @param {*} param0
 */
function ItemFeed({ feed, type }) {
  const min = 0;
  const max = 2;
  const rand = Math.floor(Math.random() * (max - min + 1) + min)
  return (
    <div className={`container-${type}`}>
      {type === 'rss' ?
        <Col xs={12} md={4} lg={4}>
          <label
            // style={{ width: "30%" }} 
            className="rss-label" >
            {moment(new Date(feed.date_string)).format('DD MMMM YYYY ').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
          </label>
        </Col> : ''
      }

      {
        feed.grouped.map((item, key) => {

          item.Preview = item.Preview == roldanImg || item.Preview == "" ||
            (item.Preview.indexOf(".jpg") == -1 && item.Preview.indexOf(".png") == -1)
            ? seaCargoImg[rand] : item.Preview;

          return (
            <Item.Group>
              <Item key={key}
                className={`item-${type}`}
              >
                <Item.Content
                  // className={type === 'rss' ? `col-4` : "col-4"}
                  size={type === 'rss' ? 'tiny' : 'tiny'}
                >
                  <Row>
                    <Col xs={3} md={4} lg={4}>
                      <a href={item.Link} target="_blank" rel='noopener noreferrer' className={`container-${type}__title`}>
                        <Image
                          // width={type === 'rss' ? 120 : "85%"}
                          // height={type === 'rss' ? 80 : 75}
                          width={item.Preview == seaCargoImg[rand] ? "80%" : "80%"}
                          //height={type === 'rss' ? 80 : 80}
                          preview={false}
                          src={item.Preview}
                        /></a>
                    </Col>
                    {/* </Item.Content> */}
                    {/* <Item.Content className={type === 'rss' ? `container-${type}__content` : "col-7"}> */}
                    {/* <Item.Content className={type === 'rss' ? `col-8` : "col-8"}> */}
                    <Col xs={9} md={8} lg={8} >
                      <Item.Header className={`container-${type}__header`}>

                        <a href={item.Link} target="_blank" rel='noopener noreferrer' className={`container-${type}__title`}>
                          <Paragraph ellipsis={{ rows: 3 }}>
                            {item.Title}
                          </Paragraph>
                        </a>
                      </Item.Header>

                      <Item.Extra className={`container-${type}__extra`}>
                        <div>
                          <div className='opacitySeaCargo' style={{ fontSize: "10px", marginRight: "10px", display: "inline" }}>
                            <Icon color='grey' name='clock outline' /> {DateFormatter(item.Pubdate)}
                          </div>
                          <div class="opacitySeaCargo" style={{ fontSize: "10px", display: "inline" }}>
                            {item.Source}
                          </div>
                        </div>
                      </Item.Extra>
                    </Col>
                  </Row>
                </Item.Content>
              </Item>
            </Item.Group>
          );
        })
      }

    </div >
  );
}

ItemFeed.propTypes = {
  type: propTypes.string
};
ItemFeed.defaultProps = {
  type: 'rss'
};

export default ItemFeed;