import React, { Suspense } from "react";
import { withRouter } from "react-router-dom";
import { Tab, Loader as Load, Icon } from "semantic-ui-react";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import Converter from "./components/convertidorMedidas";
import Incoterms from "./components/Incoterms";
import Calculador from "./components/calculadora";
import Contenedor from "./components/Contenedor";

function LogisticsTools() {
  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="styleTitleModule">
          <em
            // onClick={this.moreContact()}
            className="icon-final-17"
            style={{ color: "#020E3A" }}
          ></em>
          Herramientas Logísticas{" "}
        </legend>
      </div>

      <Tab
        menu={{ secondary: true, className: "wrapped", pointing: true }}
        menuPosition="right"
        panes={[
          {
            menuItem: "Convertidor de Medidas",
            render: () => (
              <Suspense fallback={<Load />}>
                <Converter />
              </Suspense>
            ),
          },
          {
            menuItem: "Incoterms",
            render: () => (
              <Suspense fallback={<Load />}>
                <Incoterms />
              </Suspense>
            ),
          },
          {
            menuItem: "Calculadora de Carga",
            render: () => (
              <Suspense fallback={<Load />}>
                <Calculador />
              </Suspense>
            ),
          },
          {
            menuItem: "Especificaciones Contenedor",
            render: () => (
              <Suspense fallback={<Load />}>
                <Contenedor />
              </Suspense>
            ),
          },
        ]}
      />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </ContentWrapper>
  );
}

export default withRouter(LogisticsTools);
