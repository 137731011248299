
import React from 'react';
// import { Divider, Fab, FormControlLabel, FormGroup,  RadioGroup } from '@material-ui/core';
import { Icon, Checkbox, Radio} from '@material-ui/core/';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import {blue} from '@material-ui/core/colors';
/**
 * Styles
 */
const color='#020E3A';
// const color='#001489';
const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  check: {
    color: color,
    '&$checked': {
      color: color,
    },    
  }
}));
export const RadioSelect = withStyles({
  root: {
    color: color,
    '&$checked': {
      color: color,
    },
  },
  checked: {},
})((props) => <Radio color="default" size='small' {...props} />);

export function CheckSelect(props){
  const classes = useStyles();
  return(
    <Checkbox
      checkedIcon={<Icon>{props.icon}</Icon>}
      color='none'
      className={classes.check}
      size='small'
      onClick={props.handler}
      checked={props.selected}
      inputProps={{ 'aria-labelledby': props.id }}
    />
  );
}