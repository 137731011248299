import { withRouter } from 'react-router-dom';
import _ from "lodash";
import React, { Component } from "react";
import { Modal, Popover, message, Transfer } from 'antd';
import { Button, Row, Card, CardBody, CardHeader } from 'reactstrap';
import { Input } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';

import Loader from "../../../Global/Loader";
import Table from '../../Components/Table';
import ContainerWrapper from '../../../template/Layout/ContentWrapper';

import { Services } from './../Services'


class AdminUser extends Component {
  constructor(props) {
    super(props);
    var DateExcel = "";
    var timestamp = Date.now();
    var date = new Date(timestamp);
    var month = String(date.getMonth() + 1).padStart(2, '0');
    DateExcel = "User" + date.getFullYear() + "" + month + "" + date.getDate() + ".xlsx";
    this.state = {
      loadingInfo: true,
      data: [],
      tableConf: [
        {
          //label: "Código", key: "EntityId",
          label: "Código", key: "Code",
          
        },
        { label: 'Nombre Organización', key: 'Name' },
        { label: 'País', key: 'Country' },
        { label: 'Teléfono', key: 'Telephone' },
        { label: 'Fecha de Creación', key: 'CreateDate' },
        { label: 'Usuarios Asociados', key: 'AssociatedUserId' },
      ]
    }
  }

  showGeneralInfo = (value) => {
    this.props.history.push(`/editarentidad/${value.EntityId}`);
  }



  componentDidMount = () => {

    this.getEntityService();

    this.setState({ loadingInfo: false });
  }

  getEntityService = async () => {
    let { data } = this.state;
    await Axios.get(`${Services.ADMIN.path}admin/entities`).then(response => {
      data = response.data.data

    }).catch(error => {

    })
    this.setState({ data });
  }

  deleteUser = (value) => {

    let id = value.UserId;
    Axios.delete(`${Services.ADMIN.path}admin/deleteUser/${id}`).then(response => {
      this.sendMessage('Usuario Eliminado', "success");

      this.getUserService();

    }).catch((error) => {
      if (error.response) {
        var mensaje = "";
        if (error.response.data.data) {
          mensaje = error.response.data.data.message;
        } else {
          mensaje = error.response.data.message;
        }
        this.sendMessage(mensaje, "error");
      }


    })
  }

  sendMessage = (text, type) => {
    var messageClass = "";
    if (type === "error") {
      messageClass = "general-custom-ant-message error-custom-ant-message";
    } else if (type === "success") {
      messageClass = "general-custom-ant-message success-custom-ant-message";
    } else if (type === "info") {
      messageClass = "info-custom-ant-message";
    }
    message.open({
      content: text,
      className: messageClass
    });
  }

  crearUsuario = () => {
    this.props.history.push("/altausuario");
  }

  render() {
    let { loadingInfo, errorPass, modalVisible, preview, createFormData, generalInfo, tableConf, data, isEdit } = this.state;
    return <>{
      loadingInfo ? (
        <Loader />
      ) : (
        <ContainerWrapper>
          <div className="content-heading">

            <legend style={{ marginLeft: "10px" }} className="styleTitleModule">
              <em
                // onClick={this.moreContact()}
                className="icon-final-37"
                style={{ color: "#020E3A" }}
              ></em>
              Administración
            </legend>

          </div>
          <Card >
            <CardHeader>
              <div class="col-12">
                <h3>Organizaciones</h3>
                <hr class="hr-seacargo-red"></hr>
              </div>
            </CardHeader>
            <CardBody>
              {/* <div className="row">
                <div className="col-12" style={{ fontSize: "30px", paddingRight: "30px", paddingBottom: "30px" }}>
                  <button onClick={() => { this.crearUsuario() }} className="float-right">
                    <em className="fas fa-plus-circle">
                    </em></button>
                </div>
              </div> */}
              <div className="row">
                <div className="col-12">
                  <Table columns={tableConf}
                    data={data}
                    rowClick={true}
                    customClick={this.showGeneralInfo}
                    itemsByPage={10}
               
                    options={{
                      large: false,
                      downloads: {
                        pdf: false,
                        xlsx: false
                      },
                      pagination: true,
                      search: true,
                      searchKeys: ["Name", "LastName", "Email"]
                    }} />
                </div>
              </div>
            </CardBody>
          </Card >

        </ContainerWrapper>
      )
    }</>;
  }
}

export default withRouter(AdminUser);