import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import Base from "./../template/Layout/Base";
import { MenuHandler } from "./MenuHandler";

import { Services } from "./Services.js";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Authentication from "../../services/Authentication.js";
const auth = new Authentication();

/*=============================================
=            Component For WrapperRoute       =
=============================================*/

const createOffsetValue = (date) => {
  var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = Math.floor(offset / 60);
  var minutes = ((offset % 60) / 60) * 10;
  return sign + hours + "." + minutes;
};

function WrapperRoute(props) {
  const MainComponent = props.component;
  let token = auth.getAuthentication("token");
  let RoleId = auth.getAuthentication("RolId");
  let Rol = auth.getAuthentication("Rol");
  let UserId = auth.getAuthentication("dataUser");
  let UserNameLast = auth.getAuthentication("UserNameLast");
  let UserName = auth.getAuthentication("UserName");

  let className = (document.getElementById("body").className = "layout-fixed");
  className = "layout-fixed";
  document.getElementById("body").className = className;

  let moduleLog = {
    UsuarioId: UserId,
    ModuleId: props.name,
    Date: new Date(),
    timezone: createOffsetValue(new Date()),
    SessionId: token,
  };

  // console.log("moduleLog", moduleLog);

  // navigator.sendBeacon('http://localhost:3019/modules/login', JSON.stringify(moduleLog));
  navigator.sendBeacon(
    `${Services.AUDIT.path}modules/login`,
    JSON.stringify(moduleLog)
  );

  window.addEventListener("beforeunload", function (event) {
    let endSession = {
      SessionId: token,
      EndConnection: new Date(),
      timezone: createOffsetValue(new Date()),
      Logout: "1",
    };

    // navigator.sendBeacon("http://localhost:3019/user/logout", JSON.stringify(endSession))
    navigator.sendBeacon(
      `${Services.AUDIT.path}user/logout`,
      JSON.stringify(endSession)
    );
  });

  const { history } = props;
  const GetAuthentication = React.useCallback(async () => {
    try {
      await axios.get(`${Services.ADMIN.path}oauth/validate?token=${token}`);
    } catch (error) {
      auth.deleteAuthentication("token");
      auth.deleteAuthentication("dataUser");
      auth.deleteAuthentication("customerSelect");
      history.push("/");
    }
  }, [token, history]);

  React.useEffect(() => {
    GetAuthentication();
  }, [props.component, GetAuthentication]);

  const FilterCustomer = React.useCallback(
    async (filterCustomer, objectId) => {
      try {
        /**
         * Se verifica en el servicio si es posible visualizar el objeto
         *
         */
        let data = {
          userId: UserId,
          objectId: objectId,
          moduleAction: filterCustomer,
          variableBranch: props.variableBranch,
        };
        await axios
          .post(`${Services.ADMIN.path}general/verifyCustomerAccess`, data)
          .then((response) => {
            if (response.data.code != 200) {
              history.push("/dashboard");
            }
          })
          .catch((error) => {
            console.error("Error al obtener permisos", error);
            history.push("/dashboard");
          });
      } catch (error) {
        history.push("/dashboard");
      }
    },
    [history]
  );
  React.useEffect(() => {
    if (props.filterCustomer && props.filterCustomer != "") {
      FilterCustomer(props.filterCustomer, props.match.params.id);
    }
  }, [props.filterCustomer, FilterCustomer]);

  return (
    <React.Fragment>
      <Base
        Menu={{ Menu: MenuHandler[RoleId] }}
        SideBarConfig={{
          nada: "user nothing",
          UserNameLast: UserNameLast,
          UserName: UserName,
          RoleId: RoleId,
          Rol: Rol,
        }}
      >
        {" "}
        <MainComponent name={props.name} _user={UserId} _rolId={RoleId} />{" "}
      </Base>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  variableBranch: state.header.variableBranch,
});
const mapDispatchToProps = () => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(WrapperRoute))
);

//export default withRouter(WrapperRoute);
