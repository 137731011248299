import React from "react";
import {
  Card,
  Input,
  Radio,
  Button,
  Popup,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";
import { Label, Col, Row, CardBody } from "reactstrap";
import Axios from "axios";
import Loader from "../../../../Global/Loader";
import Select from "react-virtualized-select";
import Table from "../../../../Seacargo/Components/Table";
import Authentication from "../../../../../services/Authentication";
import { Services } from "../../Services";

const auth = new Authentication();

function Calculadora() {
  let [isChecked, setIsChecked] = React.useState("aereo");
  let [longitudInput, setLongitudInput] = React.useState("");
  let [anchoInput, setAnchoInput] = React.useState("");
  let [alturaInput, setAlturaInput] = React.useState("");
  let [pesoInput, setPesoInput] = React.useState("");
  let [cbmInput, setCbmInput] = React.useState("");

  let [tipoContenedor, setTipoContenedor] = React.useState("");
  let [containerLabel, setContainerLabel] = React.useState("");

  let [cajasInput, setCajasInput] = React.useState("1");

  let [aereoCardResult, setAereoCardResult] = React.useState([]);

  let [aereoResult, setAereoResult] = React.useState([]);
  let [maritimoResult, setMaritimoResult] = React.useState([]);
  let [colorEnvelopAereo, setcolorEnvelopAereo] = React.useState("");
  let [colorEnvelopMaritimo, setColorEnvelopMaritimo] = React.useState("");
  let [isSendMail, setIsSendMail] = React.useState(false);
  let [countAereo, setCountAereo] = React.useState(0);

  const handleChange = (value) => {
    setIsChecked(value);
    /* aereo */
    setLongitudInput("");
    setAnchoInput("");
    setAlturaInput("");
    setPesoInput("");
  };

  const mCalcular = () => {
    if (isChecked === "aereo") {
      let pesoOb = {
        ind: countAereo,
        Dimensiones: `${longitudInput} x ${anchoInput} x ${alturaInput}`,
        Peso: pesoInput,
        Volumen: (longitudInput * anchoInput * alturaInput) / 1e6,
        PesoTotal: pesoInput,
        PesoVolumetrico: (longitudInput * anchoInput * alturaInput) / 6000,
      };
      setCountAereo(countAereo + 1);
      setcolorEnvelopAereo("#020E3A");

      aereoResult.push(pesoOb)
      // setAereoResult([...aereoResult, pesoOb]);
      // aereoCardResult.push(pesoOb)
      buildCard()
    }

    if (isChecked === "maritimo") {
      setCountAereo(countAereo + 1);
      setContainerLabel(tipoContenedor.label);
      setColorEnvelopMaritimo("#020E3A");


      let noCajas;

      // la longitud el ancho y el largo esta dado en cm el resultado se
      // muestra en m3 por ello se divide entre 1e+6
      let dimensiones = longitudInput * anchoInput * alturaInput;
      let cbm = dimensiones / 1e6;
      let totalVolume = cbm * cajasInput;
      let pesoVolumetrico = (dimensiones * cajasInput) / 6000;
      let pesoTotal = pesoInput * cajasInput;

      if (tipoContenedor.value === "2stdr") {
        noCajas = Math.trunc(Math.round(1165 / 35.315 / cbm));
      }
      if (tipoContenedor.value === "4stdr") {
        noCajas = Math.trunc(Math.round(2350 / 35.315 / cbm));
      }
      if (tipoContenedor.value === "40hcd") {
        noCajas = Math.trunc(Math.round(2694 / 35.315 / cbm));
      }
      if (tipoContenedor.value === "45hcd") {
        noCajas = Math.trunc(Math.round(3043 / 35.315 / cbm));
      }

      let pesoOb = {
        ind: countAereo,
        Dimensiones: `${longitudInput} x ${anchoInput} x ${alturaInput}`,
        Cajas: cajasInput,
        CBM: cbm,
        VolumenTotal: totalVolume,
        PesoTotal: pesoTotal,
        PesoVolumetrico: pesoVolumetrico,
        totalCajas: noCajas,
      };

      setCbmInput(pesoOb.CBM);

      if (containerLabel && tipoContenedor.label !== containerLabel) {
        setMaritimoResult([pesoOb]);
      } else {
        setMaritimoResult([...maritimoResult, pesoOb]);
        buildCard([...maritimoResult, pesoOb])
      }
    }
  };

  function buildCard(params) {
    if (params) {
      setAereoResult(params)
    }

    let cardResult = []
    if (isChecked === "aereo") {
      params ? cardResult = params.map((e, i) => {
        return (
          <div className="col-xs-12">
            <CardBody>
              <center>
                <Row>
                  <Col xs={12}>
                    <div className="calculadora-title">
                      Dimensiones
                    </div>
                    <div>
                      {e.Dimensiones}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Volumen Total (m3)</div>
                    <div>
                      {e.Volumen}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Peso Total (kg)</div>
                    <div>
                      {e.PesoTotal}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Peso Volumétrico (kg)</div>
                    <div>
                      {e.PesoVolumetrico}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col md={12} style={{ display: "flex", alignItems: "center" }}>
                        <Icon
                          link
                          style={{ color: "red" }}
                          name="close"
                          onClick={() => removeItem(e)}
                        />
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </center>
              <hr></hr>
            </CardBody>
          </div >
        )
      }) :
        cardResult = aereoResult.map((e, i) => {
          return (
            <div className="col-xs-12">
              <CardBody>
                <center>
                  <Row>
                    <Col xs={12}>
                      <div className="calculadora-title">
                        Dimensiones
                      </div>
                      <div>
                        {e.Dimensiones}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="calculadora-title">Volumen Total (m3)</div>
                      <div>
                        {e.Volumen}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="calculadora-title">Peso Total (kg)</div>
                      <div>
                        {e.PesoTotal}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="calculadora-title">Peso Volumétrico (kg)</div>
                      <div>
                        {e.PesoVolumetrico}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col md={12} style={{ display: "flex", alignItems: "center" }}>
                          <Icon
                            link
                            style={{ color: "red" }}
                            name="close"
                            onClick={() => removeItem(e)}
                          />
                        </Col>
                      </Row>
                    </Col>

                  </Row>
                </center>
                <hr></hr>
              </CardBody>
            </div >

          )
        })
      setAereoCardResult(cardResult)

    } else {
      cardResult = params.map((e, i) => {
        return (
          <div className="col-xs-12">
            <CardBody>
              <center>
                <Row>
                  <Col xs={12}>
                    <div className="calculadora-title">
                      Dimensiones
                    </div>
                    <div>
                      {e.Dimensiones}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Cajas</div>
                    <div>
                      {e.Cajas}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">CBM (m3)</div>
                    <div>
                      {e.CBM}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Volumen Total (m3)</div>
                    <div>
                      {e.VolumenTotal}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Peso Total (kg)</div>
                    <div>
                      {e.PesoTotal}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="calculadora-title">Peso Volumétrico (kg)</div>
                    <div>
                      {e.PesoVolumetrico}
                    </div>
                  </Col>

                </Row>
              </center>
              <hr></hr>
            </CardBody>
          </div >
        )
      })
    }
  }

  const handleEraser = () => {
    setLongitudInput("");
    setAnchoInput("");
    setAlturaInput("");
    setPesoInput("");
    setCajasInput(1);
    setCbmInput("");
    setTipoContenedor("");

    if (isChecked === "maritimo") {
      setContainerLabel("");
      setMaritimoResult([]);
      setColorEnvelopMaritimo("");
    }
  };

  const removeItem = (value) => {
    // console.log('aereoResult-->', aereoResult);
    if (isChecked === "aereo") {
      let deleteItem = aereoResult.filter((item) => item.ind !== value.ind);
      // aereoResult.push(deleteItem)
      setAereoResult(deleteItem);
      buildCard(deleteItem)
      if (aereoResult.length === 1) {
        setcolorEnvelopAereo("");
      }
    }
    if (isChecked === "maritimo") {
      let deleteItem = maritimoResult.filter((item) => item.ind !== value.ind);
      setMaritimoResult(deleteItem);
      if (maritimoResult.length === 1) {
        setColorEnvelopMaritimo("");
      }
    }
  };

  const columnsTable = [
    { label: "Dimensiones", key: "Dimensiones", position: "left" },
    {
      label: "Volumen Total (m3)",
      key: "Volumen",
      idTh: "firstTh",
      idTd: "firstTd",
      position: "center",
    },
    {
      label: "Peso Total (kg)",
      key: "PesoTotal",
      idTh: "firstTh",
      idTd: "firstTd",
      position: "right",
    },
    {
      label: "Peso Volumétrico (kg)",
      key: "PesoVolumetrico",
      idTh: "firstTh",
      idTd: "firstTd",
      position: "left",
    },
    {
      label: "",
      key: "removeItem",
      custom: (value) => (
        <Col md={12} xl={12}>
          <Row>
            <Col md={12} style={{ display: "flex", alignItems: "center" }}>
              <Icon
                link
                style={{ color: "red" }}
                name="close"
                onClick={() => removeItem(value)}
              />
            </Col>
          </Row>
        </Col>
      ),
    },
  ];

  const tableMaritimo = [
    {
      label: "Dimensiones",
      key: "Dimensiones",
      idTh: "firstTh",
      idTd: "firstTd",
    },
    { label: "Cajas", key: "Cajas", idTh: "firstTh", idTd: "firstTd" },
    { label: "CBM (m3)", key: "CBM", idTh: "firstTh", idTd: "firstTd" },
    {
      label: "Volumen Total (m3)",
      key: "VolumenTotal",
      idTh: "firstTh",
      idTd: "firstTd",
    },
    {
      label: "Peso Total (kg)",
      key: "PesoTotal",
      idTh: "firstTh",
      idTd: "firstTd",
    },
    {
      label: "Peso Volumétrico (kg)",
      key: "PesoVolumetrico",
      idTh: "firstTh",
      idTd: "firstTd",
    },
    // {
    //   label: "Numero de Cajas",
    //   key: "totalCajas",
    //   idTh: "firstTh",
    //   idTd: "firstTd",
    // },
    {
      label: "",
      key: "removeItem",
      custom: (value) => (
        <Col md={12} xl={12}>
          <Row>
            <Col md={12} style={{ display: "flex", alignItems: "center" }}>
              <Icon
                link
                style={{ color: "red" }}
                name="close"
                onClick={() => removeItem(value)}
              />
            </Col>
          </Row>
        </Col>
      ),
    },
  ];

  const containerType = [
    { key: "2stdr", value: "2stdr", label: "20′ Standard Dry Container" },
    { key: "4stdr", value: "4stdr", label: "40′ Standard Dry Container" },
    { key: "40hcd", value: "40hcd", label: "40′ High Cube Dry Container" },
    { key: "45hcd", value: "45hcd", label: "45′ High Cube Dry Container" },
  ];

  const aereoInfo = `Susceptible a cambios al momento del embarque según dimensiones, piezas, peso, volumen y
  caracteristicas finales informadas por la aerolínea, naviera, agente consolidador de carga u otro proveedor, los cuales
  serán los aplicables para la facturación.`;

  let colorEraser = longitudInput || anchoInput || alturaInput ? "#020E3A" : "";

  let cbmValue =
    longitudInput && anchoInput && alturaInput
      ? (longitudInput * anchoInput * alturaInput) / 1e6
      : "";

  const sendMail = () => {
    if (isChecked === "maritimo" && maritimoResult.length) {
      let correo = auth.getAuthentication('EmailUser');
      let objExce = {
        data: maritimoResult,
        info: {
          containerType: tipoContenedor.label,
          config: {
            //title:'Peso Volumétrico Áereo',
            headers: {
              containerType: "Tipo de Contenedor",
            },
          },
        },
        options: {
          image: {
            name: "seacargo",
          },
          headers: [
            "Dimensiones",
            "Cajas",
            "CBM (m3)",
            "Volumen Total (m3)",
            "Peso Total (kg)",
            "Peso Volumétrico",
            "Número de Cajas",
          ],
          keys: [
            "Dimensiones",
            "Cajas",
            "CBM",
            "VolumenTotal",
            "PesoTotal",
            "PesoVolumetrico",
            "totalCajas",
          ],
        },
        info: {
          config: {
            title: "Los datos presentados son aproximados y pueden variar sin previo aviso. Para mayor información contacte a Sea Cargo Logistics.",
            titlePosition: {
              col: 2,
              row: 5

            }
          }
        }
      };
      const url = `${Services.MAIL.path}send/calculadora-carga`;
      // const url = 'http://localhost:5017/send/calculadora-carga'

      Axios.post(url, {
        data: objExce,
        email: correo,
        name: "Calculo_Volumetrico_Maritimo",
      });
      setIsSendMail(true);
      setTimeout(function () {
        setIsSendMail(false);
      }, 3000);
    }

    if (isChecked === "aereo" && aereoResult.length) {
      let correo = auth.getAuthentication('EmailUser');
      let objExce = {
        data: aereoResult,
        // info:{
        //     config:{
        //       title:'Peso Volumétrico Áereo',
        //     }
        // },
        options: {
          image: {
            name: "seacargo",
          },
          headers: [
            "Dimensiones",
            "Peso (kg)",
            "Volumen Total (m3)",
            "Peso Total (kg)",
            "Peso Volumetrico (kg)",
          ],
          keys: [
            "Dimensiones",
            "Peso",
            "Volumen",
            "PesoTotal",
            "PesoVolumetrico",
          ],
        },
        info: {
          config: {
            title: "Los datos presentados son aproximados y pueden variar sin previo aviso. Para mayor información contacte a Sea Cargo Logistics.",
            titlePosition: {
              col: 2,
              row: 5

            }
          }
        }
      };
      // const url = 'http://localhost:5017/send/calculadora-carga'
      const url = `${Services.MAIL.path}send/calculadora-carga`;
      Axios.post(url, {
        data: objExce,
        email: correo,
        name: "Calculo_Volumetrico_Aereo",
      });
      setIsSendMail(true);
      setTimeout(function () {
        setIsSendMail(false);
      }, 2500);
    }
  };

  const aereo = () => {
    return (
      <div className="row">
        <div className="col-12">
          {isChecked === "maritimo" ? (
            <>
              <div className="row">
                <div
                  className="col-4"
                  style={{ paddingRight: "0", textAlign: "left" }}
                >
                  <Label>Tipo de Contenedor</Label>
                </div>
                <div className="col-8">
                  <Select
                    placeholder="Tipo de contenedor"
                    value={tipoContenedor}
                    onChange={(selectValue) => setTipoContenedor(selectValue)}
                    options={containerType}
                  />
                </div>
                {/* <div className="col-2">
                  <Label />
                </div> */}
              </div>
              <br />
            </>
          ) : (
            ""
          )}
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4">
              <Label>Longitud</Label>
            </div>
            <div className="col-5">
              <Input
                fluid
                value={longitudInput}
                type="number"
                onChange={(e, { value }) => setLongitudInput(value)}
              />
            </div>
            <div className="col-3">
              <Label>cm</Label>
            </div>
          </div>
          <br />
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4" /* style={{padding:'0'}} */>
              <Label>Ancho</Label>
            </div>
            <div className="col-5 col-md-5 col-lg-5 col-lg-5">
              <Input
                fluid
                value={anchoInput}
                type="number"
                onChange={(e, { value }) => setAnchoInput(value)}
              />
            </div>
            <div className="col-3 col-md-3 col-lg-3">
              <Label>cm</Label>
            </div>
          </div>
          <br />
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4">
              <Label>Altura</Label>
            </div>
            <div className="col-5 col-md-5 col-lg-5 col-lg-5">
              <Input
                fluid
                value={alturaInput}
                type="number"
                onChange={(e, { value }) => setAlturaInput(value)}
              />
            </div>
            <div className="col-3 col-md-3 col-lg-3">
              <Label>cm</Label>
            </div>
          </div>
          <br />
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-4">
              <Label>Peso</Label>
            </div>
            <div className="col-5 col-md-5 col-lg-5 col-lg-5">
              <Input
                fluid
                value={pesoInput}
                type="number"
                onChange={(e, { value }) => setPesoInput(value)}
              />
            </div>
            <div className="col-3 col-md-3 col-lg-3">
              <Label>kg</Label>
            </div>
          </div>
          <br />
          {isChecked === "maritimo" ? (
            <>
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-4">
                  <Label>Cajas</Label>
                </div>
                <div className="col-5 col-md-5 col-lg-5 col-lg-5">
                  <Input
                    fluid
                    value={cajasInput}
                    type="number"
                    onChange={(e, { value }) => setCajasInput(value)}
                  />
                </div>
                <div className="col-3 col-md-3 col-lg-3">
                  <Label />
                </div>
              </div>
              <br />
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-4">
                  <Label>CBM</Label>
                </div>
                <div className="col-5 col-md-5 col-lg-5 col-lg-5">
                  <Input
                    fluid
                    value={cbmValue}
                  //disabled
                  //onChange={(e, { value }) => (setCbmInput(value))}
                  />
                </div>
                <div className="col-3 col-md-3 col-lg-3">
                  <Label>m3</Label>
                </div>
              </div>
              <br />
            </>
          ) : (
            ""
          )}
          <div className="row" style={{ alignItems: "center" }}>
            <div
              className="col-3"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <em
                class="fa-2x mr-2 fas fa-eraser"
                style={{ color: colorEraser, marginTop: "7px" }}
                onClick={() => handleEraser()}
              />
            </div>
            <div className="col-7">
              {isChecked === "maritimo" ? (
                <Button
                  fluid
                  onClick={() => mCalcular(isChecked)}
                  className={
                    longitudInput && anchoInput && alturaInput
                      ? "colorbuttom"
                      : ""
                  }
                  // style={{background:'#020E3A'}}
                  disabled={
                    !(longitudInput && anchoInput && alturaInput) ||
                    maritimoResult.length >= 5 ||
                    !cajasInput ||
                    !tipoContenedor
                  }
                >
                  Calcular
                </Button>
              ) : (
                <Button
                  fluid
                  onClick={() => mCalcular(isChecked)}
                  className={
                    longitudInput && anchoInput && alturaInput
                      ? "colorbuttom"
                      : ""
                  }
                  disabled={
                    !(longitudInput && anchoInput && alturaInput) ||
                    aereoResult.length >= 5
                  }
                >
                  Calcular Aereo
                </Button>
              )}
            </div>
            <div className="col-2" style={{ padding: "0" }}>
              <Popup
                content={aereoInfo}
                style={{ textAlign: "justify" }}
                position="top center"
                wide="very"
                trigger={<em class="fa-1x icon-info mr-1"></em>}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <br />
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-xs-12 col-md-5 col-lg-5">
              <Card fluid style={{ backgroundColor: "#0045ef" }}>
                <Card.Content>
                  <Label style={{ font: "bold", color: "white" }}>
                    Peso Volumétrico
                  </Label>
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Card fluid className="CARD UI REACT STRAP BORDER">
                <Card.Content>
                  {/* Radio buttons */}
                  <br />
                  <div className="row">
                    <CardBody>
                      <div className="col-xs-12 col-md-12 col-lg-5">
                        <div className="row">
                          <div
                            className="col-xs-12 col-md-6 col-lg-6" /* style={{backgroundColor:'pink'}} */
                          >
                            <div className="row">
                              <div className="col-2" style={{ marginTop: "4px" }}>
                                <Radio
                                  //label='Áereo'
                                  //value='aereo'
                                  checked={isChecked === "aereo"}
                                  onChange={() => {
                                    handleChange("aereo");
                                  }}
                                />
                              </div>
                              <div
                                className="col-4" /* style={{backgroundColor:'red'}} */
                              >
                                {/* <img
                                alt=""
                                src="https://fsposeidon.blob.core.windows.net/roldan/icon_avion.png"
                                width="35"
                              /> */}
                                <em
                                  style={{ color: "#020E3A", fontSize: "20px" }}
                                  className="icon-final-04"
                                ></em>
                              </div>
                              <div
                                className="col-6"
                                style={{ marginTop: "4px" /* ,padding:'0' */ }}
                              >
                                <Label>Aéreo</Label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-2" style={{ marginTop: "4px" }}>
                                <Radio
                                  // label='Marítimo'
                                  // value='maritimo'
                                  checked={isChecked === "maritimo"}
                                  onChange={() => {
                                    handleChange("maritimo");
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  paddingLeft: "5px"
                                }}
                                className="col-4" /* style={{backgroundColor:'red'}} */
                              >
                                {/* <img
                                alt=""
                                src="https://fsposeidon.blob.core.windows.net/roldan/icon_barco.png"
                                width="30"
                              /> */}
                                <em
                                  style={{ color: "#020E3A", fontSize: "20px" }}
                                  className="icon-final-03"
                                ></em>
                              </div>
                              <div className="col-6" style={{ marginTop: "4px" }}>
                                <Label>Marítimo</Label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xs-12 col-md-12 col-lg-12" /* style={{backgroundColor:'brown'}} */
                    >
                      <div className="row">
                        <div
                          className={
                            'col-xs-12 col-md-12 col-lg-4'
                            /* isChecked === "aereo" ? "col-3" : "col-4" */
                          }
                        >
                          {aereo()}
                          <br></br>
                        </div>
                        <div
                          className={
                            'col-xs-12 col-md-12 col-lg-8'
                            /* isChecked === "aereo" ? "col-8" : "col-8" */
                          } /* style={{backgroundColor:'yellow'}} */
                        >
                          <Card fluid>
                            <Card.Content>
                              {isChecked === "maritimo" ? (
                                <div className="row">
                                  <div className="col-12">
                                    <CardBody>
                                      {/* <div className="row"> */}
                                      <Label
                                        style={{
                                          fontSize: "14px",
                                          color: "#666666",
                                          // marginLeft: "16px",
                                        }}
                                      >
                                        <b>Tipo de Contenedor: </b>
                                      </Label>
                                      {" "}{containerLabel}{" "}

                                      {/* </div> */}
                                    </CardBody>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              <Table
                                columns={
                                  isChecked === "aereo"
                                    ? columnsTable
                                    : tableMaritimo
                                }
                                data={
                                  isChecked === "aereo"
                                    ? aereoResult
                                    : maritimoResult
                                }
                                options={{
                                  large: true,
                                  downloads: {
                                    pdf: false,
                                    xlsx: false,
                                  },
                                }}
                              />

                              {/* {aereoCardResult} */}


                              <div className="row">
                                <div
                                  className="col-12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Popup
                                    open={isSendMail}
                                    basic
                                    position="top right"
                                    size="large"
                                    on="click"
                                    trigger={
                                      <em
                                        class="fa-2x mr-2 far fa-envelope"
                                        onClick={() => sendMail()}
                                        style={{
                                          color:
                                            isChecked === "aereo"
                                              ? colorEnvelopAereo
                                              : colorEnvelopMaritimo,
                                        }}
                                      />
                                    }
                                  >
                                    <div>
                                      <div className="row">
                                        <div
                                          className="col-12"
                                          style={{ textAlign: "center" }}
                                        >
                                          <Header as="h4">
                                            <Icon
                                              style={{
                                                background: "transparent",
                                              }}
                                              color="green"
                                              name="check circle outline"
                                            />
                                          </Header>
                                        </div>
                                      </div>
                                      <div style={{ height: 10 }}></div>
                                      <div className="row">
                                        <div
                                          className="col-12"
                                          style={{
                                            color: "green",
                                            textAlign: "center",
                                          }}
                                        >
                                          El acumulado de sus cálculos fue
                                          enviado a su correo exitosamente.
                                        </div>
                                      </div>
                                    </div>
                                  </Popup>
                                </div>
                              </div>
                            </Card.Content>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Card
                Card
                fluid
                header={"Peso total - Peso Volumétrico"}
                style={{ textAlign: "justify", color: "#020e3a" }}
                className="CARD UI REACT STRAP BORDER"
                description={
                  <div>
                    <p style={{ textAlign: "justify" }}>
                      Si decide ingresar el peso de una sola caja, nuestra
                      calculadora CBM para envío devolverá automáticamente dos
                      valores adicionales: el peso total y el peso volumétrico
                      de su carga.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      ¿Cuál es la diferencia entre los dos? El peso total
                      describe cuanto pesa su carga. Puede obtener este valor
                      multiplicando el peso de una sola caja por la cantidad de
                      cajas en un envío. El peso volumétrico, por otro lado, es
                      una medida artificial de cuanto espacio ocupa el artículo
                      enviado. Imagine la siguiente situación: viaja en un avión
                      y lleva un equipaje increíblemente ligero. En ese caso, no
                      se le cobrará por el peso, sino por el volumen del
                      artículo.{" "}
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Puede calcular el peso volumétrico del envío de acuerdo
                      con la siguiente fórmula:{" "}
                      <b>
                        Peso Volumétrico (kg) = Longitud (cm) * Ancho (cm) *
                        Largo (cm) * Cajas / 6000
                      </b>
                      .{" "}
                    </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calculadora;
