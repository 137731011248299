import React, { Suspense } from 'react';
import { Tab } from 'semantic-ui-react';
const Fields = React.lazy(() => import('./components/Fields'));
const Filters = React.lazy(() => import('./components/Filters'));
const Order = React.lazy(() => import('./components/Order'));
const Preview = React.lazy(() => import('./components/Preview'));

function ReportPreview(props) {
  const tabs = [
    { menuItem: 'Campos', render: () => <Suspense fallback={<div>Loading...</div>}> <Fields action={props.action} reportId={props.reportId} /></Suspense> },
    { menuItem: 'Filtros', render: () => <Suspense fallback={<div>Loading...</div>}> <Filters /></Suspense> },
    { menuItem: 'Ordenamiento', render: () => <Suspense fallback={<div>Loading...</div>}> <Order /></Suspense> },
    { menuItem: 'Previsualización', render: () => <Suspense fallback={<div>Loading...</div>}> <Preview /></Suspense> }
  ];

  return (
    // <div className='col-md-12 mt-5'>
    <Tab
      menu={{ secondary: true, className: 'wrapped' }}
      menuPosition={'right'}
      panes={tabs}
    />
    // </div>
  );
}

export default ReportPreview;