import React, { Component } from "react";
import _ from "lodash";
import { Card, CardBody, CardHeader } from "reactstrap";
import Fade from "react-reveal/Fade";
import { Input } from "reactstrap";
import Loader from "../../../../../Global/Loader";
import { Button } from "reactstrap";
import Table from "../../../../Components/Table";
import axios from "axios";
import { Services } from "../../../Services";
import { Dropdown, Icon } from "semantic-ui-react";
import { Modal, Popover, message, Transfer } from "antd";

import { withRouter } from "react-router-dom";

import TemplateForm from "./../../Forms/template"
import ContainerWrapper from "../../../../../template/Layout/ContentWrapper";

const { confirm } = Modal;
const tableUserConf = [
    { label: "ID", key: "UserId" },
    { label: "Nombre", key: "Name" },
    { label: "Apellido", key: "LastName" },
    { label: "Rol", key: "Rol" },
    { label: "Email", key: "Email" },
];
class createUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            TemplateForm,
            isEdit: false,
            createFormData: "",
            generalInfo: "",
            entityId: this.props.match.params.id,
            // entityInfo: {
            //   ConfirmPassword: "carlosRene1993?",
            //   Email: "oliver.luna@magic-log.com",
            //   LastName: "mendoza",
            //   Name: "calors",
            //   Password: "carlosRene1993?",
            //   Position: "develop",
            //   RolId: "",
            //   Telephone: "345678",
            // },
            entityInfo: {},
            unEditedInfo: {},
            selectCatalog: [],
            camposUsuario: true,
            camposValidos: false,
            guardarDisabled: true,
            confirmPassError: false,
            loadingInfo: true,
            isEditing: false,
            tablaUsuarios: "",
        };
    }

    componentDidMount = async () => {
        // this.getRoles();
        // this.getUser();
        await this.getCatalogs();
        this.getEntity();

    };
    componentWillReceiveProps = (nextProps) => {
        this.setState(
            {
                newSelected: [],
                selectcheck: false,
                Form: nextProps.form,
                reset: true,
            },
            () => {
                // this.getRoles();
                // this.getUser();
            }
        );
    };

    handleChangeDrop = (attribute, isEdit) => (evt, data) => {
        let { entityInfo, showClientes, guardarDisabled } = this.state;
        entityInfo[attribute] = data.value;
        if (attribute === "RolId") {
            entityInfo.Entities = [];
            if ([1, 2].indexOf(data.value) >= 0) {
                showClientes = false;
            } else {
                showClientes = true;
            }
            guardarDisabled = !this.validarCampos(isEdit);
            //  else if (newSelected.length <= 0 && flatNext) {
            //   flatNext = false;
            // }
        }

        this.setState({ entityInfo, showClientes, guardarDisabled }, () =>
            this.renderForm(isEdit)
        );
    };

    onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index && value != null && value != "";
    };

    handleChangeFilter =
        (campo, isEdit) => (nextTargetKeys, direction, moveKeys) => {
            let { entityInfo, guardarDisabled } = this.state;
            if (entityInfo[campo]) {
                if (direction === "left") {
                    moveKeys.forEach((key) => {
                        var removeKey = entityInfo[campo].indexOf(key);
                        if (removeKey > -1) {
                            entityInfo[campo].splice(removeKey, 1);
                        }
                    });
                    this.sendMessage("Retirado", "info");
                } else {
                    moveKeys.forEach((key) => {
                        entityInfo[campo].push(key);
                    });
                    this.sendMessage("Agregado", "info");
                }

                entityInfo[campo] = entityInfo[campo].filter(this.onlyUnique);
            } else {
                this.sendMessage("Agregado", "info");
                entityInfo[campo] = moveKeys;
            }
            // guardarDisabled = true;
            // if (
            //     [1, 2].indexOf(entityInfo["RolId"]) >= 0 ||
            //     (entityInfo[campo] && entityInfo[campo].length > 0)
            // ) {
            //     guardarDisabled = false;
            // }


            this.setState({ entityInfo, guardarDisabled });
        };

    sendMessage = (text, type) => {
        var messageClass = "";
        if (type === "error") {
            messageClass = "general-custom-ant-message error-custom-ant-message";
        } else if (type === "success") {
            messageClass = "general-custom-ant-message success-custom-ant-message";
        } else if (type === "info") {
            messageClass = "info-custom-ant-message";
        }
        message.open({
            content: text,
            className: messageClass,
        });
    };

    editarInformacionAlta = () => {
        this.setState({ showClientes: false, camposUsuario: true }, () => {
            this.renderForm(false);
        });
    };


    getCatalogs = async () => {
        let { selectCatalog } = this.state;
        await axios
            .get(`${Services.ADMIN.path}admin/catalogUser`, {})
            .then((response) => {
                selectCatalog["Usuarios"] = response.data.data;
                selectCatalog["Paises"] = response.data.paises;
                this.setState(
                    {
                        selectCatalog,
                    },
                    () => {
                        this.renderForm();
                    }
                );
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    getEntity = async () => {
        let { entityInfo, tablaUsuarios, unEditedInfo } = this.state;
        axios
            .get(
                `${Services.ADMIN.path}admin/getentityinfo/${this.state.entityId}`,
                {}
            )
            .then((response) => {
                entityInfo = response.data.entity[0];
                entityInfo.Users = this.parsearArrayInt(
                    entityInfo.UserIds ? entityInfo.UserIds.split(",") : []
                );

                entityInfo.UsersName = this.obtenerNombreUsuarios(entityInfo.Users);
                tablaUsuarios = this.obtenerTablaUsuarios(entityInfo.Users);
                unEditedInfo = _.cloneDeep(entityInfo);
                this.setState(
                    {
                        entityInfo,
                        isEdit: true,
                        tablaUsuarios,
                        unEditedInfo,
                        loadingInfo: false,
                    },
                    () => {
                        this.generarInfoGeneral();
                    }
                );
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    obtenerTablaUsuarios = (UserIds) => {
        var arrayUsuarios = [];

        UserIds.map((usrId) => {
            this.state.selectCatalog["Usuarios"].map((catUsr) => {
                if (usrId === catUsr.key) {
                    arrayUsuarios.push(catUsr.data);
                }
            });
        });
        return (
            <Table
                columns={tableUserConf}
                data={arrayUsuarios}
                itemsByPage={10}
                options={{
                    large: true,
                    downloads: {
                        pdf: false,
                        xlsx: false,
                    },
                    pagination: true,
                    search: true,
                }}
            />
        );
    };

    parsearArrayInt = (arr) => {
        let intArr = [];
        arr.map((s) => {
            intArr.push(parseInt(s));
        });
        return intArr;
    };



    obtenerNombreUsuarios = (UsersIds) => {
        let UsersName = [];

        if (UsersIds && UsersIds.length > 0) {
            UsersIds.map((id) => {
                this.state.selectCatalog["Usuarios"].map((usuario) => {
                    if (id === usuario.key) {
                        UsersName.push(usuario.text);
                    }
                });
            });
        }
        return UsersName;
    };


    sleep = (milliseconds) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    handleChange = (name, isEdit) => (event) => {
        let { entityInfo, errorPass, confirmPassError, guardarDisabled } = this.state;
        entityInfo[name] = event.target.value;

        if (!confirmPassError && !errorPass) {
            var camposValidos = this.validarCampos(isEdit);
        }

        //Habilitar guardado en edicion
        if (isEdit) {
            guardarDisabled = !camposValidos;
        }

        this.setState(
            { entityInfo, errorPass, camposValidos, confirmPassError, guardarDisabled },
            () => this.renderForm(isEdit)
        );
    };

    validarCampos = (isEdit) => {
        let { entityInfo } = this.state;
        var campos = isEdit ? TemplateForm["editEntity"] : [];
        var valido = true;
        campos.map((campo) => {
            if (campo.required) {
                if (!entityInfo[campo.id] || entityInfo[campo.id] === "") {
                    valido = false;
                }
            }
        });
        var flagEntities = false;
        if (isEdit) {
            if ([1, 2].indexOf(entityInfo.RolId) >= 0) {
                flagEntities = true;
            } else {
                flagEntities = entityInfo.Entities && entityInfo.Entities.length > 0;
            }
        } else {
            flagEntities = true;
        }

        return valido && flagEntities;
    };

    // handleChangeSelect = (selected) => (event) => {
    //   /**
    //     1-Admin
    //     2-SeaCargo SU
    //   */
    //   let { showClientes, flatNext, newSelected } = this.state;
    //   console.log("showClientes");
    //   showClientes = true;

    //   if (selected === "RolId") {
    //     if (event && [1, 2].indexOf(event.value) >= 0) {
    //       showClientes = false;
    //     } else if (newSelected.length <= 0 && flatNext) {
    //       flatNext = false;
    //     }

    //   }
    //   document.getElementById(selected).style.color = 'black';
    //   if (event && event.value) {
    //     let { user } = this.state;
    //     user[selected] = event.value;
    //     this.setState({ selected, showClientes, flatNext }, () => {
    //       this.buildFormCreate();
    //     });
    //   } else {
    //     let { user } = this.state;
    //     user[selected] = '';
    //     this.setState({ selected, showClientes, flatNext }, () => {
    //       this.buildFormCreate();
    //     });
    //   }
    // };

    recieve = (array) => {
        this.setState({
            newSelected: array,
        });
    };

    dataReport = (data, edit) => {
        let dataReport = { data: data, userId: this.props._user };

        this.setState({
            dataReport,
        });
    };
    viewPass = () => {
        let { viewpass, TemplateForm } = this.state;
        for (let i = 0; i < TemplateForm["createUser"].length; i++) {
            const e = TemplateForm["createUser"][i];
            if (e.id === "ConfirmPassword" || e.id === "Password") {
                e.datatype = viewpass ? "" : "Password";
            }
        }
        this.setState({ TemplateForm }, () => {
            this.renderForm(false);
        });
    };

    /*
      ####################################GENERACION DE CAMPOS####################################
     */
    renderForm = (isEdit) => {
        let {
            TemplateForm,
            entityInfo,
            selectCatalog,
            confirmPassError,
            showCatalogModal,
            viewpass,
            preview,
            errorPass,
        } = this.state;
        var template = isEdit ? TemplateForm["editEntity"] : []
        let createFormData = template.map(
            (e) => {
                if (e.type === "Input")
                    return (
                        <div style={{ marginTop: "15px" }} className={e.class} key={e.id}>
                            <Fade left opposite>
                                {" "}
                                <span id={e.id} style={e.style}>
                                    <label>
                                        {e.label}{" "}
                                        {e.required === true ? (
                                            <span style={{ color: "#ff0000" }}>*</span>
                                        ) : (
                                                ""
                                            )}
                                    </label>
                                </span>
                            </Fade>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input
                                    required
                                    onFocus={(e) => {
                                        e.target.setAttribute("autocomplete", "none");
                                    }}
                                    id={e.id}
                                    type={e.datatype}
                                    placeholder={e.placeHolder}
                                    name={e.id}
                                    value={entityInfo[e.id]}
                                    maxlength={e.maxlength}
                                    minlength={e.minlength}

                                    onChange={this.handleChange(e.id, isEdit ? true : false)}
                                    max={e.max}
                                />

                            </div>

                        </div>
                    );
                else if (e.type === "Select")
                    return (
                        <div style={{ marginTop: "15px" }} className={e.class} key={e.id}>
                            <Fade left opposite>
                                <span id={e.id} style={e.style}>
                                    {" "}
                                    <label>
                                        {e.label}{" "}
                                        {e.required ? (
                                            <span style={{ color: "#ff0000" }}>*</span>
                                        ) : (
                                                ""
                                            )}{" "}
                                    </label>
                                </span>
                            </Fade>

                            <Dropdown
                                className="search-drops"
                                placeholder={e.label}
                                id={e.id}
                                options={selectCatalog[e.selectCatalogName]}
                                clearable
                                selection
                                fluid
                                allowAdditions
                                value={entityInfo[e.id]}
                                onChange={this.handleChangeDrop(e.id, isEdit)}
                            />
                            <Fade left opposite>
                                <label style={e.style}>{e.error}</label>
                            </Fade>
                        </div>
                    );

                else if (e.type === "label") {
                    var attrInfo = "";
                    var extraClass = "";

                    return <div className={e.class} style={{ paddingTop: "1%" }}>
                        {
                            <div className="row">
                                <div className={"col-md-12 col-lg-12 col-sm-6 " + extraClass}>
                                    <span style={{ fontWeight: "bold" }}>{e.label}</span>
                                </div>
                                <div className={"col-md-12 col-lg-12 col-sm-6 " + extraClass}>
                                    <span>{entityInfo[e.id]}</span>
                                </div>
                            </div>
                        }
                    </div>
                }
                else
                    return (
                        <div
                            className={e.class}
                            style={{ marginTop: "20px" }}
                            key={e.id}
                        >

                        </div>
                    );
            }
        );
        this.setState({
            createFormData,
            showCatalogModal,
            entityInfo,
            isEdit,
        });
    };
    generarInfoGeneral = () => {
        let { entityInfo, isEdit, showClientes } = this.state;

        let generalInfo = TemplateForm["previewForm"].map((e) => {
            var attrInfo = "";
            var extraClass = "";

            if (e.type === "label") {
                attrInfo = <span>{entityInfo[e.id]}</span>;
            } else if (e.type === "list") {
                // attrInfo = <ul>
                //     {entityInfo[e.id].map(element => {
                //         return <li>{element}</li>
                //     })}
                // </ul>
                attrInfo = <span>{entityInfo[e.id].length}</span>;
                extraClass = "";
            } else if (e.type === "select") {
                if (entityInfo[e.id]) {
                    let catalog = this.state.selectCatalog[e.selectCatalogName];

                    var value = catalog.filter((cat) => cat.key === entityInfo[e.id]);

                    attrInfo = <span>{value[0].text}</span>;
                }
            } else if (e.type === "password") {
                if (this.state.isEdit) {
                    return;
                }
                attrInfo = <span>***********</span>;
            }
            return (
                <div className={e.class} style={{ paddingTop: "1%" }}>
                    {
                        <div className="row">
                            <div className={"col-md-12 col-lg-12 col-sm-6 " + extraClass}>
                                <span style={{ fontWeight: "bold" }}>{e.label}</span>
                            </div>
                            <div className={"col-md-12 col-lg-12 col-sm-6 " + extraClass}>
                                {attrInfo}
                            </div>
                        </div>
                    }
                </div>
            );
        });

        if (!isEdit) {
            showClientes = true;
        }
        var guardarDisabled = true;
        if ([1, 2].indexOf(entityInfo["RolId"]) >= 0) {
            guardarDisabled = false;
            showClientes = false;
        } else if (entityInfo.Entities && entityInfo.Entities.length > 0) {
            guardarDisabled = false;
        }
        this.setState({
            camposUsuario: false,
            showClientes,
            guardarDisabled,
            generalInfo,
        });
    };

    confirmaGuardado = async () => {
        let { isEdit } = this.state;
        var mensaje = "¿Estás seguro de actualizar la información?";

        confirm({
            title: <hr className="hr-popover"></hr>,
            icon: false,
            cancelText: "No",
            okText: "Si",
            content: (
                <div>
                    <div className="col-12" style={{ textAlign: "center" }}>
                        <p class="seacargo-pop-icon">
                            <em class="fas fa-exclamation-triangle"></em>
                        </p>
                        <span>{mensaje}</span>
                    </div>
                </div>
            ),
            onOk: () => {

                this.validarEditarEntity();

            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };
    validarEditarEntity = () => {
        let { editActive, Form, isEdit, showClientes } = this.state;

        let validation = [];
        let errors = "";
        let Forms = TemplateForm.editEntity;



        Forms.map((e) => {
            if (e.required === true) {
                validation.push(e);
            }
            return e;
        });
        validation.forEach((e) => {
            if (this.state.entityInfo[e.id] === "") {
                errors += e.label + ", ";
                document.getElementById(e.id).style.color = "red";
            } else if (document.getElementById(e.id)) {
                document.getElementById(e.id).style.color = "black";
            }
        });
        if (errors.length > 0) {
            console.log("HAY ERRORES ", errors);
            message.error({
                content: "Required fields: " + errors + "",
            });
        } else {
            // if (this.state.errorPass) {
            this.PutEntity();
            // } else {
            //   message.error({
            //     content: "The Password does not conform to the specified format",
            //   });
            // }
        }
    };

    cambiaEdicion = () => {
        let { showClientes, unEditedInfo } = this.state;

        if ([1, 2].indexOf(this.state.entityInfo.RolId) >= 0) {
            showClientes = false;
        } else {
            showClientes = true;
        }
        this.setState(
            {
                entityInfo: _.cloneDeep(unEditedInfo),
                createFormData: "",
                isEditing: true,
                camposUsuario: true,
                showClientes,
                guardarDisabled: false,
            },
            () => {
                this.renderForm(true);
            }
        );
    };

    cancelarEdicion = () => {
        let { unEditedInfo } = this.state;
        this.setState(
            {
                entityInfo: _.cloneDeep(unEditedInfo),
                isEditing: false,
                camposUsuario: false,
                showClientes: false,
            },
            () => {
                this.generarInfoGeneral();
            }
        );
    };

    PutEntity = async () => {
        let { entityInfo } = this.state;
        let data = { data: entityInfo };
        // console.log("entityInfo", entityInfo);
        // return;

        await axios
            .put(`${Services.ADMIN.path}admin/updateEntity`, data)
            .then((response) => {
                this.sendMessage(
                    "La organización se ha actualizado correctamente!",
                    "success"
                );
                this.sleep(1000).then(() => {
                    this.props.history.push("/entidades");
                });
            })
            .catch((error) => {
                this.sendMessage(error.response.data.message, "error");
            });
    };

    validateSaveUser = (e) => (evt) => {
        let { editActive, Form, entityInfo, isEdit, showClientes } = this.state;
        let validation = [];
        let errors = "";
        let Forms = TemplateForm.createUser;

        Forms.map((e) => {
            if (e.required === true) {
                validation.push(e);
            }
            return e;
        });
        validation.forEach((e) => {
            if (this.state.entityInfo[e.id] === "") {
                errors += e.label + ", ";
                document.getElementById(e.id).style.color = "red";
            } else {
                document.getElementById(e.id).style.color = "black";
            }
        });
        if (errors.length > 0) {
            console.log("HAY ERRORES ", errors);
            message.error({
                content: "Required fields: " + errors + "",
            });
        } else {
            // if (this.state.errorPass) {
            this.generarInfoGeneral();
            // console.log("LISTO PARA GUARDAR");
            // } else {
            //   message.error({
            //     content: "The Password does not conform to the specified format",
            //   });
            // }
        }
    };

    filterOption = (a, b, c) => {
        return b.label.toLowerCase().indexOf(a.toLowerCase()) > -1;
    }

    render() {
        let { showClientes, loadingInfo, isEditing, isEdit } = this.state;

        return loadingInfo ? (
            <Loader />
        ) : (
                <ContainerWrapper>
                    <div className="content-heading">
                        <legend style={{ marginLeft: "10px" }} className="styleTitleModule">
                            <em
                                // onClick={this.moreContact()}
                                className="icon-final-37"
                                style={{ color: "#020E3A" }}
                            ></em>
                        Administración
                    </legend>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#020e3a",
                            fontSize: "10px",
                            marginBottom: "5px"

                        }}>
                            <Icon.Group size='large'>
                                <Icon name='angle left' className='_btnBack' size='big'
                                    onClick={() => this.props.history.push('/entidades')}
                                />
                            </Icon.Group>
                            <h3 style={{
                                fontWeight: 'initial',
                                fontSize: "18px"
                            }} className='_btnBack'
                                onClick={() => this.props.history.push('/entidades')}>


                                Regresar
                        </h3>
                        </div>
                    </div>
                    <Card>
                        <CardHeader>
                            <div className="col-12">
                                <h3>Organizaciones</h3>
                                <hr className="hr-seacargo-red"></hr>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="col-12">
                                {this.state.camposUsuario ? (
                                    <div>
                                        <h4>Detalle de la Organización</h4>

                                        <div className="row">
                                            {/* <div className="col-12"> */}
                                            {this.state.createFormData}

                                            {/* </div> */}
                                        </div>
                                    </div>
                                ) : (
                                        <div>
                                            <div className="row">
                                                <div className="col-8">
                                                    <h4>

                                                        Detalle de la Organización

                                            </h4>
                                                </div>
                                                <div className="col-4">
                                                    {isEdit && !isEditing ? (
                                                        <em
                                                            style={{ fontSize: "25px", marginRight: "10px" }}
                                                            className="fas fa-pencil-alt clickable float-right"
                                                            onClick={() => this.cambiaEdicion()}
                                                        ></em>
                                                    ) : (
                                                            false
                                                        )}
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* <div className="col-12"> */}
                                                {this.state.generalInfo}

                                                {/* </div> */}
                                            </div>
                                        </div>
                                    )}

                            </div>
                            <div className="col-12" style={{ marginTop: "2%" }}>
                                <h4>

                                    Usuarios Asociados

                            </h4>
                            </div>
                            <div className="col-12" style={{ marginTop: "2%" }}>
                                {showClientes &&
                                    (!this.state.camposUsuario || (isEdit && isEditing)) ? (
                                        <>

                                            <Transfer
                                                style={{
                                                    marginTop: "25px",
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    justifyContent: "center",
                                                    height: "350px",
                                                }}
                                                locale={{
                                                    itemUnit: "Usuario",
                                                    itemsUnit: "Usuarios",
                                                    searchPlaceholder: "",
                                                }}
                                                dataSource={this.state.selectCatalog["Usuarios"]}
                                                showSearch
                                                filterOption={this.filterOption}
                                                onChange={this.handleChangeFilter(
                                                    "Users",
                                                    this.state.isEdit
                                                )}
                                                render={(item) => item.label}
                                                targetKeys={this.state.entityInfo["Users"]}
                                                operations={["Asociar", "Quitar"]}
                                            // showSelectAll={false}
                                            />
                                        </>
                                    ) : (
                                        <div></div>
                                    )}
                            </div>
                            {(!this.state.camposUsuario && !isEdit) || (isEdit && isEditing) ? (
                                <div
                                    className="col-12"
                                    style={{ marginTop: "15px", marginBottom: "15px" }}
                                >
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        variant="contained"
                                        color="primary"
                                        className="button float-right"
                                        onClick={() => this.confirmaGuardado()}
                                    >
                                        Guardar
                                </Button>
                                    {isEdit && isEditing ? (
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#020e3a",
                                                opacity: "80%",
                                            }}
                                            color="primary"
                                            className="button float-right"
                                            onClick={() => {
                                                this.cancelarEdicion();
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    ) : (
                                            false
                                        )}
                                    {!isEdit ? (
                                        <Button
                                            variant="contained"
                                            // disabled={
                                            //   !this.state.siguienteEnabled ? this.state.newSelected.length <= 0 : false
                                            // }
                                            style={{
                                                backgroundColor: "#020e3a",
                                                opacity: "80%",
                                            }}
                                            color="secondary"
                                            className="button float-right"
                                            onClick={() => {
                                                this.editarInformacionAlta();
                                            }}
                                        >
                                            Atras
                                        </Button>
                                    ) : (
                                            false
                                        )}
                                </div>
                            ) : (
                                    <></>
                                )}
                            {isEdit && !isEditing ? (
                                <div className="">
                                    <div className="col-12">{this.state.tablaUsuarios}</div>
                                </div>
                            ) : (
                                    false
                                )}
                        </CardBody>
                    </Card>
                </ContainerWrapper>
            );
    }
}

export default withRouter(createUsers);
