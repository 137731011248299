import React from "react";
import axios from "axios";
import "./styles.scss";
import imagenLogin from "./../../../resources/wallpaperSEA.gif";
import {
  Label,
  Container,
  Col,
  Form,
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
} from "reactstrap";
import { Services } from "./Services";
import logoSeaCargo from "./../../../resources/Logo-login.png";
import { withRouter } from "react-router-dom";
import { Input } from "semantic-ui-react";
const BackgroundHead = {
  height: "100%",
  backgroundImage: "url(" + imagenLogin + ")",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "calc(100vw + 48px)",
  // margin: -24,
  // padding: 24,
};
/*=============================================
=            Login Component                  =
=============================================*/
function Recover(props) {
  const [redirectlogin, setRedirectlogin] = React.useState({
    login: {
      newcontrasena: "",
      valid: false,
      confirmarcontrasena: "",
      check: false,
      email: "",
    },
  });
  let [Mostrar, setMostrar] = React.useState(false);
  let [exito, setexito] = React.useState(false);
  let [TokenValido, setTokenValido] = React.useState(true);

  const getAutentication = React.useCallback(() => {
    axios
      .get(
        `${Services.ADMIN.path}admin/recovery?token=${props.match.params.token}`,
        {}
      )
      .then((response) => {
        redirectlogin.login.email = response.data.email;
        setRedirectlogin({ redirectlogin, ...redirectlogin });
        setTokenValido(true);
      })
      .catch((error) => {
        setTokenValido(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.token]);

  React.useEffect(() => {
    getAutentication();
  }, []);

  function change(event) {
    document.getElementById("email_sin").setAttribute("hidden", "hidden");
    document.getElementById("pass_sin").setAttribute("hidden", "hidden");
    if (event.target.name === "confirmarcontrasena") {
      let value = event.target.value;
      if (redirectlogin.login.newcontrasena === value) {
        document
          .getElementById("pass_diferentes")
          .setAttribute("hidden", "hidden");
        document.getElementById("se_cumple").removeAttribute("hidden");
        document.getElementById("input_correo").className = "input100";
      } else {
        document.getElementById("pass_diferentes").removeAttribute("hidden");
        document.getElementById("input_correo").className = "invalidInput";
        document.getElementById("se_cumple").setAttribute("hidden", "hidden");
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    } else {
      let value = event.target.value;

      const emailRegex = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/
      );
      if (emailRegex.test(value)) {
        document.getElementById("input_email").className = "input100";
        document.getElementById("123456").setAttribute("hidden", "hidden");
      } else {
        document.getElementById("input_email").className = "invalidInput";
        document.getElementById("123456").removeAttribute("hidden");
      }
      redirectlogin.login[event.target.name] = value;
      setRedirectlogin({ redirectlogin, ...redirectlogin });
    }
  }

  function handleLoginId() {
    if (!redirectlogin.login.newcontrasena) {
      document.getElementById("email_sin").removeAttribute("hidden");
    } else if (!redirectlogin.login.confirmarcontrasena) {
      document.getElementById("pass_sin").removeAttribute("hidden");
    } else {
      document.getElementById("email_sin").setAttribute("hidden", "hidden");
      document.getElementById("pass_sin").setAttribute("hidden", "hidden");
      let data = {
        email: redirectlogin.login.email,
        password: redirectlogin.login.newcontrasena,
      };

      axios
        .put(`${Services.ADMIN.path}admin/changePass`, data)
        .then((response) => {
          document.getElementById("pass_changue").removeAttribute("hidden");
          setexito(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  return (
    <>
      <div style={BackgroundHead}>
        <div className="login-page ">
          <Container>
            {TokenValido ? (
              <div className="">
                {exito ? (
                  <Container>
                    <Card className="loginContainer">
                      <CardBody>
                        <label style={{ color: "#002297", fontSize: "22px",marginBottom:'2em' }}>
                          Contraseña actualizada correctamente
                        </label>{" "}
                        <div className="col-12 divcenter">
                            <div  className="col-3">
                            <Button
                              id="loginGo"
                              block
                              style={{ marginBottom: "1em" }}
                              className="login100-form-btn-blue"
                              // style={{ fontWeight: 'bold', background: '#FF0000' }}
                              // color='danger'
                              onClick={()=>props.history.push('/')}
                              // disabled={loading}
                            >
                              <strong>Ir a SCL Go</strong>
                            </Button>
                            </div>
                            </div>
                      </CardBody>
                    </Card>
                  </Container>
                ) : (
                  <>
                    <Col xs={12} md={8} lg={5} className="ml-auto mr-auto">
                      <Form>
                        <Card className="loginContainer">
                          <CardHeader>
                            <div
                              style={{ width: "71%" }}
                              className="logo-container"
                            >
                              <img
                                style={{ marginTop: "1em" }}
                                src={logoSeaCargo}
                                alt="now-logo"
                              />
                            </div>
                          </CardHeader>
                          <div className="logo-container2">
                            <span className={"tex_1"}>Tus embarques</span>{" "}
                            <span className={"tex_2"}>siempre cerca.</span>
                          </div>
                          <CardBody>
                            <Col
                              xs={11}
                              className="mr-auto ml-auto textInitial"
                            >
                              <label
                                style={{ color: "#002297", fontSize: "24px" }}
                              >
                                Recuperar Contraseña
                              </label>
                              <label
                                style={{ color: "#002297", fontSize: "11px" }}
                              >
                                La contraseña debe de estar compuesta por:
                              </label>
                              <label
                                style={{ color: "#002297", fontSize: "11px" }}
                              >
                                * Mínimo 8 dígitos y hasta máximo 15 dígitos.
                              </label>
                              <label
                                style={{ color: "#002297", fontSize: "11px" }}
                              >
                                * Al menos una letra mayúscula y una minúscula.
                              </label>
                              <label
                                style={{ color: "#002297", fontSize: "11px" }}
                              >
                                * Un carácter especial (*!$%&?).
                              </label>
                            </Col>
                            <br />
                            {/* Inputs */}
                            <Col xs={11} className="mr-auto ml-auto">
                              <Input
                                fluid
                                // className="input100"
                                id="input_email"
                                type={Mostrar ? "text" : "password"}
                                value={redirectlogin.login.newcontrasena}
                                name="newcontrasena"
                                onChange={change}
                                placeholder="Nueva contraseña"
                              />
                            </Col>
                            <span className="focus-input100"></span>
                            <br />
                            <Col xs={11} className="mr-auto ml-auto">
                              <Input
                                fluid
                                id="input_correo"
                                // className="input100"
                                type={Mostrar ? "text" : "password"}
                                value={redirectlogin.login.confirmarcontrasena}
                                name="confirmarcontrasena"
                                onChange={change}
                                placeholder="Confirmar contraseña"
                              />
                            </Col>
                            <span className="focus-input100"></span>

                            <div
                              style={{
                                padding: "3px",
                                // marginTop: "-14px",
                                color: "#FF0000",
                                fontSize: "12px",
                              }}
                              className="_alert"
                              id="email_sin"
                              hidden
                            >
                              Contraseña es obligatoria
                            </div>
                            <div
                              style={{
                                padding: "3px",
                                // marginTop: "-14px",
                                color: "#FF0000",
                                fontSize: "12px",
                              }}
                              className="_alert"
                              id="pass_sin"
                              hidden
                            >
                              Confirme la contraseña
                            </div>
                            <div
                              style={{
                                padding: "3px",
                                // marginTop: "-14px",
                                color: "#FF0000",
                                fontSize: "12px",
                              }}
                              className="_alert"
                              id="pass_diferentes"
                              hidden
                            >
                              Las Contraseñas deben ser iguales
                            </div>
                            <div
                              style={{
                                padding: "3px",
                                // marginTop: "-14px",
                                color: "#FF0000",
                                fontSize: "12px",
                              }}
                              className="_alert"
                              id="pass_changue"
                              hidden
                            >
                              Su contraseña ha sido cambiada exitosamente
                            </div>
                            <div
                              style={{
                                padding: "3px",
                                // marginTop: "-14px",
                                color: "#FF0000",
                                fontSize: "12px",
                              }}
                              className="_alert"
                              id="123456"
                              hidden
                            >
                              Contraseña inválida
                            </div>

                            <br />
                            <Col
                              style={{ textAlign: "initial" }}
                              xs={11}
                              className="mr-auto ml-auto"
                            >
                              <Label>
                                <Input
                                  type="checkbox"
                                  style={{
                                    marginRight: "8px",
                                    color: "#002297",
                                  }}
                                  onChange={() => {
                                    setMostrar(!Mostrar);
                                  }}
                                  checked={Mostrar}
                                />
                                <spam style={{ color: "#002297" }}>
                                  Mostrar
                                </spam>
                              </Label>
                            </Col>
                            <br />

                            {/* Buttom */}
                            <div className="col-12 divcenter">
                            <div  className="col-6">
                            <Button
                              id="se_cumple"
                              block
                              style={{ marginBottom: "1em" }}
                              hidden
                              className="login100-form-btn-blue"
                              // style={{ fontWeight: 'bold', background: '#FF0000' }}
                              // color='danger'
                              onClick={handleLoginId}
                              // disabled={loading}
                            >
                              <strong>Cambiar</strong>
                            </Button>
                            </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Form>
                    </Col>
                  </>
                )}
              </div>
            ) : (
              <Container>
                <Card className="loginContainer">
                  <CardHeader>
                    <center>
                      <div className="logo-container2">
                        <img src={logoSeaCargo} alt="now-logo" />
                      </div>
                      <div className="logo-container2">
                        <span className={"tex_1"}>Tus embarques</span>{" "}
                        <span className={"tex_2"}>siempre cerca.</span>
                      </div>
                    </center>
                  </CardHeader>
                  <CardBody>
                    <div style={{ height: "1em" }} className="col-12"></div>
                    <div className="col-12"></div>
                    <div style={{ height: "1em" }} className="col-12"></div>
                    <label style={{ color: "#002297", fontSize: "20px" }}>
                      Error: "El token ya se ha utilizado o ha caducado"{" "}
                    </label>
                    <div style={{ height: "1em" }} className="col-12"></div>
                  </CardBody>
                </Card>
              </Container>
            )}
          </Container>
        </div>
        <div className="col-12" style={{ height: "5em" }}></div>
      </div>
    </>
  );
}
// export default withRouter(Recover);
export default Recover;
