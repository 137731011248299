import React from 'react';
import { Typography, Spin } from 'antd';
import { Item, Icon } from 'semantic-ui-react';

import ItemFeed from './../RSSCard';
import useFeeds from './../../hooks/useFeeds';
import './styles.scss';
const { Title } = Typography;

/**
 * Scroll feeds component
 * @param {String} apiPath - data path
 * @param {String} title - Title for feeds scroll
 */
function ScrollFeeds({apiPath, title}){
  const [pageNumber, setPageNumber] = React.useState(1);
  const { loading, error, feeds, hasMore, details} = useFeeds(pageNumber, apiPath);

  /**
   * Scroll Handler Function
   */
  function handleScroll(evt){
    const { scrollTop, clientHeight, scrollHeight} = evt.currentTarget;
    if(scrollHeight -  scrollTop === clientHeight && loading === false && hasMore === true){
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
  }

  return (
    <React.Fragment>
      <Title level={4}>{title}</Title>
      <div className='statistics-container'>
        <Item.Meta className='statistics-item'>
          <div style={{marginLeft:"10px"}}>
            {/* <Icon name='newspaper outline' /> */}
            Noticias: {details.total}
          </div>
          {/* <div>
            <Icon name='hashtag' />Paginas: {details.pages}
          </div> */}
        </Item.Meta>
      </div>
      <div
        onScroll={handleScroll}
        className='feeds-container'>
        {
          feeds.map((feed, key) => {
            return (
              <ItemFeed key={key} feed={feed}/>
            );
          })
        }
        <div style={{textAlign: 'center', padding: '2em'}}>
          {
            loading ?  <Spin />: ''
          }
        </div>
        <div>{error && 'Error...'}</div>
        {/* <div ref={lastBookElementRef}>{loading && 'Loading ...'}</div> */}
      </div>
    </React.Fragment>
  );
}
export default ScrollFeeds;