export const Services = {
   "ADMIN": {
      "path": "https://seacargoapiadmin.azurewebsites.net/",
      "method": "",
      "descrp": "API TO HANDLE ADMIN"
   },
   "MAIL": {
      "path": "https://seacargoapimail.azurewebsites.net/",
      "method": "",
      "descrp": "API TO HANDLE MAIL"
   }
}