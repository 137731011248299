import React, { Component, Suspense } from "react";

import moment from "moment";
import ContentWrapper from "./../../../template/Layout/ContentWrapper";
import _ from "lodash";
import { message } from "antd";
import { Tab } from "semantic-ui-react";
import Documents from "../../Components/Documents";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import axios from "axios";
import { Services } from "../Services";
import Authentication from "../../../../services/Authentication";
import { withTranslation } from "react-i18next";
import { validateIdParams } from "../../../utils/regularExpressionValidation";
const Events = React.lazy(() => import("./Events"));
const SumaryDetail = React.lazy(() => import("./Sumary/SumaryDetail"));
const auth = new Authentication();
const configRealMarkers = [
  {
    label: "Embarcación",
    key: "Vessel",
  },
  {
    label: "IMO",
    key: "IMO",
  },
  {
    label: "MMSI",
    key: "MMSI",
  },
  {
    label: "Latitud",
    key: "LAT",
  },
  {
    label: "Longitud",
    key: "LON",
  },
  {
    label: "Viaje",
    key: "Voyage_FlightNumber",
  },
  {
    label: "Puerto actual",
    key: "CURRENT_PORT",
  },
  {
    label: "Último puerto",
    key: "LAST_PORT",
  },
  {
    label: "ATD",
    key: "LAST_PORT_TIME",
  },
  {
    label: "Est. llegada",
    key: "ETA_CALC",
  },
  {
    label: "Próximo puerto",
    key: "NEXT_PORT_NAME",
  },
  {
    key: "CreateDate",
    label: "Actualización localización",
  },
];
//TEST Eliminar
// const documentos = [{
//   'Name': 'Name',
//   'Type': 'Type',
//   'Descripcion': 'Descripcion',
//   'CreationDate': '2021/12/12',
//   'CreateBy': '2021/12/12',
//   'URL': '',
//   'DocumentKeyAsString': ''
// },
// {
//   'Name': 'Name',
//   'Type': 'Type',
//   'Descripcion': 'Descripcion',
//   'CreationDate': '2021/12/12',
//   'CreateBy': '2021/12/12',
//   'URL': '',
//   'DocumentKeyAsString': ''
// },
// {
//   'Name': 'Name',
//   'Type': 'Type',
//   'Descripcion': 'Descripcion',
//   'CreationDate': '2021/12/12',
//   'CreateBy': '2021/12/12',
//   'URL': '',
//   'DocumentKeyAsString': ''
// }]
class ShipmentDetail extends Component {
  state = {
    id: this.props.match.params.id,
    rolId: auth.getAuthentication("RolId"),
    userId: auth.getAuthentication("dataUser"),
    allSteps: [],
    infoGeolocation: { realMarkers: [] },
    allShipmentInfo: {},
    documentos: [],
    commentsData: [
      {
        ComType: "El embarque ha sido marcado como retrasado",
        ComTypeId: 1,
        Comment: "Sistema",
        CommentId: 73,
        Date: "2019-06-20 AM",
        Module: "Retraso del Embarque",
        ModuleId: 1,
        RowId: 861,
        UpdateBy: "API",
      },
      {
        ComType: "El embarque ha sido marcado como retrasado",
        ComTypeId: 1,
        Comment: "Sistema",
        CommentId: 73,
        Date: "2019-07-02 AM",
        Module: "Comentario",
        ModuleId: 1,
        RowId: 861,
        UpdateBy: "API",
      },
    ],
    catalogsData: [
      {
        ComTypeId: 1,
        ModuleId: 1,
        ComType: "Supplier Follow Up",
        value: 1,
        label: "Supplier Follow Up",
      },
      {
        ComTypeId: 2,
        ModuleId: 1,
        ComType: "Order Quality",
        value: 2,
        label: "Order Quality",
      },
      {
        ComTypeId: 3,
        ModuleId: 1,
        ComType: "Order Quantities",
        value: 3,
        label: "Order Quantities",
      },
      {
        ComTypeId: 10,
        ModuleId: 1,
        ComType: "Other",
        value: 10,
        label: "Other",
      },
    ],
    stepsIMPO: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 3,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 4,
        idStep: "step4",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Despachado",
        value: "",
        id: 19,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Entregado",
        value: "",
        id: 23,
        completed: false,
        order: 6,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 7,
      //   idStep: 'step6',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    stepsEXPO: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Despachado",
        value: "",
        id: 10,
        completed: false,
        order: 3,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 4,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 6,
      //   idStep: 'step6',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    stepsDOMESTICO: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 3,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 4,
        idStep: "step4",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Entregado",
        value: "",
        id: 23,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 6,
      //   idStep: 'step5',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
    stepsTERRESTRE: [
      {
        label: "Reservado",
        value: "",
        id: 2,
        completed: false,
        order: 1,
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Recolectado",
        value: "",
        id: 7,
        completed: false,
        order: 2,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Salió",
        value: "",
        id: 11,
        completed: false,
        order: 3,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Cruce de frontera",
        value: "",
        id: 13,
        completed: false,
        order: 4,
        middle: false,
        idStep: "",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },

      {
        label: "En tránsito a destino",
        value: "",
        id: 15,
        completed: false,
        order: 5,
        idStep: "step5",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Llegó",
        value: "",
        id: 17,
        completed: false,
        order: 6,
        idStep: "step6",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      {
        label: "Despachado",
        value: "",
        id: 20,
        completed: false,
        order: 7,
        idStep: "step7",
        middleStep: "",
        classValue: "subTitleCard",
        classTittle: "statusStepTittle",
      },
      // ,
      // {
      //   label: 'Facturado',
      //   value: '',
      //   id: 24,
      //   completed: false,
      //   order: 8,
      //   idStep: 'step8',
      //   middleStep: '',
      //   classValue: "subTitleCard",
      //   classTittle: "statusStepTittle"
      // }
    ],
  };

  componentDidMount = () => {
    // const query = new URLSearchParams(this.props.history);
    const query = queryString.parse(this.props.location.search);

    this.getShipmentsForId();
  };
  obtenerSteps = (shipment) => {
    var steps = [];
    if (
      shipment.TransportModeCode === "A" ||
      shipment.TransportModeCode === "O"
    ) {
      if (shipment.DirectionCode === "I") {
        steps = this.state.stepsIMPO;
      } else if (
        shipment.DirectionCode === "E" ||
        shipment.DirectionCode == "R"
      ) {
        steps = this.state.stepsEXPO;
      } else if (shipment.DirectionCode === "D") {
        steps = this.state.stepsDOMESTICO;
      }
    } else {
      steps = this.state.stepsTERRESTRE;
    }
    return steps;
  };
  componentWillReceiveProps = (nextProps) => {
    const query = new URLSearchParams(nextProps.history);

    const newProp = nextProps.match.params.id
      ? nextProps.match.params.id
      : this.props.match.params.id;
    this.setState({ id: newProp }, () => {
      this.getShipmentsForId();
    });
  };

  getEvents = () => {
    const { id, allShipmentInfo } = this.state;
    let moduleId = 3;
    // let idContainer = 1816;
    axios
      .get(`${Services.TRACEABILITY.path}trazabilidad/shipmentevents/${id}`)
      .then((response) => {
        let update = false;
        for (let index = response.data.steps.length; index >= 0; index--) {
          const element = response.data.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = this.obtenerSteps(allShipmentInfo.infoEmbarque);
        let dataEvents = response.data.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === "1") {
                f.completed = true;
              }
              // else {
              //   findNext = false;
              // }
            }
            // else {
            //   findNext = false;
            // }
            // if (findNext) {
            //   f.completed = true;
            // }
            return e;
          });
          return f;
        });
        newSteps.sort(function (a, b) {
          return b.order - a.order;
        });
        let findTrue = false;
        newSteps.map((e) => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function (a, b) {
          return a.order - b.order;
        });
        console.log("newSteps", newSteps);
        this.setState({ steps: newSteps, allSteps: dataEvents });
      })
      .catch((error) => {});
  };

  handleBack = () => {};

  getShipmentsForId = () => {
    let idValidate = validateIdParams(this.state.id);
    if (!idValidate) {
      this.sendMessage(this.props.t("Data_entered_is_invalid"), "error");
      setTimeout(() => {
        this.props.history.push("/trazabilidad");
      }, 2000);
      return;
    }
    axios
      .get(
        `${Services.TRACEABILITY.path}trazabilidad/dataShipments/${this.state.id}`
      )
      .then((success) => {
        let commentsData = [];
        let data = {
          ComType: "",
          ComTypeId: 1,
          Comment: success.data.data.infoEmbarque.Observaciones,
          CommentId: "",
          Date: success.data.data.infoEmbarque.Observaciones
            ? success.data.data.infoEmbarque.ETA
            : "",
          Module: "Comentario",
          ModuleId: 1,
          RowId: "",
          UpdateBy: "API",
        };
        commentsData.push(data);
        let Info = [];
        Info.push(success.data.data.infoEmbarque);

        //LIMITAR DOCUMENTOS POR ROL
        let Documents = [];
        if (
          success.data.data.Documentos &&
          success.data.data.Documentos.length > 0
        ) {
          if (this.state.rolId == 4) {
            success.data.data.Documentos.map((doc) => {
              if (doc.VisibleCustomer == 1) {
                Documents.push(doc);
              }
            });
          } else {
            Documents = success.data.data.Documentos;
          }
        }
        this.setState(
          {
            infoGeolocation: {
              geolocation: success.data.data.infoEmbarque.Geolocation == "1",
              ActiveGeolocation:
                success.data.data.infoEmbarque.ActivateGeolocation == "1",
              ...this.state.infoGeolocation,
            },
            // commentsData,
            documentos: Documents,
            allShipmentInfo: success.data.data,
          },
          async () => {
            this.getEvents();
            await this.validateShowGeolocationButtons(
              this.state.allShipmentInfo.infoEmbarque
            );
          }
        );
      })
      .catch((error) => {
        console.warn(error);
        this.sendMessage(this.props.t("Data_entered_is_invalid"), "error");
        setTimeout(() => {
          this.props.history.push("/trazabilidad");
        }, 2000);
        return;
      });
  };

  sendMessage = (text, type) => {
    var messageClass = "";
    if (type === "error") {
      messageClass = "error-custom-ant-message";
    } else if (type === "success") {
      messageClass = "general-custom-ant-message success-custom-ant-message";
    } else if (type === "info") {
      messageClass = "info-custom-ant-message";
    }
    message.open({
      content: text,
      className: messageClass,
    });
  };

  validateShowGeolocationButtons = async (info) => {
    let { infoGeolocation, showGeolocalizationMarkers } = this.state;
    //Solo el rol de admin puede actualizar
    //SU puede visualizar el boton de activar y desactivar geolocalizacion , ademas solo admin y SU pueden ver esta funcionalidad

    if (
      info.TransportMode === "Ocean" &&
      infoGeolocation.ActiveGeolocation &&
      infoGeolocation.geolocation &&
      [1, 2, 3, 4, 5].indexOf(parseInt(this.state.rolId)) > -1
    ) {
      console.log("adentro");
      showGeolocalizationMarkers = true;
      this.setState({
        infoGeolocation: {
          showGeolocalizationMarkers,
          ...this.state.infoGeolocation,
        },
      });
      await this.obtainRealMarkers(true);
    }
  };

  generateRandomDate = (from, to) => {
    return new Date(
      from.getTime() + Math.random() * (to.getTime() - from.getTime())
    );
  };
  obtainRealMarkers = async (firstTime) => {
    let { infoGeolocation, canUpdateGeolocation, allShipmentInfo } = this.state;
    try {
      let shipmentInfo = allShipmentInfo.infoEmbarque;
      let { realMarkers, FechaGeoActualizacion } = infoGeolocation;
      let respAxios = await axios.get(
        `${Services.TRACEABILITY.path}geolocalization/currentLocation/${this.state.id}`
      );

      let dataRealMarker = respAxios.data.data;
      canUpdateGeolocation = respAxios.data.canUpdate;
      if (dataRealMarker && dataRealMarker.length > 0) {
        if (
          !shipmentInfo.ATA &&
          shipmentInfo.ATD &&
          shipmentInfo.ATD != "" &&
          firstTime
        ) {
          if (dataRealMarker[0].Count >= 10) {
            this.sendMessage(
              `Se ha excedido el número máximo de consultas de geolocalización de su embarque, para conocer el status actual de su embarque, por favor contacte a su ejecutivo de servicio 
            al cliente`,
              "error"
            );
          } else {
            this.refreshRealMarkers();
          }
        }
        FechaGeoActualizacion = dataRealMarker[0].LastUpdateDate;

        //Es el id de la geolocalizacion
        let id = 1;
        let positionsTmp = [];
        //

        realMarkers.positions = dataRealMarker
          .filter(function (dataReal) {
            let add = true;
            positionsTmp.forEach((element) => {
              if (element.LAT == dataReal.LAT && element.LON == dataReal.LON) {
                add = false;
              }
            });

            if (add) {
              positionsTmp.push({ LAT: dataReal.LAT, LON: dataReal.LON });
            }
            return add;
          })
          .map((dataReal) => {
            //Si ha arribado no es necesario mostrar tooltip
            if (shipmentInfo.ATA && shipmentInfo.ATA != "") {
              dataReal.GeolocationId = 0;
            }
            return {
              id: id++,
              data: dataReal,
              position: {
                lat: parseFloat(dataReal.LAT),
                lng: parseFloat(dataReal.LON),
              },
            };
          });
        realMarkers.config = configRealMarkers;
        realMarkers.icon = {
          url: "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Maritimo-Mapa[1].png",
        };
        realMarkers.anchorX = 30;
        realMarkers.anchorY = 45;
        realMarkers.tittle = "Actual";
      }
      this.setState({
        infoGeolocation: {
          ...this.state.infoGeolocation,
          realMarkers,
          canUpdateGeolocation,
          FechaGeoActualizacion,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  refreshRealMarkers = async () => {
    try {
      let respAxios = await axios.get(
        `${Services.TRACEABILITY.path}geolocalization/updateLocation/${this.state.id}?userid=${this.state.userId}`
      );
      if (respAxios.data.status === "SUCCESS") {
        if (respAxios.data.message != "noupdate") {
          this.obtainRealMarkers(false);
          this.sendMessage(
            "Se actualizó la localización correctamente",
            "success"
          );
        }
      } else {
        this.sendMessage(this.props.t(respAxios.data.message), "error");
      }
    } catch (ex) {
      this.sendMessage(this.props.t(ex.response.data.message), "error");
    }
  };

  render() {
    let logoUrl =
      this.state.ModoTransporte === "AEREA"
        ? "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Aereo-Mapa[1].png"
        : this.state.ModoTransporte === "MARITIMA"
        ? "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Maritimo-Mapa[1].png"
        : "https://fsposeidon.blob.core.windows.net/seacargopic/Transporte-Terrestre-Mapa[1].png";
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading header-seacargo">
            <legend className="styleTitleModule">
              <em
                // onClick={this.moreContact()}
                className="icon-final-02"
                style={{ color: "#020E3A" }}
              ></em>
              Detalle del Embarque
            </legend>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#020e3a",
                fontSize: "10px",
                marginBottom: "5px",
              }}
            >
              <Icon.Group size="large">
                <Icon
                  name="angle left"
                  className="_btnBack"
                  size="big"
                  onClick={() => this.props.history.push("/trazabilidad")}
                />
              </Icon.Group>
              <h3
                style={{
                  fontWeight: "initial",
                  fontSize: "18px",
                }}
                className="_btnBack"
                onClick={() => this.props.history.push("/trazabilidad")}
              >
                Regresar
              </h3>
            </div>
          </div>
        </div>
        <Tab
          menu={{ secondary: true, className: "myWrapped", pointing: true }}
          menuPosition="right"
          panes={[
            {
              menuItem: "Resumen",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <SumaryDetail
                    steps={this.state.steps}
                    allShipmentInfo={this.state.allShipmentInfo}
                    infoGeolocation={this.state.infoGeolocation}
                  />
                </Suspense>
              ),
            },
            {
              menuItem: "Eventos",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Events
                    cliente={this.state.cliente}
                    NIT={this.state.NIT}
                    Grupo={this.state.Grupo}
                    logoUrl={logoUrl}
                    DoEmbarque={this.state.DoEmbarque}
                    HBL_HAWB={this.state.HBL_HAWB}
                    Estatus={this.state.Estatus}
                    allSteps={this.state.allSteps}
                    steps={this.state.steps}
                  />{" "}
                </Suspense>
              ),
            },
            // {
            //   menuItem: "Comentarios",
            //   render: () => (
            //     <div>
            //       <Comments
            //         data={this.state.commentsData}
            //         catalogs={this.state.catalogsData}
            //       />
            //     </div>
            //   ),
            // },
            {
              menuItem: "Documentos",
              render: () => (
                <div>
                  <Documents
                    dataDoc={this.state.documentos}
                    rolId={1}
                    shipment=""
                    shipmentDataPlane=""
                  />
                </div>
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}
export default withTranslation()(withRouter(ShipmentDetail));
