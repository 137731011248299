import React, { Component, Suspense } from "react";
import { Grid, Label } from "semantic-ui-react";
import "./styles/cards.scss";
import { searchBy } from "./search";
import axios from "axios";
import { Icon, Pagination, Search } from "semantic-ui-react";
import Select from "react-select";

import {
  Button,
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { saveAs } from "file-saver";
import moment from "moment";
import IMG from "../../../../resources/filter.png";
import { Dropdown } from "semantic-ui-react";
import { DatePicker } from "antd";
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Services } from './../../Reporting&Analitics/Services';
const { RangePicker } = DatePicker;
const CardData = React.lazy(() => import("./card"));

const urlDinamicReport =
  `${Services.DOWNLOAD.path}report/dynamic/xlsx`;

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValues: [],
      data: [],
      isOpen: false,
      loaderExcel: false,
      cards: "",
      search: "",
      toggleDrawer: this.props.toggleDrawer,
      resetCards: this.props.reset,
      labelStatus: this.props.labelStatus,
      dataSearch: this.props.dataSearch,
      activePage: 1,
      totalPages: 0,
      infoCard: false,
      advancedSearchData: this.props.advancedSearchData
        ? this.props.advancedSearchData
        : [],
      resetAdvance: this.props.resetAdvance ? this.props.resetAdvance : ""
    };
  }

  componentDidMount = () => {
    if (this.props.cardInfon) {
      this.setState(
        {
          data: this.props.data,
          infoCard: true,
        },
        () => this.buildCards()
      );
    } else {
      this.setState(
        {
          data: this.props.data,
          dataSearch: [...this.props.data],
          allMyData: [...this.props.data],
        },
        () => this.initPages()
      );
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.data)
      this.setState(
        {
          data: nextProps.data,
          dataSearch: nextProps.data,
          allMyData: nextProps.data,
        },
        () => {
          this.initPages();
        }
      );
    if (nextProps.advancedSearchData) {
      this.setState({ advancedSearchData: nextProps.advancedSearchData });
    }
    if (nextProps.labelStatus)
      this.setState({ labelStatus: nextProps.labelStatus }, () => {
        this.buildCards();
        try {
          let selectedFileDoom = document.getElementById(nextProps.labelStatus);
          selectedFileDoom.classList.add("_cardSelected");
        } catch (e) { }
      });
  };

  initPages = () => {
    let pages = Math.ceil(parseInt(this.state.dataSearch.length) / 5);
    if (pages === 0) pages = 1;
    this.setState(
      {
        activePage: 1,
        totalPages: pages,
      },
      () => {
        this.buildPageResults();
      }
    );
  };

  buildPageResults = () => {
    let { activePage, totalPages } = this.state;
    let data = this.setResultsByPage(
      activePage,
      this.state.dataSearch,
      totalPages,
      10
    );
    this.setState(
      {
        data,
      },
      () => {
        this.buildCards();
      }
    );
  };

  setResultsByPage = (page, all, pages, itemsByPage) => {
    page = page - 1;
    const pag = all.slice(page * itemsByPage, (page + 1) * itemsByPage);
    return pag;
  };

  buildCards = () => {
    let { data } = this.state;
    let tags = data.map((e, index) => {
      return (
        <div
          key={index}
          className={this.props.config.length > 12 ? "" : ""}
          onClick={this.props.cardInfon ? () => { } : this.state.toggleDrawer(e)}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <CardData
              className={this.props.config.length > 12 ? "" : ""}
              CardIndoHeaders={this.props.CardIndoHeaders}
              infoCard={this.state.infoCard}
              key={index}
              config={this.props.config}
              data={e}
              element={e}
            ></CardData>
          </Suspense>
        </div>
      );
    });
    this.setState(
      {
        cards: tags,
      },
      () => this.setState({ cards: tags })
    );
  };

  // handleChange = (name) => (event) => {
  //   if (this.props.newSearch) {
  //     this.props.newSearch(event.target.value);
  //   } else {
  //     let { allMyData } = this.state;
  //     this.setState(
  //       {
  //         [name]: event.target.value,
  //       },
  //       () => {
  //         if (this.state.search === '') {
  //           this.setState(
  //             {
  //               dataSearch: allMyData,
  //             },
  //             () => this.initPages()
  //           );
  //         } else {
  //           let results = searchBy(
  //             this.state.search,
  //             allMyData,
  //             this.props.config
  //           );
  //           this.setState(
  //             {
  //               dataSearch: results,
  //             },
  //             () => this.initPages()
  //           );
  //         }
  //       }
  //     );
  //   }
  // };

  // handleChange = (name) => (event) => {
  //   let { allMyData } = this.state;
  //   this.setState(
  //     {
  //       [name]: event.target.value,
  //     },
  //     () => {
  //       if (this.state.search === "") {
  //         this.setState(
  //           {
  //             dataSearch: allMyData,
  //           },
  //           () => this.initPages()
  //         );
  //       } else {
  //         let results = searchBy(this.state.search, allMyData);
  //         this.setState(
  //           {
  //             dataSearch: results,
  //           },
  //           () => this.initPages()
  //         );
  //       }
  //     }
  //   );
  // };

  handleChange = (name) => (event, value) => {
    if (this.props.newSearch) {
      this.setState({ [name]: value.value }, () => this.props.newSearch(value.value));
    } else {
      let { allMyData } = this.state;
      this.setState(
        {
          [name]: event.target.value,
        },
        () => {
          if (this.state.search === '') {
            this.setState(
              {
                dataSearch: allMyData,
              },
              () => this.initPages()
            );
          } else {
            let results = searchBy(
              this.state.search,
              allMyData,
              this.props.config
            );
            this.setState(
              {
                dataSearch: results,
              },
              () => this.initPages()
            );
          }
        }
      );
    }
  };

  changePage = (page) => {
    this.setState(
      {
        activePage: page,
      },
      () => {
        this.buildPageResults();
      }
    );
  };

  handlAdvancedSearch = (search) => {
    this.props.backRes(search);
    this.setState({ isOpen: false });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSearchChange = (e, i) => {
    this.setState({ search: i.value });
  };

  splitArray = (list, howMany) => {
    var idx = 0;
    var result = [];

    while (idx < list.length) {
      if (idx % howMany === 0) result.push([]);
      result[result.length - 1].push(list[idx++]);
    }
    return result;
  };

  callGenerate = (objPost) => {
    if (objPost.options.headers.length > 0) {
      axios
        .post(urlDinamicReport, objPost)
        .then((success) => {
          if (success.data.base64) {
            let fileName;
            this.props.nameXlsx
              ? (fileName = this.props.nameXlsx)
              : (fileName =
                Math.floor(Math.random() * 900000) +
                100000 +
                Math.floor(Date.now() / 1000) +
                ".xlsx");

            const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
            saveAs(linkSource, fileName);
            this.setState({ loaderExcel: false });
          } else console.warn("Error downloading file", success);
        })
        .catch((error) => {
          console.error("errorr", error);
          this.setState({ loaderExcel: false });
        });
    }
  };

  generateExcel = () => {
    this.setState({ loaderExcel: true });
    let { data } = this.state;
    let headers = [];
    let keys = [];
    this.props.config.map((e) => {
      if (e.xls) {
        if (e.first) {
          if (e.firstExpo) {
            headers.push("Embarque");
            keys.push("DoEmbarque");
            // headers.push("Documento de Transporte");
            keys.push("DocTransporte");
          } else {
            headers.push("Embarque");
            keys.push("DoEmbarque");
            // headers.push("Documento de Transporte");
            keys.push("HBL_HAWB");
          }
        } else {
          if (e.titleId) {
            headers.push(e.titleId);
          } else {
            headers.push(e.title);
          }
          if (e.idExcel) {
            keys.push(e.idExcel);
          } else {
            keys.push(e.id);
          }
        }
      }
      return e;
    });
    if (this.props.urlReport) {
      axios
        .post(this.props.urlReport, this.props.paramsxml)
        .then((response) => {
          if (response.data.data) {
            if (response.data.data.length > 10000) {
              let obj = {
                data: response.data.data.splice(0, 10000),
                options: {
                  headers: headers,
                  keys: keys,
                  image: {
                    name: "seacargo",
                  },
                },

                info: {
                  config: {
                    title: "Los datos presentados son aproximados y pueden variar sin previo aviso. Para mayor información contacte a Sea Cargo Logistics.",
                    titlePosition: {
                      col: 3,
                      row: 5

                    }

                  }
                }
              };
              this.callGenerate(obj);
            } else {
              let obj = {
                data: response.data.data,
                options: {
                  headers: headers,
                  keys: keys,
                  image: {
                    name: "seacargo",
                  },
                },
                info: {
                  config: {
                    title: "Los datos presentados son aproximados y pueden variar sin previo aviso. Para mayor información contacte a Sea Cargo Logistics.",
                    titlePosition: {
                      col: 3,
                      row: 5

                    }

                  }
                }
              };
              this.callGenerate(obj);
            }
          }
        })
        .catch((error) => {
          console.error("errorr", error);
          this.setState({ loaderExcel: false });
        });
    } else {
      let obj = {
        data: data,
        options: {
          headers: headers,
          keys: keys,
          image: {
            name: "seacargo",
          },
          info: {
            config: {
              title: "Los datos presentados son aproximados y pueden variar sin previo aviso. Para mayor información contacte a Sea Cargo Logistics.",
              titlePosition: {
                col: 3,
                row: 5

              }

            }
          }
        },
      };
      if (headers.length > 0) {
        axios
          .post(urlDinamicReport, obj)
          .then((success) => {
            if (success.data.base64) {
              let fileName;
              this.props.nameXlsx
                ? (fileName = this.props.nameXlsx)
                : (fileName =
                  Math.floor(Math.random() * 900000) +
                  100000 +
                  Math.floor(Date.now() / 1000) +
                  ".xlsx");

              const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
              saveAs(linkSource, fileName);
              this.setState({ loaderExcel: false });
            } else console.warn("Error downloading file", success);
          })
          .catch((error) => {
            console.error("errorr", error);
            this.setState({ loaderExcel: false });
          });
      }
    }
  };

  toggle = (evt) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getAdvancedData = (e, index, firstIndex) => {
    return (
      <Row
        style={{
          justifyContent: e.length === 1 && e[0].keyUnikey ? "center" : "",
        }}
        key={index}
      >
        {e.map((f, index1) => {
          return this.getFilterData(f, index1, index, firstIndex);
        })}
      </Row>
    );
  };



  resetData = () => {
    let newAdvanced = this.state.advancedSearchData;
    newAdvanced.map((e) => {
      e.data.map((f) => {
        f.map((g) => {
          if (g.type === "dateRange") {
            g.date = {
              init: "",
              end: "",
            };
          } else {
            g.value = "";
          }
          return g;
        });
        return f;
      });
      return e;
    });
    this.setState({ advancedSearchData: newAdvanced });
  };

  findAdvanced = () => {

    let obj = [];
    this.state.advancedSearchData.map((e) => {
      e.data.map((f) => {

        f.map((g) => {
          if (!g.undefined) {
            let newPush = {
              id: g.id,
              UseIN: g.UseIN ? g.UseIN : false,
              isDate: g.type === "dateRange" ? true : false,
              subconsulta: g.subconsulta ? g.subconsulta : false,
              value:
                g.type === "dateRange"
                  ? g.date
                  : g.type === "select"
                    ? g.value
                      ? g.value.value
                      : ""
                    : g.value,
            };
            obj.push(newPush);
          }
          return g;
        });
        return f;
      });
      return e;
    });
    console.log("advanced.obj", obj)
    this.props.advancedSearch(obj);
    this.toggle();
  };

  handleOnChangeData = (evt, { value }) => {
    this.setState({ currentValues: value }, () => { });
  };

  getFilterData = (e, index, secondIndex, firstIndex) => {
    switch (e.type) {
      case "text":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.title} {e.undefined ? "!" : ""}

            </label>
            <InputGroup>
              <Input
                type="text"
                value={e.value}
                name={e.title}
                onChange={(e) => {
                  let newAdvanced = this.state.advancedSearchData;
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (
                          newAdvanced[firstIndex].data[secondIndex][index]
                        ) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].value = e.target.value;
                          this.setState({ advancedSearchData: newAdvanced });
                        }
                      }
                    }
                  }
                }}
              />
            </InputGroup>
          </div>
        );
      case "select":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.title} {e.undefined ? "!" : ""}

            </label>
            <Select
              placeholder=""
              value={e.value}
              options={e.values}
              onChange={(value) => {
                let newAdvanced = this.state.advancedSearchData;
                if (newAdvanced[firstIndex]) {
                  if (newAdvanced[firstIndex].data) {
                    if (newAdvanced[firstIndex].data[secondIndex]) {
                      if (newAdvanced[firstIndex].data[secondIndex][index]) {
                        newAdvanced[firstIndex].data[secondIndex][
                          index
                        ].value = value;
                        this.setState({ advancedSearchData: newAdvanced });
                      }
                    }
                  }
                }
              }}
            />
          </div>
        );
      case "multipleSelect":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.title} {e.undefined ? "!" : ""}

            </label>
            <Dropdown
              className="NewClass"
              onChange={(evt, { value }) => {
                let newAdvanced = this.state.advancedSearchData;
                if (newAdvanced[firstIndex]) {
                  if (newAdvanced[firstIndex].data) {
                    if (newAdvanced[firstIndex].data[secondIndex]) {


                      if (newAdvanced[firstIndex].data[secondIndex][index]) {

                        newAdvanced[firstIndex].data[secondIndex][
                          index
                        ].value = value;
                        this.setState({
                          advancedSearchData: newAdvanced,
                          currentValues: value,
                        });
                      }
                    }
                  }
                }
              }}
              id={e.id}
              options={e.values}
              clearable
              search
              selection
              fluid
              value={e.value}
              multiple
              allowAdditions
            />
          </div>
        );
      case "date":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.title} {e.undefined ? "!" : ""}
            </label>
            <InputGroup>
              <Input
                type="date"
                name={e.title}
                onChange={(e) => {
                  let newAdvanced = this.state.advancedSearchData;
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (
                          newAdvanced[firstIndex].data[secondIndex][index]
                        ) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].value = e.target.value;
                          this.setState({ advancedSearchData: newAdvanced });
                        }
                      }
                    }
                  }
                }}
              />
            </InputGroup>

          </div>
        );


      case "dateRange":
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            {/* <center> */}
            <label className="labelTitleAvancSearc">
              {e.title} {e.undefined ? "!" : ""}
            </label>
            {/* </center> */}
            {/* <div className="row"> */}

            {/* <div className="col-6"> */}
            <RangePicker
              locale={locale}
              onChange={(event) => {
                let newAdvanced = this.state.advancedSearchData;

                if (event === null) {
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.init = "";
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.end = "";
                          this.setState({
                            advancedSearchData: newAdvanced,
                            [e.id]: event,
                          });
                        }
                      }
                    }
                  }
                } else {
                  let init = event[0].format("YYYY-MM-DD");
                  let end = event[1].format("YYYY-MM-DD");
                  if (newAdvanced[firstIndex]) {
                    if (newAdvanced[firstIndex].data) {
                      if (newAdvanced[firstIndex].data[secondIndex]) {
                        if (newAdvanced[firstIndex].data[secondIndex][index]) {
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.init = init;
                          newAdvanced[firstIndex].data[secondIndex][
                            index
                          ].date.end = end;
                          this.setState({
                            advancedSearchData: newAdvanced,
                            [e.id]: event,
                          });
                        }
                      }
                    }
                  }
                }
              }}
              value={
                this.state[e.id] === undefined || this.state[e.id] === null
                  ? ""
                  : [
                    moment(new Date(this.state[e.id][0])),
                    moment(new Date(this.state[e.id][1])),
                  ]
              }
              style={{
                height: "2.6em",
                borderRadius: ".3em",
                borderColor: "#CCCCCC",
              }}
              size="large"
/* Creating a div with a class of col-md-12. */
              className="col-md-12"
            />

          </div>
        );

      default:
        return (
          <div
            key={index}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.title} {e.undefined ? "!" : ""}

            </label>
            <InputGroup>
              <Input type="text" name={e.title} />
            </InputGroup>
          </div>
        );
    }
  };

  render() {
    let { activePage, totalPages, search, resetCards, labelStatus, resetAdvance } = this.state;
    let cant = 0;
    if (this.props.config) {
      this.props.config.map((e, index) => {
        if (e.hide) {
          return false;
        }
        cant++;
      });
    }

    return (
      <Row>
        {this.props.cardInfon || this.props.broswer === false ? (
          ""
        ) : (
          // <div className="col-12">
          <Col xs={12} md={12} lg={12}>
            {/* <Grid style={{ paddingBottom: "2%" }}> */}
            {/* <Grid.Row columns="threes"> */}
            {/* <Grid.Column style={{ marginLeft: "15px" }} width={6}> */}
            {/* <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      Buscar
                    </InputGroupAddon>
                    <Input onChange={this.handleChange('search')} />
                  </InputGroup> */}
            <Row>
              <Col xs={10} md={5} lg={5}>
                <Row>
                  <Col xs={10}>
                    <Search
                      input={{
                        icon: "search",
                        iconPosition: "left",
                        fluid: true,
                      }}
                      placeholder="..."
                      // onSearchChange={this.handleSearchChange}
                      value={search}
                      open={false}
                      name="search"
                      id="search"
                      onSearchChange={this.handleChange("search")}
                    />
                  </Col>
                  <Col xs={2}>

                    <img
                      onClick={() => {
                        if (this.state.advancedSearchData.length > 1) {
                          this.setState({ isOpen: true });
                        }
                      }}
                      alt="img"
                      width={26}
                      height={26}
                      src={IMG}
                      style={{ cursor: "pointer" }}
                    ></img>
                  </Col>
                </Row>
              </Col>
              {/* </Grid.Column> */}

              {/* <Grid.Column
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0",
              }}
              width={8}
            > */}


              {/* </Grid.Column> */}
              {/* <Grid.Column width={1}> */}
              <Col xs={2} md={7} lg={7} style={{ textAlign: 'right' }}>
                {this.state.loaderExcel ? (
                  <Icon.Group size="large">
                    <Icon
                      loading={this.state.loaderExcel}
                      name="circle notch"
                      size="large"
                    />
                    <Icon color="green" name="file excel" />
                  </Icon.Group>
                ) : (
                  <Icon
                    onClick={() => {
                      this.generateExcel();
                    }}
                    color="green"
                    className="__downloader"
                    name="file excel"
                    size="big"
                  />
                )}
              </Col>
            </Row>
            {/* </Grid.Column> */}
            {/* </Grid.Row> */}
            <div className="col-12" >
              {labelStatus !== 'closed' ?
                <div className="" >
                  <br></br>
                  <Label as='a'>
                    {labelStatus}
                    <Icon name='delete' onClick={resetCards} />
                  </Label>
                </div>
                : ''}
              <div className="col-md-12 col-lg-12 col-sm-12" ></div>
            </div>
            {/* </Grid> */}

            {/* </div> */}
          </Col>
        )}
        <br></br>
        <br></br>
        <br></br>
        <div
          className={
            //  this.props.config.length > 12 ? "containerCard" : "col-12"
            cant > 12 ? "containerCard" : "col-12"
          }
        >
          {this.state.cards}
        </div>
        <Modal
          backdrop="static"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          size="lg"
          style={{ maxWidth: '1000px', width: '100%' }}
        >
          <ModalHeader
            className="advancedSearchModal"
            style={{ background: "#020E3A", color: "white" }}
            toggle={this.toggle}
          >
            <img
              alt="img"
              width={23}
              src={IMG}
              style={{ cursor: "pointer" }}
            ></img>
            <b className='titleAvanzSearch' >Búsqueda Avanzada</b>
          </ModalHeader>
          <ModalBody>
            {(typeof resetAdvance === "function") ? <Row>
              <Col style={{ display: "flex", justifyContent: "flex-end" }} >

                <em
                  class="fa-2x mr-2 fas fa-eraser"
                  style={{ color: "#020E3A", marginTop: "7px", cursor: "pointer" }}
                  onClick={resetAdvance}
                />

              </Col>

            </Row> : false}

            {this.state.advancedSearchData.map((f, index1) => {
              return (
                <Container
                  key={index1}
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Row>
                    <Col >
                      <center className="subTitleAvanzSearch">{f.title ? f.title : `${"\u00A0"}`}</center>
                    </Col>

                  </Row>
                  <Row>
                    <Col>
                      {f.data.map((h, index2) => {
                        return this.getAdvancedData(h, index2, index1);
                      })}
                    </Col>
                  </Row>
                  {index1 !== this.state.advancedSearchData.length - 1 ? (
                    <div
                      style={{
                        background: "#f0f0f0",
                        height: 1,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Container>
              );
            })}
          </ModalBody>
          <ModalFooter className="modalFooterCenter">
            <Button className="buttomSeaCargoCancel" onClick={this.toggle}>
              Cancelar
            </Button>
            <Button
              // color="primary"
              className="buttomSeaCargo"
              onClick={() => {
                this.findAdvanced();
              }}
            >
              Buscar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {this.props.cardInfon || this.props.pagination === false ? (
          ""
        ) : (
          <div className="">
            <center>
              <Pagination
                activePage={activePage}
                ellipsisItem={null}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) =>
                  this.changePage(activePage)
                }
              />
            </center>
          </div>
        )}
      </Row>
    );
  }
}
export default Cards;
